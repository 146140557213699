import React, { useEffect, useState } from "react";
//import PropTypes from 'prop-types'

// zustand
import useStore, { useSearchStore } from "../../lib/store";
import { shallow } from "zustand/shallow";
//

// i18n
import { useTranslation } from "react-i18next";
import { getInLang } from "../../lib/translate";
//

import _ from "underscore";

import { register } from "swiper/element/bundle";

import Chronology from "./Chronology";

import "./ChronologySelector.scss";

import "swiper/scss";
import "swiper/scss/navigation";
import { PrevArrowIcon, NextArrowIcon } from "../Icons/Icons";

const CHRONOGIES_BY_SLIDE = 3;
const CHRONOLOGY_ORDER = [
  2037, // Europe occidentale néolithique à nos jours
  1135, // Calendrier Gregorien
  2465, // Monde romain-byzantin de la fondation de Rome à la chute de Constantinople
  1531, // Mediterranée et mer noire
  1749, // Europe occidentale continentale MCMXCVIII
  1070, // Antilles
];

const findSelectedSlide = (chronologies, chronologyId) => {
  if (!chronologyId) return 1;
  const index = chronologies.findIndex((chrono) => chrono.id === chronologyId);
  return Math.floor(index / CHRONOGIES_BY_SLIDE);
};

const ChronologySelector = () => {
  const chronologies = [...useStore((state) => state.chronologies)].sort(
    (a, b) => CHRONOLOGY_ORDER.indexOf(a.id) - CHRONOLOGY_ORDER.indexOf(b.id)
  );
  const [selectedChronologyId, setSelectedChronologyId] = useSearchStore(
    (state) => [state.selectedChronologyId, state.setSelectedChronologyId],
    shallow
  );

  const [swiperEl, setSwiperEl] = useState(null);

  useEffect(() => {
    if (swiperEl && !swiperEl.swiper?.initialized) {
      register();
      Object.assign(swiperEl, {
        slidesPerView: "auto",
        navigation: true,
        mousewheel: true,
        on: {
          init: (swiper) => {
            setTimeout(() => {
              swiper.navigation.update();
            }, 500);
          },
        },
      });
      swiperEl.initialize();
    }
    if (swiperEl && swiperEl.swiper) {
      swiperEl.swiper.slideTo(findSelectedSlide(chronologies, selectedChronologyId));
    }
  }, [swiperEl, chronologies, selectedChronologyId]);

  return (
    <div className="ChronologySelector">
      <button className="swiper-prev-button">
        <PrevArrowIcon />
      </button>
      <swiper-container
        init="false"
        ref={(el) => setSwiperEl(el)}
        navigation-next-el=".swiper-next-button"
        navigation-prev-el=".swiper-prev-button"
      >
        {_.chunk(chronologies, CHRONOGIES_BY_SLIDE).map((chronocolumn, colnum) => (
          <swiper-slide key={colnum}>
            {chronocolumn.map((chronology) => (
              <Chronology
                key={chronology.id}
                chronology={chronology}
                selected={chronology.id === selectedChronologyId}
                onClick={() => setSelectedChronologyId(chronology.id)}
              />
            ))}
          </swiper-slide>
        ))}
      </swiper-container>
      <button className="swiper-next-button">
        <NextArrowIcon />
      </button>
    </div>
  );
};

ChronologySelector.propTypes = {};

export default ChronologySelector;
