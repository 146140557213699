// React
import React, { useState } from "react"

// bootstrap
import Form from 'react-bootstrap/Form';

// translate
import { t } from "i18next";
import { useTranslation } from 'react-i18next';
import { Language } from '../../enums/Language';

const Lang = () => {
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(i18n.language);

    let changeLanguage = (event) => {
        let language = event.target.value;

        switch (language) {
            case Language.EN:
                setLang(Language.EN);
                i18n.changeLanguage(Language.EN);
                break;
            case Language.FR:
            default:
                setLang(Language.FR);
                i18n.changeLanguage(Language.FR);
                break;
        }
    }

    return (
        <>
            <Form.Select
                size={"sm"}
                value={lang}
                placeholder={t('components.LangSelect.select.label')}
                name="language"
                onChange={changeLanguage}>
                    <option value={Language.FR}>Fr</option>
                    <option value={Language.EN}>En</option>
            </Form.Select>
        </>
    )
}

export default Lang;
