import getSpiderfyObject from "@nazka/map-gl-js-spiderfy";
import maplibregl from "maplibre-gl";
import { buildHtmlMarker } from "../../lib/map/ArkeoMarkerBuilder";

/**
 * Class representing ArkeoSpiderfy for handling clustering and spiderfication of map features.
 * @constructor
 * @param {Object} map - The map object where the spiderfication will be applied.
 * @param {string} geojsonResultId - The ID of the GeoJSON result containing the features to be spiderfied.
 */

export class ArkeoSpiderfy {
  constructor(map, geojsonResultId) {
    this.map = map;
    this.geojsonResultId = geojsonResultId;
    this._spiderfy = null;
  }

  removeSpiderfy = () => {
    if (this._spiderfy) this._spiderfy.unspiderfyAll();
    this._spiderfy = null;

    /* search all layers to remove thems */
    /*
    const layers = this.map.getStyle().layers;
    layers.forEach((layer) => {
      console.log("layer found : ", layer.id);
      if (layer.id.startsWith(`cluster_${this.geojsonResultId}-spiderfy-`)) {
        console.log("spiderfy removing layer : ", layer.id);
        this.map.removeLayer(layer.id);
      }
    });
    */
  };

  loadSpiderfy = () => {
    console.log("loadSpiderfy");
    const map = this.map;

    if (this._spiderfy) {
      this._spiderfy.unspiderfyAll();
      this._spiderfy = null;
    }

    this._spiderfy = getSpiderfyObject(map, {
      //onLeafClick: f => console.log(f),
      closeOnLeafClick: false,
      //onLeafHover: (site_feature, e) => onLeafHover(site_feature, e),
      minZoomLevel: 12,
      zoomIncrement: 1,
      spiderLegsWidth: 3,
      spiderLegsColor: "#ffffff",
      renderMethod: "3D",
      spiralOptions: {
        legLengthStart: 45,
        legLengthFactor: 1.75,
        leavesSeparation: 30,
        leavesOffset: [0, 0],
      },
      spiderLeavesPaint: {
        // if we don't put spiderLeavesPaint (empty or not), it don't work...
      },
      spiderLeavesLayout: {
        "icon-image": [
          "concat",
          "arkeo_",
          ["get", "exceptional"],
          "_",
          ["get", "color"],
          "_",
          ["get", "knowledge"],
          "_",
          ["get", "centroid"],
        ],
        "icon-anchor": ["match", ["get", "centroid"], 1, "center", "bottom"],
        // flat way with niclone patch on Spiderfy
        /*
        "icon-offset": point => [
          "match",
          ["get", "centroid"],
          1,
          ["literal", [point[0], point[1]]],
          ["literal", [point[0], point[1] + 8 + 25]],
        ],
        */
        // 3D way
        "icon-offset": [
          "match",
          ["get", "centroid"],
          1,
          ["literal", [0, 0]],
          ["literal", [0, 8 + 25]],
        ],
        "icon-allow-overlap": true, // recommended
      },
    });
    this._spiderfy.applyTo("cluster_" + this.geojsonResultId);
  };
}
