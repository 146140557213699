import React from 'react';
//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// zustand
import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'

// i18n
import { useTranslation } from 'react-i18next';
//

import LangSelect from '../../components/LangSelect/LangSelect';

import { CloseIcon } from '../Icons/Icons';

import styles from './BurgerModal.module.scss';

/**
 * This is the Modal Dialog that is displayed when you click on the burger menu
 * @returns The Modal Dialog Component to include somewhere
 */

const BurgerModal = () => {
  const [ showBurgerModal, setShowBurgerModal, setSidePanelOpened, setProjectPage, setHelpPage ] = useStore(
    state => [ state.showBurgerModal, state.setShowBurgerModal, state.setSidePanelOpened, state.setProjectPage, state.setHelpPage ], shallow)

  const handleClose = () => setShowBurgerModal(false);

  const handleSearch = () => {
    setShowBurgerModal(false);
    setSidePanelOpened('left');
  }

  const { t } = useTranslation();

  return (
    <div className={`${styles.burgerModal} ${showBurgerModal ? styles.show : ""}`}>

      <img
        src="/logos/logo-arkeopen-white.svg"
        alt="ArkeoPen"
        title="Arkeopen"
      />

      <button className={styles.closeButton} onClick={handleClose}><CloseIcon /></button>

      <div className={styles.linkGroup1}>
        <a href='/#' rel='noreferrer' onClick={e => { e.preventDefault(); setProjectPage('project')}}>
        {t('components.BurgerModal.link.theproject1')}
        <span style={{color: 'white'}}>ArkeOpen</span>
        {t('components.BurgerModal.link.theproject2')}
        </a>
        <a href='/#' rel='noreferrer' onClick={e => { e.preventDefault(); handleSearch()}}>{t('components.BurgerModal.link.search')}</a>
        <a onClick={e => { e.preventDefault(); setProjectPage('partners')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.partners')}</a>
      </div>

      <div className={styles.linkGroup2}>
        <a onClick={e => { e.preventDefault(); setHelpPage('interface')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.inlinehelp')}</a>
        <div>
          <a className={styles.smallLink} onClick={e => { e.preventDefault(); setHelpPage('interface')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.interface')}</a>
          <a className={styles.smallLink} onClick={e => { e.preventDefault(); setHelpPage('when')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.chronology')}</a>
          <a className={styles.smallLink} onClick={e => { e.preventDefault(); setHelpPage('others')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.filter')}</a>
          <a className={styles.smallLink} onClick={e => { e.preventDefault(); setHelpPage('map')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.map')}</a>
        </div>
      </div>

      <div className={styles.linkGroup3}>
        <a className={styles.smallLink} onClick={e => { e.preventDefault(); setProjectPage('credits')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.credits')}</a>
        <a className={styles.smallLink} onClick={e => { e.preventDefault(); setProjectPage('cgu')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.cgu')}</a>
        <a className={styles.smallLink} onClick={e => { e.preventDefault(); setProjectPage('legals')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.legals')}</a>
        <a className={styles.smallLink} onClick={e => { e.preventDefault(); setProjectPage('contact')}} href='/#' rel='noreferrer'>{t('components.BurgerModal.link.contact')}</a>
        <div className={styles.lang}><LangSelect/></div>
      </div>
    </div>
  );
}

export default BurgerModal;
