import { gql } from '@apollo/client';

export const GetWhat = gql`
query GetWhat($site_ids: [Int!]) {
  ako_site(where: {id: {_in: $site_ids}}) {
    site_ranges {
      site_range__characs {
        charac_id
      }
    }
  }
}
`

export const GetOther = gql`
query GetOther($site_ids: [Int!]) {
  knowledge_not_documented: ako_site_aggregate(where: {id: {_in: $site_ids}, site_ranges: {site_range__characs: {knowledge_type: {_eq: "not_documented"}}}}) {
    aggregate {
      count
    }
  }
  knowledge_literature: ako_site_aggregate(where: {id: {_in: $site_ids}, site_ranges: {site_range__characs: {knowledge_type: {_eq: "literature"}}}}) {
    aggregate {
      count
    }
  }
  knowledge_prospected_aerial: ako_site_aggregate(where: {id: {_in: $site_ids}, site_ranges: {site_range__characs: {knowledge_type: {_eq: "prospected_aerial"}}}}) {
    aggregate {
      count
    }
  }
  knowledge_prospected_pedestrian: ako_site_aggregate(where: {id: {_in: $site_ids}, site_ranges: {site_range__characs: {knowledge_type: {_eq: "prospected_pedestrian"}}}}) {
    aggregate {
      count
    }
  }
  knowledge_surveyed: ako_site_aggregate(where: {id: {_in: $site_ids}, site_ranges: {site_range__characs: {knowledge_type: {_eq: "surveyed"}}}}) {
    aggregate {
      count
    }
  }
  knowledge_dig: ako_site_aggregate(where: {id: {_in: $site_ids}, site_ranges: {site_range__characs: {knowledge_type: {_eq: "dig"}}}}) {
    aggregate {
      count
    }
  }
  centroid_yes: ako_site_aggregate(where: {id: {_in: $site_ids}, centroid: {_eq: true}}) {
    aggregate {
      count
    }
  }
  centroid_no: ako_site_aggregate(where: {id: {_in: $site_ids}, centroid: {_eq: false}}) {
    aggregate {
      count
    }
  }
  exceptional_no: ako_site_aggregate(where: {id: {_in: $site_ids}, site_ranges: {site_range__characs: {exceptional: {_eq: false}}}}) {
    aggregate {
      count
    }
  }
  exceptional_yes: ako_site_aggregate(where: {id: {_in: $site_ids}, site_ranges: {site_range__characs: {exceptional: {_eq: true}}}}) {
    aggregate {
      count
    }
  }
  datasets: ako_database(where: {sites: {id: {_in: $site_ids}}}) {
    id
    sites_aggregate(where: {id: {_in: $site_ids}}) {
      aggregate {
        count
      }
    }
    name
    editor
    editor_url
    user {
      firstname
      lastname
      id
    }
  }
  authors: ako_database__authors(where: {database: {sites: {id: {_in: $site_ids}}}}) {
    user {
      firstname
      lastname
      id
    }
    database {
      sites_aggregate(where: {id: {_in: $site_ids}}) {
        aggregate {
          count
        }
      }
    }
  }
}
`

export const GetCitations = gql`
query GetCitations($site_ids: [Int!]) {
  datasets: ako_database(where: {sites: {id: {_in: $site_ids}}}) {
    id
    name
    editor
    editor_url
    database_trs {
      lang_isocode
      re_use
      source_description
      source_relation
      subject
      __typename
      bibliography
      copyright
      database_id
      context_description
      description
    }
  }
}
`
