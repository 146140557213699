const langprefsorder = {
  fr: ["fr", "en", "es", "de"],
  en: ["en", "fr", "es", "de"],
  de: ["de", "en", "fr", "es"],
  es: ["es", "en", "fr", "de"],
};

/**
 * Retrieves a translation object from a list of translations based on the preferred language order.
 *
 * @param {Array} trs - The list of translation objects to search through.
 * @param {string} wantedlang - The preferred language to retrieve the translation in. Defaults to 'fr'.
 * @returns {Object|null} The translation object in the preferred language, or the first translation object if not found. Returns null if the list of translations is empty.
 */
export function getInLang(trs, wantedlang = "fr") {
  for (const lang of langprefsorder[wantedlang]) {
    for (const tr of trs) {
      if (tr.lang_isocode === lang) return tr;
    }
  }
  if (trs.length > 0) return trs[0];
  return null;
}

/**
 * Retrieves the translation for a given key in the specified language.
 *
 * @param {Array} trs - The array of translation objects.
 * @param {string} key - The key to retrieve the translation for.
 * @param {string} [wantedlang="fr"] - The language code of the desired translation. Defaults to "fr".
 * @param {Object} [options={}] - Additional options for retrieving the translation.
 * @param {boolean} [options.emptyIsUndefined=false]
 *                  Specifies whether an empty translation should be treated as undefined.
 *                  This help finding an another translate in an another language instead of returning an empty string.
 *                  Defaults to false.
 *
 * @returns {string} - The translation for the given key in the specified language, or an empty string if not found.
 */
export function getInLangWKey(trs, key, wantedlang = "fr", options = {}) {
  const defaults = {
    emptyIsUndefined: false,
  };
  const actual = Object.assign({}, defaults, options);

  for (const lang of langprefsorder[wantedlang]) {
    for (const tr of trs) {
      if (tr.lang_isocode === lang) {
        if (actual.emptyIsUndefined) {
          if (key in tr && typeof tr[key] === "string" && tr[key].trim() !== "") {
            return tr[key];
          }
        } else {
          return tr[key];
        }
      }
    }
  }
  return "";
}

/** some translations "npm run extract-translations" :
 * t`arkeo.lang.fr`
 * t`arkeo.lang.en`
 * t`arkeo.lang.de`
 * t`arkeo.lang.es`
 */

export const getTranslatedLang = (t, lang) => {
  switch (lang) {
    case "fr":
      return t`arkeo.lang.fr`;
    case "en":
      return t`arkeo.lang.en`;
    case "de":
      return t`arkeo.lang.de`;
    case "es":
      return t`arkeo.lang.es`;
    default:
      return lang;
  }
};
