import { max } from "underscore";
import config from "../../config";
const maxsizes = config.markers.maxsizes;

const svgToDataURL = (svgStr) => {
  const encoded = encodeURIComponent(svgStr)
    .replace(/'/g, "%27")
    .replace(/"/g, "%22");

  const header = "data:image/svg+xml,";
  const dataUrl = header + encoded;

  return dataUrl;
};

const buildMarkerSVG = (exceptional, color, knowledge, centroid, selected) => {
  let zoom = (5 + knowledge) / 10;
  let svg = ``;
  svg += `<svg width="${maxsizes.width}" height="${
    maxsizes.height
  }" viewBox="-25 ${
    centroid ? -35.5 : -65
  } 50 71" fill="none" xmlns="http://www.w3.org/2000/svg">`;
  svg += `  <g transform="scale(${zoom},${zoom})">`;

  if (centroid) {
    // disc

    // note: there is no exceptional site with a disc (centroid). So this case is ignored
    if (exceptional === "yes") {
      svg += `
        <mask id="exceptional">
          <rect x="${-maxsizes.width / 2}" y="${
        -maxsizes.height / 2 - maxsizes.offsetFromBottom
      }" width="${maxsizes.width}" height="${maxsizes.height}" fill="#fff"/>
          <path d="M 9.9667 -2.2766 C 9.8766 -2.56 9.6118 -2.7532 9.3156 -2.7532 H 2.8745 L 0.6492 -9.5292 C 0.5562 -9.8097 0.2943 -10 -0.0005 -10 C -0.2953 -10 -0.5586 -9.8097 -0.6502 -9.5292 L -2.8755 -2.7532 H -9.3166 C -9.6143 -2.7532 -9.8776 -2.56 -9.9677 -2.2766 C -10.0579 -1.9933 -9.9548 -1.6842 -9.713 -1.511 L -4.5024 2.1982 L -6.6018 8.5866 C -6.6948 8.8686 -6.5946 9.1762 -6.3571 9.3522 C -6.2369 9.441 -6.0952 9.4853 -5.9506 9.4853 C -5.8118 9.4853 -5.673 9.4424 -5.5542 9.358 L -0.0004 5.404 L 5.5535 9.358 C 5.7953 9.5311 6.1173 9.5268 6.3563 9.3522 C 6.5953 9.1762 6.6926 8.8685 6.601 8.5866 L 4.5016 2.1982 L 9.7122 -1.511 C 9.9555 -1.6842 10.0583 -1.9933 9.9667 -2.2766 Z" fill="#000"/>
        </mask>`;
    }

    svg += `<circle cx="0" cy="0" r="20" fill="${color}" ${
      exceptional === "yes" ? `mask="url(#exceptional)"` : ""
    }/>`;

    if (selected == 1) {
      // small contour        [size = 44x44] => [viewbox offset = 3x49]
      svg += `<circle cx="0" cy="0" r="21" fill="#0000" stroke="#fff" stroke-width="2" vector-effect="non-scaling-stroke"/>`;
    } else if (selected == 2) {
      // big contour   [size = 50x50] => [viewbox offset = 0x46]
      svg += `<circle cx="0" cy="0" r="${
        25 - knowledge * 0.3
      }" fill="#0000" stroke="#fff" stroke-width="2" vector-effect="non-scaling-stroke"/>`;
    } else {
      // no contour                       [size = 40x40] => [viewbox offset = 5x51]
    }
  } else {
    // goutte
    if (exceptional === "yes") {
      svg += `
        <mask id="exceptional">
          <rect x="${-maxsizes.width / 2}" y="${-maxsizes.height}" width="${
        maxsizes.width
      }" height="999" fill="#fff"/>
          <path d="M 9.9667 -42.2766 C 9.8766 -42.56 9.6118 -42.7532 9.3156 -42.7532 H 2.8745 L 0.6492 -49.5292 C 0.5562 -49.8097 0.2943 -50 -0.0005 -50 C -0.2953 -50 -0.5586 -49.8097 -0.6502 -49.5292 L -2.8755 -42.7532 H -9.3166 C -9.6143 -42.7532 -9.8776 -42.56 -9.9677 -42.2766 C -10.0579 -41.9933 -9.9548 -41.6842 -9.713 -41.511 L -4.5024 -37.8018 L -6.6018 -31.4134 C -6.6948 -31.1314 -6.5946 -30.8238 -6.3571 -30.6478 C -6.2369 -30.559 -6.0952 -30.5147 -5.9506 -30.5147 C -5.8118 -30.5147 -5.673 -30.5576 -5.5542 -30.642 L -0.0004 -34.596 L 5.5535 -30.642 C 5.7953 -30.4689 6.1173 -30.4732 6.3563 -30.6478 C 6.5953 -30.8238 6.6926 -31.1315 6.601 -31.4134 L 4.5016 -37.8018 L 9.7122 -41.511 C 9.9555 -41.6842 10.0583 -41.9933 9.9667 -42.2766 Z" fill="#000"/>
        </mask>`;
    }

    svg += `
      <g transform="translate(0,${-maxsizes.offsetFromBottom})">
        <path
          d="M 0.1343 -57.9964 C 0.1015 -57.9981 0.0681 -57.999 0.0342 -57.999 H 0.0333 C -0.0125 -58.0005 -0.0572 -58.0003 -0.101 -57.9985 C -8.1936 -57.9431 -15.3818 -52.8748 -18.5069 -45.3601 C -21.1384 -39.0405 -20.1109 -32.3289 -16.8378 -26.5313 C -14.3545 -22.1502 -11.856 -17.7534 -9.3569 -13.3559 C -6.6907 -8.6641 -4.0239 -3.9714 -1.3746 0.7045 C -0.7981 1.7481 0.8058 1.7481 1.3847 0.7045 C 6.5173 -8.2876 11.6499 -17.2798 16.7171 -26.3352 C 20.0528 -32.1992 21.0803 -38.5851 18.707 -45.0343 C 15.8293 -52.8845 8.3075 -57.7634 0.1343 -57.9964 Z"
          fill="${color}" ${
      exceptional === "yes" ? `mask="url(#exceptional)"` : ""
    }
        />
      </g>
    `;

    if (selected == 1) {
      // small contour        [size = 44x64] => [viewbox offset = 3x7]
      svg += `
        <g transform="scale(${1.05 - knowledge * 0.015},${
        1.05 - knowledge * 0.015
      }) translate(0,${3.0 - maxsizes.offsetFromBottom - knowledge * 0.8})">
          <path
            d="M 0.1343 -57.9964 C 0.1015 -57.9981 0.0681 -57.999 0.0342 -57.999 H 0.0333 C -0.0125 -58.0005 -0.0572 -58.0003 -0.101 -57.9985 C -8.1936 -57.9431 -15.3818 -52.8748 -18.5069 -45.3601 C -21.1384 -39.0405 -20.1109 -32.3289 -16.8378 -26.5313 C -14.3545 -22.1502 -11.856 -17.7534 -9.3569 -13.3559 C -6.6907 -8.6641 -4.0239 -3.9714 -1.3746 0.7045 C -0.7981 1.7481 0.8058 1.7481 1.3847 0.7045 C 6.5173 -8.2876 11.6499 -17.2798 16.7171 -26.3352 C 20.0528 -32.1992 21.0803 -38.5851 18.707 -45.0343 C 15.8293 -52.8845 8.3075 -57.7634 0.1343 -57.9964 Z"
            fill="#0000"
            stroke="#fff"
            stroke-width="2"
            vector-effect="non-scaling-stroke"
          />
        </g>
      `;
    } else if (selected == 2) {
      // big contour   [size = 50x71] => [viewbox offset = 0x0]
      svg += `
        <g transform="scale(${1.3 - knowledge * 0.025},${
        1.3 - knowledge * 0.025
      }) translate(0,${9.0 - maxsizes.offsetFromBottom - knowledge * 0.63})">
          <path
            d="M 0.1343 -57.9964 C 0.1015 -57.9981 0.0681 -57.999 0.0342 -57.999 H 0.0333 C -0.0125 -58.0005 -0.0572 -58.0003 -0.101 -57.9985 C -8.1936 -57.9431 -15.3818 -52.8748 -18.5069 -45.3601 C -21.1384 -39.0405 -20.1109 -32.3289 -16.8378 -26.5313 C -14.3545 -22.1502 -11.856 -17.7534 -9.3569 -13.3559 C -6.6907 -8.6641 -4.0239 -3.9714 -1.3746 0.7045 C -0.7981 1.7481 0.8058 1.7481 1.3847 0.7045 C 6.5173 -8.2876 11.6499 -17.2798 16.7171 -26.3352 C 20.0528 -32.1992 21.0803 -38.5851 18.707 -45.0343 C 15.8293 -52.8845 8.3075 -57.7634 0.1343 -57.9964 Z"
            fill="#0000"
            stroke="#fff"
            stroke-width="2"
            vector-effect="non-scaling-stroke"
          />
        </g>
      `;
    } else {
      // no contour                       [size = 40x60] => [viewbox offset = 5x11]
    }
  }
  svg += `  </g>`;
  svg += `</svg>`;

  return svg;
};

export const buildMarker = async (map, id) => {
  const prefix = "arkeo_";
  if (id.indexOf(prefix) !== 0) return;
  const [p, exceptional, color, knowledge, centroid] = id.split("_");

  const svg = buildMarkerSVG(
    exceptional,
    color,
    parseInt(knowledge),
    parseInt(centroid),
    0
  );

  //let img = new Image(15 + knowledge * 5,42.31 + knowledge * 7.43)
  let img = new Image(maxsizes.width, maxsizes.height);
  img.src = svgToDataURL(svg);
  await img.decode();
  map.addImage(id, img, { sdf: false });
};

export const buildHtmlMarker = (
  offset,
  exceptional,
  color,
  knowledge,
  centroid,
  selected = 1
) => {
  const svg = buildMarkerSVG(
    exceptional,
    color,
    parseInt(knowledge),
    parseInt(centroid),
    selected
  );

  //let img = new Image(15 + knowledge * 5,42.31 + knowledge * 7.43)
  let img = new Image(maxsizes.width, maxsizes.height);
  img.src = svgToDataURL(svg);
  img.className = "marker";
  img.style.pointerEvents = "none";
  img.style.margin = centroid
    ? `${offset[1]}px ${offset[0]}px`
    : `${offset[1] - maxsizes.height / 2}px ${offset[0]}px`;
  // console.log("offset", offset, img.style.margin);
  return img;
};
