import React from "react";
//import PropTypes from 'prop-types'

//import Button from 'react-bootstrap/Button';
import { BiSearch } from "react-icons/bi";

// zustand
import { useSearchStore } from "../../lib/store";
import { shallow } from "zustand/shallow";
//

// bootstrap
import Form from "react-bootstrap/Form";
//import FormControl from 'react-bootstrap/FormControl';
//

// i18n
import { useTranslation } from "react-i18next";
//import { getInLang } from '../../lib/translate';
//

// graphql queries
import { useQuery } from "@apollo/client";
import {
  GetDatabases,
  GetDatabasesAuthors,
  GetDatabasesEditors,
} from "../../lib/queries/database";
//

import "./FormSearchOthers.scss";
import FlatSelect from "../FlatSelect/FlatSelect";

const SelectEditors = () => {
  const { t /*, i18n*/ } = useTranslation();
  const { loading, error, data } = useQuery(GetDatabasesEditors, {
    variables: {},
  });

  const [editors, updateEditors] = useSearchStore(
    (state) => [state.editors, state.updateEditors],
    shallow
  );

  if (error) return <div className="error">Error</div>;
  if (loading) return <div className="loading">...</div>;

  return (
    <FlatSelect
      unselectable={true}
      label={t("components.FormSearchOthers.editors.label")}
      partiallySelected={editors.length > 0}
    >
      {data.ako_database
        .filter((d) => d.editor !== "")
        .map((database) => (
          <FlatSelect
            key={database.editor}
            leaf={true}
            label={database.editor}
            selected={editors.includes(database.editor)}
            onSelect={(sel) => updateEditors(sel, database.editor)}
          />
        ))}
    </FlatSelect>
  );
};

const SelectAuthors = (props) => {
  const { t, i18n } = useTranslation();
  const { loading, error, data } = useQuery(GetDatabasesAuthors, {
    variables: {},
  });

  const [authors, updateAuthors] = useSearchStore(
    (state) => [state.authors, state.updateAuthors],
    shallow
  );

  if (error) return <div className="error">Error</div>;
  if (loading) return <div className="loading">...</div>;

  const authorsindb = data.ako_database__authors.map((author) => {
    return {
      user_id: author.user_id,
      fullname: `${author.user.lastname} ${author.user.firstname}`,
    };
  });

  return (
    <FlatSelect
      unselectable={true}
      label={t("components.FormSearchOthers.authors.label")}
      partiallySelected={authors.length > 0}
    >
      {authorsindb
        .sort((a, b) =>
          a.fullname.localeCompare(b.fullname, i18n.language, {
            sensitivity: "base",
          })
        )
        .map((author) => {
          return (
            <FlatSelect
              key={author.user_id}
              leaf={true}
              label={author.fullname}
              selected={authors.includes(author.user_id)}
              onSelect={(sel) => updateAuthors(sel, author.user_id)}
            />
          );
        })}
    </FlatSelect>
  );
};

const SelectDatabases = () => {
  const { t /*, i18n*/ } = useTranslation();
  const { loading, error, data } = useQuery(GetDatabases, {
    variables: {},
  });

  const [databases, updateDatabases] = useSearchStore(
    (state) => [state.databases, state.updateDatabases],
    shallow
  );

  if (error) return <div className="error">Error</div>;
  if (loading) return <div className="loading">...</div>;

  return (
    <FlatSelect
      unselectable={true}
      label={t("components.FormSearchOthers.databases.label")}
      partiallySelected={databases.length > 0}
    >
      {data.ako_database.map((database) => {
        return (
          <FlatSelect
            key={database.id}
            leaf={true}
            label={database.name}
            selected={databases.includes(database.id)}
            onSelect={(sel) => updateDatabases(sel, database.id)}
          />
        );
      })}
    </FlatSelect>
  );
};

const FormSearchOthers = () => {
  const { t /*, i18n*/ } = useTranslation();
  const [
    datasetTypes,
    updateDatasetTypes,
    scaleResolutions,
    updateScaleResolutions,
    textual,
    setTextual,
    textualOn,
    setTextualOn,
  ] = useSearchStore(
    (state) => [
      state.datasetTypes,
      state.updateDatasetTypes,
      state.scaleResolutions,
      state.updateScaleResolutions,
      state.textual,
      state.setTextual,
      state.textualOn,
      state.setTextualOn,
    ],
    shallow
  );

  return (
    <div className="FormSearchOthers">
      <div>

        <Form.Label>{t("components.FormSearchOthers.label")}</Form.Label>
        <SelectDatabases />

        {/*'undefined', 'inventory', 'research', 'literary-work' */}
        <FlatSelect
          unselectable={true}
          label={t("components.FormSearchOthers.dataset_type.label")}
          partiallySelected={datasetTypes.length > 0}
        >
          <FlatSelect
            leaf={true}
            label={t("arkeo.dataset_type.inventory")}
            selected={datasetTypes.includes("inventory")}
            onSelect={(sel) => updateDatasetTypes(sel, "inventory")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.dataset_type.research")}
            selected={datasetTypes.includes("research")}
            onSelect={(sel) => updateDatasetTypes(sel, "research")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.dataset_type.literary_work")}
            selected={datasetTypes.includes("literary-work")}
            onSelect={(sel) => updateDatasetTypes(sel, "literary-work")}
          />
          {/*
            <FlatSelect
              leaf={true}
              label={t("arkeo.dataset_type.undefined")}
              selected={datasetTypes.includes("undefined")}
              onSelect={(sel) => updateDatasetTypes(sel, "undefined")}
            />
            */}
        </FlatSelect>

        {/* undefined,object,site,watershed,micro-region,region,country,continent,world */}
        <FlatSelect
          unselectable={true}
          label={t("components.FormSearchOthers.scale_resolution.label")}
          partiallySelected={scaleResolutions.length > 0}
        >
          <FlatSelect
            leaf={true}
            label={t("arkeo.scale_resolution.object")}
            selected={scaleResolutions.includes("object")}
            onSelect={(sel) => updateScaleResolutions(sel, "object")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.scale_resolution.site")}
            selected={scaleResolutions.includes("site")}
            onSelect={(sel) => updateScaleResolutions(sel, "site")}
          />
          {/*}
          <FlatSelect
            leaf={true}
            label={t("arkeo.scale_resolution.watershed")}
            selected={scaleResolutions.includes("watershed")}
            onSelect={(sel) => updateScaleResolutions(sel, "watershed")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.scale_resolution.micro_region")}
            selected={scaleResolutions.includes("micro-region")}
            onSelect={(sel) => updateScaleResolutions(sel, "micro-region")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.scale_resolution.region")}
            selected={scaleResolutions.includes("region")}
            onSelect={(sel) => updateScaleResolutions(sel, "region")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.scale_resolution.country")}
            selected={scaleResolutions.includes("country")}
            onSelect={(sel) => updateScaleResolutions(sel, "country")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.scale_resolution.continent")}
            selected={scaleResolutions.includes("continent")}
            onSelect={(sel) => updateScaleResolutions(sel, "continent")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.scale_resolution.world")}
            selected={scaleResolutions.includes("world")}
            onSelect={(sel) => updateScaleResolutions(sel, "world")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.scale_resolution.undefined")}
            selected={scaleResolutions.includes("undefined")}
            onSelect={(sel) => updateScaleResolutions(sel, "undefined")}
          />
          */}
        </FlatSelect>
      </div>

      <div>
        <Form.Label>
          {t("components.FormSearchOthers.textual.label")}
        </Form.Label>

        <div className="TextualSearch">
          <Form.Control
            type="text"
            placeholder={t("components.FormSearchOthers.textual.placeholder")}
            value={textual}
            onChange={(e) => setTextual(e.target.value)}
          />
        </div>
      </div>

      <div className="radioButtonsContainer">
        <Form.Check
          id="radio_searchon"
          inline
          label={t("components.FormSearchOthers.textual.search-on.all")}
          type="radio"
          checked={textualOn === "all"}
          onChange={() => {}}
          onClick={() => setTextualOn("all")}
        />
        <Form.Check
          id="radio_datasetname"
          inline
          label={t(
            "components.FormSearchOthers.textual.search-on.dataset-name"
          )}
          type="radio"
          checked={textualOn === "dataset-name"}
          onChange={() => {}}
          onClick={() => setTextualOn("dataset-name")}
        />
        <Form.Check
          id="radio_ressourcename"
          inline
          label={t(
            "components.FormSearchOthers.textual.search-on.resource-name"
          )}
          type="radio"
          checked={textualOn === "resource-name"}
          onChange={() => {}}
          onClick={() => setTextualOn("resource-name")}
        />
        <Form.Check
          id="radio_commnents"
          inline
          label={t("components.FormSearchOthers.textual.search-on.comments")}
          type="radio"
          checked={textualOn === "comments"}
          onChange={() => {}}
          onClick={() => setTextualOn("comments")}
        />
        <Form.Check
          id="radio_bibliography"
          inline
          label={t(
            "components.FormSearchOthers.textual.search-on.bibliography"
          )}
          type="radio"
          checked={textualOn === "bibliography"}
          onChange={() => {}}
          onClick={() => setTextualOn("bibliography")}
        />
        <Form.Check
          id="radio_city_name"
          inline
          label={t("components.FormSearchOthers.textual.search-on.city-name")}
          type="radio"
          checked={textualOn === "city-name"}
          onChange={() => {}}
          onClick={() => setTextualOn("city-name")}
        />
      </div>

      <div>
        <Form.Label>
          {t("components.FormSearchOthers.attributes.label")}
        </Form.Label>

        <SelectEditors />

        <SelectAuthors />
      </div>
    </div>
  );
};

FormSearchOthers.propTypes = {};

export default FormSearchOthers;
