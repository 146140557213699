import React, {useState} from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

// i18n
import { useTranslation } from 'react-i18next';

import './ShareButton.scss'

export const ShareButton = props => {
  const { t/*, i18n*/ } = useTranslation();
  const [ shared, setShared ] = useState(false);

  const share = e => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setShared(true);
    }).catch(err => {
      console.error(err);
    })
  }
  const toggle = show => {
    if (show) setShared(false);
  }

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement='top'
      onToggle={show => toggle(show)}
      overlay={
        <Popover id={`popover-positioned-top`}>
          <Popover.Body>
            {shared ? t('components.ShareButton.shared_label') : t('components.ShareButton.default_label')}
          </Popover.Body>
        </Popover>
      }
    >
      <button className="ShareButton" onClick={e => share(e)}>
        <img alt="share" src='/icons/share.svg'/>
      </button>
    </OverlayTrigger>
  )
}

export default ShareButton;
