import React, { useEffect, useRef, useState } from "react";
import { register } from "swiper/element/bundle";
import TabContainer from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";
import NavLink from "react-bootstrap/NavLink";

import "swiper/scss";
import "swiper/scss/navigation";
import { PrevArrowIcon, NextArrowIcon } from "../Icons/Icons";
import styles from "./SlidableTabs.module.scss";

const SlidableTabs = ({
  tabs,
  onSelect,
  activeKey,
  className = "",
  mountOnEnter = true,
  umountOnExit = false,
  hideNavigationOnBigScreen = false,
  onClick,
  swiperConfig = {
    slidesPerView: "auto",
    navigation: true,
    mousewheel: true,
    slideToClickedSlide: true,
  },
}) => {
  const swiperRef = useRef(null);
  const [active, setActive] = useState(null);

  const onClickCallback = (_, code) => {
    if (typeof onClick === "function") {
      onClick();
    }
    setActive(code)
  };

  useEffect(() => {
    register();
    Object.assign(swiperRef.current, swiperConfig);
    swiperRef.current.initialize();
    // Activate default tab
    setActive(activeKey ? activeKey : ! active ? tabs.at(0).code : null)
  }, []);

  return (
    <div className={`${styles.slidableTabs} ${hideNavigationOnBigScreen ? styles.hideNavigationOnBigScreen : ''} ${className}`}>
      <TabContainer
        id={`tabs-${activeKey}`}
        defaultActiveKey={activeKey || tabs.at(0).code}
        activeKey={activeKey}
        onSelect={onSelect}
        umountOnExit={umountOnExit}
      >
        <div className={styles.tabContainer}>
          <swiper-container init="false" ref={swiperRef} navigation-next-el=".swiper-next-button" navigation-prev-el=".swiper-prev-button"
          >
            {tabs.map((tab) => {
              const tabLabel =
                <NavLink
                  eventKey={tab.code}
                  className={activeKey === tab.code || active === tab.code ? styles.active : ""}
                  onClick={(e) => onClickCallback(e, tab.code)}
                >
                  {tab.text}
                </NavLink>

              return <swiper-slide key={tab.code}>{tabLabel}</swiper-slide>
            })}
          </swiper-container>
          <div className={styles.navigation}>
            <div className={`swiper-prev-button ${styles.swiperPrevButton}`}><PrevArrowIcon /></div>
            <div className={`swiper-next-button ${styles.swiperPrevButton}`}><NextArrowIcon /></div>
          </div>
        </div>
        <TabContent>
          {tabs.map((tab) => {
            return (
              <TabPane
                key={tab.code}
                eventKey={tab.code}
                mountOnEnter={mountOnEnter}
              >
                {tab.content}
              </TabPane>
            );
          })}
        </TabContent>
      </TabContainer>
    </div>
  );
};

export default SlidableTabs;
