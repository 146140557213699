import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { GetFullSiteQuery } from '../../lib/queries/sites';
import Loader from '../Loader/Loader';
import ShareButton from '../ShareButton/ShareButton';
import PicturesButton from '../PicturesButton/PicturesButton';
import { useQuery } from '@apollo/client';
import Moment from 'react-moment';

import Accordion from 'react-bootstrap/Accordion';

import Markdown from '../Markdown/Markdown';
import SlidableTabs from '../SlidableTabs/SlidableTabs';

import { MdError } from "react-icons/md";

// i18n
import { useTranslation } from 'react-i18next';
import { getInLang } from '../../lib/translate';
//

import useStore, { useValidatedSearchStore } from '../../lib/store'

import { yearToHuman } from '../../lib/year';

import { getChronologyByDatel1, getChronologyByDatel4 } from '../../lib/chronologiesSelection';

import config from '../../config.json';
import { UNDETERMINED_LEFT } from '../../lib/year';
import MiniMap from '../MiniMap/MiniMap';

import _ from 'underscore';

import { useEffect } from 'react';
import CloseButton from '../CloseButton/CloseButton';
import { Align, ContentWithPadding, ContentWithLabel, SimpleAccordion, Sticker, TitleWithIcons } from '../ArkeoUI/ArkeoUI';

import styles from './SiteComponent.module.scss';

const SiteComponent = props => {
  const { i18n, t } = useTranslation();
  const { id } = props;
  const setRightPanel = useStore(state => state.setRightPanel);
  const chronologies = useStore(state => state.chronologies);
  const selectedChronologyId = useValidatedSearchStore(state => state.selectedChronologyId);
  const [geojson, setGeojson] = useState(undefined);

  const { loading, error, data } = useQuery(GetFullSiteQuery, {
    variables: {
      id: id,
    },
  })
  const site = data && data.ako_site && data.ako_site.length > 0 ? data.ako_site[0] : undefined;

  useEffect(() => {
    if (!site) return;

    const reduceRangeCharacs = (accumulator, siteRanges) => {
      let r=siteRanges.site_range__characs.reduce((accumulator2, siteRangeCharac) => {
        const t = siteRangeCharac.knowledge_type;        // enum:"not_documented,literature,prospected_aerial,prospected_pedestrian,surveyed,dig"
        let r2=0;
        switch(t) {
          case 'dig': r2=5; break;
          case 'surveyed': r2=4; break;
          case 'prospected_pedestrian': r2=3; break;
          case 'prospected_aerial': r2=2; break;
          case 'literature': r2=1; break;
          default:
            console.warn('Knowledge type not found.')
          //case 'not_documented': r2=0; break;
        }
        return r2 > accumulator2 ? r2 : accumulator2;
      }, accumulator);
      return r > accumulator ? r : accumulator;
    }

    const selectedChronology = chronologies.find(c => c.id === selectedChronologyId);
    const p1 = getChronologyByDatel1(selectedChronology.chronologies, site.end_date1, site.end_date2);
    const p2 = getChronologyByDatel4(selectedChronology.chronologies, site.end_date1, site.end_date2);
    const _geojson = {
      "type": "FeatureCollection",
      "features": [{
        "type": "Feature",
        "properties": {
          "name": site.name,
          "start_date1": site.start_date1,
          "start_date2": site.start_date2,
          "end_date1": site.end_date1,
          "end_date2": site.end_date2,
          "centroid": site.centroid ? 1 : 0,
          "periodeid": p1 ? p1.id : (site.end_date1 === UNDETERMINED_LEFT ? config.chronology.undetermined.id : config.chronology.outside.id),
          "periodeid2": p2 ? p2.id : (site.end_date1 === UNDETERMINED_LEFT ? config.chronology.undetermined.id : config.chronology.outside.id),
          "color": p2 ? '#'+p2.color : (site.end_date1 === UNDETERMINED_LEFT ? config.chronology.undetermined.color : config.chronology.outside.color),
          "exceptional": site.site_ranges.map(sr => sr.site_range__characs.map(src => src.exceptional)).flat().includes(true) ? "yes" : "no",
          "knowledge": site.site_ranges.reduce(reduceRangeCharacs, 0),
          "site_id": site.id,
        },
        "geometry": {
          "type": "Point",
          "coordinates": site.geom.coordinates
        }
      }]
    };
    setGeojson(_geojson);
  }, [setGeojson, selectedChronologyId, chronologies, data, site])

  if (loading) {
    return <Loader/>
  }

  const mdError = error ? error.message :
                  (!data.ako_site || data.ako_site.length === 0) ? "Site not found" :
                  !geojson ? "No coordinates found." : null;

  if (mdError) {
    return (
      <div className={styles.SiteComponent}>
        <div className={styles.error}>
        <MdError size={48}/>{mdError}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.SiteComponent}>

      <MiniMap minimaptype="site" geojson={geojson}/>

      <CloseButton />

      <ContentWithPadding>
        <TitleWithIcons title={site.name}>
          <PicturesButton site={site} />
          <ShareButton />
        </TitleWithIcons>
        <Align smallFont split className={styles.headingBlocks}>
          <SiteCoverage1 site={site}/>
          <SiteCoverage2 site={site}/>
        </Align>
        <hr />
        <Align smallFont split className={styles.headingBlocks}>
          <div>
            <ContentWithLabel label={t("components.SiteComponent.Database.Authors.title")} lang={i18n.language}>
              <DatabaseAuthors database={site.database}/>
            </ContentWithLabel>
            <ContentWithLabel label={t("components.SiteComponent.Database.Dataset.title")} lang={i18n.language}>
              <a
                href='/#'
                rel="noreferrer"
                onClick={
                  e => {
                    e.preventDefault();
                    setRightPanel({type: 'db', id: site.database.id})
                  }
                }
              >{site.database.name}</a>
            </ContentWithLabel>
          </div>
          <div>
            <ContentWithLabel label={t("components.SiteComponent.Database.Dataset.date_created")} lang={i18n.language}>
              <Moment format="DD/MM/YYYY hh:mm">{site.database.declared_creation_date}</Moment>
            </ContentWithLabel>
            <ContentWithLabel label={t("components.SiteComponent.Database.Dataset.date_updated")} lang={i18n.language}>
              <Moment format="DD/MM/YYYY hh:mm">{site.database.updated_at}</Moment>
            </ContentWithLabel>
          </div>
        </Align>
        <SiteRanges site={site}/>
      </ContentWithPadding>
    </div>
  )
}

const SiteCoverage1 = props => {
  const { site } = props;
  const { t, i18n } = useTranslation();

  const selectedChronologyId = useValidatedSearchStore(state => state.selectedChronologyId);
  const chronologies = useStore(state => state.chronologies);
  const selectedChronology = chronologies.find(c => c.id === selectedChronologyId);

  const chrono_start = getChronologyByDatel4(selectedChronology.chronologies, site.start_date1, site.start_date2);
  const chrono_end = getChronologyByDatel4(selectedChronology.chronologies, site.end_date1, site.end_date2);

  const color_start = chrono_start ? '#'+chrono_start.color : '';
  const color_end = chrono_end ? '#'+chrono_end.color : '';

  return (
    <div>
      <ContentWithLabel label={t("components.SiteComponent.SiteCoverage1.title")} noColon/>
      <ContentWithLabel label={t("components.SiteComponent.SiteCoverage1.start")} lang={i18n.language}>
        {chrono_start && getInLang(chrono_start.chronology_trs, i18n.language).name}
        <Align gap={12}>
          {chrono_start && <div className={styles.colorblock} style={{backgroundColor: color_start}}/>}
          {yearToHuman(site.start_date1)} =&gt; {yearToHuman(site.start_date2)}
        </Align>
      </ContentWithLabel>
      <ContentWithLabel label={t("components.SiteComponent.SiteCoverage1.end")} lang={i18n.language}>
        {chrono_end && getInLang(chrono_end.chronology_trs, i18n.language).name}
        <Align gap={12}>
          {chrono_end && <div className={styles.colorblock} style={{backgroundColor: color_end}}/>}
          {yearToHuman(site.end_date1)} =&gt; {yearToHuman(site.end_date2)}
        </Align>
      </ContentWithLabel>
      <ContentWithLabel label={t("components.SiteComponent.SiteCoverage1.occupation")} lang={i18n.language}>
        {site.occupation === 'not_documented' ? t("arkeo.occupation.not_documented") : ''}
        {site.occupation === 'single' ? t("arkeo.occupation.single") : ''}
        {site.occupation === 'continuous' ? t("arkeo.occupation.continuous") : ''}
        {site.occupation === 'multiple' ? t("arkeo.occupation.multiple") : ''}
      </ContentWithLabel>
    </div>
  );
}

const SiteCoverage2 = props => {
  const { site } = props;
  const { i18n, t } = useTranslation();

  return (
    <div>
      <ContentWithLabel label={t("components.SiteComponent.SiteCoverage2.title")} noColon />
        <ContentWithLabel label={t("components.SiteComponent.SiteCoverage2.latitude.title")} lang={i18n.language}>
          {site.geom.coordinates[0].toFixed(8)}
        </ContentWithLabel>
        <ContentWithLabel label={t("components.SiteComponent.SiteCoverage2.longitude.title")} lang={i18n.language}>{site.geom.coordinates[1].toFixed(8)}</ContentWithLabel>
      <Align>
        <ContentWithLabel label={t("components.SiteComponent.SiteCoverage2.altitude.title")} lang={i18n.language}>{site.altitude === -999999 ? '-' : site.altitude.toFixed(8) + " mts"}</ContentWithLabel>
        <div style={{minWidth: 8}}></div>
        <ContentWithLabel label={t("components.SiteComponent.SiteCoverage2.geoname.title")} lang={i18n.language}>
          {parseInt(site.city_geonameid) !== 0 ? <a className="no-icon" target='_blank' rel="noreferrer" href={`https://www.geonames.org/${site.city_geonameid}`}>{site.city_geonameid} </a> : "-"}
        </ContentWithLabel>
      </Align>
      <ContentWithLabel label={t("components.SiteComponent.SiteCoverage2.city.title")} lang={i18n.language}>{site.city_name}</ContentWithLabel>
      <Align>
        <ContentWithLabel label={t("components.SiteComponent.SiteCoverage2.coords.title")} lang={i18n.language}>
          {site.centroid ? t("components.SiteComponent.SiteCoverage2.coords.centroid") : t("components.SiteComponent.SiteCoverage2.coords.notcentroid")}
        </ContentWithLabel>
      </Align>
    </div>
  );
}

const SiteRanges = props => {
  const { site } = props;
  const [ key, setKey ] = useState(site && site.site_ranges && site.site_ranges.length > 0 ? 'ek'+site.site_ranges[0].id : 'none')
  const { t } = useTranslation();

  useEffect(() => {
    setKey(site && site.site_ranges && site.site_ranges.length > 0 ? 'ek'+site.site_ranges[0].id : 'none');
  }, [site]);

  const tabsWithContent = site.site_ranges.map((range, index) => (
    {
      code: `ek${range.id}`,
      text: `${index === 0 ? t("components.SiteComponent.SiteRanges.occupation") : ""} ${yearToHuman(range.start_date1)} => ${yearToHuman(range.end_date2)}`,
      content: <Characs range_characs={range.site_range__characs}/>
    }
  ))

  return (
    <SlidableTabs id="SiteRangesTabs" className={styles.siteRanges} tabs={tabsWithContent} onSelect={(k) => setKey(k)} activeKey={key} umountOnExit></SlidableTabs>
  )

}

const reversecharac = charac => {
  const acumulator = [];
  for (let c=charac; c && c.id>0; c=c.charac) acumulator.push({id: c.id, charac_trs: c.charac_trs, parent_id: c.parent_id, ark_id: c.ark_id});
  acumulator.reverse();
  return acumulator;
}

const reorderCharacs = rcs => {
  return rcs.map(rc => { return {
    id: rc.id,
    exceptional: rc.exceptional,
    knowledge_type: rc.knowledge_type,
    web_images: rc.web_images,
    charac: reversecharac(rc.charac),
    site_range__charac_trs: rc.site_range__charac_trs,
  }})
}

const reduceRangeCharacs = rcs => {
  rcs = reorderCharacs(rcs);
  const rcss = [];
  rcs.forEach(rc => {
    if (rcss.length === 0) {
      rcss.push([rc]);
    } else {
      if (rcss[rcss.length - 1][0].charac[0].id === rc.charac[0].id) {
        rcss[rcss.length - 1].push(rc);
      } else {
        rcss.push([rc]);
      }
    }
  })
  return rcss;
}

const subCharac = (characA, lang) => {
  let namepath = '';
  for (let i=1; i<characA.length; i++) {
    namepath += (i > 1 ? ' / ' : '') + getInLang(characA[i].charac_trs, lang).name;
  }
  return namepath;
}

const Characs = props => {
  const { t, i18n } = useTranslation();
  const { range_characs } = props;
  const rcss = reduceRangeCharacs(range_characs);
  const [ showAnnexes, setShowAnnexes ] = useState(true);

  const showhideannexes = (e) => {
    setShowAnnexes(!showAnnexes);
    e.stopPropagation();
    return false;
  }

  const RootCharac = (rcs, rcs_i) => {
    return (
      <Accordion.Item eventKey={rcs_i} key={rcs_i} className={!showAnnexes ? styles.hideAppendices : ""}>
        <Accordion.Header>
          {getInLang(rcs[0].charac[0].charac_trs, i18n.language).name}
          <a rel="noreferer" href='/#' onClick={e => {e.preventDefault(); showhideannexes(e)}}>
            {showAnnexes ? t("components.SiteComponent.CharacAccordion.header.hide-appendices") :
            t("components.SiteComponent.CharacAccordion.header.show-appendices")}
          </a>
        </Accordion.Header>
        <Accordion.Body>
          {rcs.map(rc => {
            const comment = getInLang(rc.site_range__charac_trs, i18n.language).comment;
            const bibliography = getInLang(rc.site_range__charac_trs, i18n.language).bibliography;
            return (
            <div className={styles.accordionContent} key={rc.id}>
              <div className={styles.contentHeader}>
                <ul><li className={styles.subCharacName}>{subCharac(rc.charac, i18n.language)}</li></ul>
                <Align className={styles.knowledgeType}>
                  {t('components.SiteComponent.RangeCharac.knowledge_type.label')}{i18n.language === "fr" ? " : " : ""}
                  <Sticker>{t(`arkeo.knowledge_type.${rc.knowledge_type}`)}</Sticker>
                </Align>
                {rc.exceptional && <div className={styles.exceptional}>{t('components.SiteComponent.RangeCharac.exceptional')}</div>}
              </div>
              {showAnnexes && (
                <>
                  {comment &&
                    <SimpleAccordion className={styles.commentAccordion} title={t('components.SiteComponent.RangeCharac.comment.label')}>
                      <Markdown>{comment}</Markdown>
                    </SimpleAccordion>
                  }
                  {bibliography &&
                    <SimpleAccordion className={styles.bibliographyAccordion} title={t('components.SiteComponent.RangeCharac.bibliography.label')} defaultActiveKey={null}>
                      <Markdown>{bibliography}</Markdown>
                    </SimpleAccordion>
                  }
                </>
                )}
            </div>
          )})}
        </Accordion.Body>
      </Accordion.Item>
    )
  }

  return (
    <div>
      <Accordion className={styles.characAccordion} alwaysOpen flush defaultActiveKey={_.range(rcss.length)}>
        {rcss.map((rcs, rcs_i) => RootCharac(rcs, rcs_i))}
      </Accordion>
    </div>
  );
}

const DatabaseAuthors = props => {
  const { database } = props;
  const authorsstr = database.database__authors.map(author => author.user.firstname + " " + author.user.lastname).join(', ');
  return authorsstr
}

SiteComponent.propTypes = {
  id: PropTypes.number.isRequired,
}

export default SiteComponent
