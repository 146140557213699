import React from "react";
import styles from "./FundersLogos.module.scss";

const FundersLogos = ({ className }) => (
  <div className={`${styles.fundersLogos} ${className ? className : ''}`}>
    <img src="/logos/ministere-recherche.png" alt="Ministère de l'enseignement supérieur et de la recherche" />
    <img
      src="/logos/Signature_Universite_Strasbourg_Unistra2_Transparent 1.png"
      alt="Université de Strasbourg Unistra2"
    />
    <img src="/logos/11_Logo UMR 1.png" alt="UMR" />
    <img src="/logos/logo-misha-court 1.png" alt="Misha" />
    <img src="/logos/logo-MASA.png" alt="MASA" />
    <img src="/logos/logo huma-num 1.png" alt="Huma-Num" />
  </div>
);

export default FundersLogos;
