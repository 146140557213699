import React from "react";
//import PropTypes from 'prop-types'
import ShareButton from "../ShareButton/ShareButton";

import { GetFullShapefile } from "../../lib/queries/shapefile";

import { useQuery } from "@apollo/client";

import Linkify from "react-linkify";

// i18n
import { useTranslation } from "react-i18next";
import { getInLang } from "../../lib/translate";

import styles from "./PanelShapefile.module.scss";
import Loader from "../Loader/Loader";
import MiniMap from "../MiniMap/MiniMap";
import Markdown from "../Markdown/Markdown";
import { yearToHuman } from "../../lib/year";
import CloseButton from "../CloseButton/CloseButton";
import {
  Align,
  ContentWithPadding,
  ContentWithLabel,
  TitleWithIcons,
  DescriptionBlock,
  SpacedContent,
} from "../ArkeoUI/ArkeoUI";

const myLink = (decoratedHref, decoratedText, key) => {
  return (
    <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
      {decoratedText}
    </a>
  );
};

const PanelShapefile = (props) => {
  const { t, i18n } = useTranslation();
  const { id } = props;

  const shapefileq = useQuery(GetFullShapefile, {
    variables: {
      shapefile_id: id,
    },
  }); // shapefile = { loading, error, data }

  const loadingOrError = shapefileq.loading ? (
    <Loader />
  ) : shapefileq.error ? (
    shapefileq.error.message
  ) : !shapefileq.data.ako_shapefile ||
    shapefileq.data.ako_shapefile.length === 0 ? (
    "Shapefile not found."
  ) : null;

  if (loadingOrError)
    return <div className={styles.PanelShapefile}>{loadingOrError}</div>;

  const shapefile = shapefileq.data.ako_shapefile[0];

  const CSS_LABEL_WIDTH = 220;

  return (
    <div className={styles.PanelShapefile}>
      <MiniMap geojson={JSON.parse(shapefile.geojson)} minimaptype="polygon" />
      <CloseButton rp={{ type: "legend", id: "where" }} />
      <ContentWithPadding>
        <TitleWithIcons
          title={getInLang(shapefile.shapefile_trs, i18n.language).name}
        >
          <ShareButton />
        </TitleWithIcons>

        <Align smallFont split className={styles.headingBlocks}>
          <div>
            <ContentWithLabel
              label={t`components.PanelShapefile.temporal.title`}
              noColon
            />
            <ContentWithLabel
              label={t`components.PanelShapefile.temporal.begin.title`}
              lang={i18n.language}
            >
              {yearToHuman(shapefile.start_date)}
            </ContentWithLabel>
            <ContentWithLabel
              label={t`components.PanelShapefile.temporal.end.title`}
              lang={i18n.language}
            >
              {yearToHuman(shapefile.end_date)}
            </ContentWithLabel>
          </div>

          <div>
            <ContentWithLabel
              label={t`components.PanelShapefile.geographical.title`}
              lang={i18n.language}
              split
              noColon
            />
            {
              getInLang(shapefile.shapefile_trs, i18n.language)
                .geographical_covering
            }
          </div>
        </Align>

        <hr />

        <Align smallFont split className={styles.headingBlocks}>
          <div>
            <ContentWithLabel
              label={t`components.PanelShapefile.authors.title`}
            >
              {shapefile.shapefile__authors
                .map((ug) => `${ug.user.lastname} ${ug.user.firstname}`)
                .join(", ")}
            </ContentWithLabel>
            <ContentWithLabel
              label={t`components.PanelShapefile.editors.title`}
              lang={i18n.language}
            >
              {shapefile.editor_uri ? (
                <a href={shapefile.editor_uri} target="_blank" rel="noreferrer">
                  {shapefile.editor}
                </a>
              ) : (
                shapefile.editor
              )}
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelShapefile.type.title`} lang={i18n.language}>
              SHP
            </ContentWithLabel>
          </div>
          <div>
            <ContentWithLabel
              label={t`components.PanelShapefile.date-created.title`} lang={i18n.language}
            >
              {new Date(shapefile.created_at).toLocaleDateString(i18n.language)}
            </ContentWithLabel>
            <ContentWithLabel
              label={t`components.PanelShapefile.date-updated.title`}
              lang={i18n.language} split
            >
              {new Date(shapefile.updated_at).toLocaleDateString(i18n.language)}
            </ContentWithLabel>
          </div>
        </Align>

        <hr />

        <DescriptionBlock
          label={t`components.PanelShapefile.description.title`}
        >
          <Markdown>
            {getInLang(shapefile.shapefile_trs, i18n.language).description}
          </Markdown>
        </DescriptionBlock>

        <hr />

        <SpacedContent>
          {!!getInLang(shapefile.shapefile_trs, i18n.language).citation && (
            <ContentWithLabel label={t`components.PanelShapefile.Citation.title`} lang={i18n.language} labelMinWidth={CSS_LABEL_WIDTH} split>
              <Linkify componentDecorator={myLink}>
                {getInLang(shapefile.shapefile_trs, i18n.language).citation}
              </Linkify>
            </ContentWithLabel>
          )}

          <ContentWithLabel label={t`components.PanelShapefile.license.title`} lang={i18n.language} labelMinWidth={CSS_LABEL_WIDTH} split>
            <a
              href={shapefile.licenseByLicenseId.url}
              target="_blank"
              rel="noreferrer"
            >
              {shapefile.licenseByLicenseId.name}
            </a>
          </ContentWithLabel>

          {!!shapefile.deposit_uri && (
            <ContentWithLabel label={t`components.PanelShapefile.DepositURI.title`} lang={i18n.language} labelMinWidth={CSS_LABEL_WIDTH} split>
              <Linkify componentDecorator={myLink}>{shapefile.deposit_uri}</Linkify>
            </ContentWithLabel>
          )}

          <ContentWithLabel label={t`components.PanelShapefile.mediator.title`} lang={i18n.language} labelMinWidth={CSS_LABEL_WIDTH} split>
            {t`components.PanelChronology.mediator.content`}{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={t`components.PanelShapefile.mediator.url`}
            >{t`components.PanelShapefile.mediator.urltext`}</a>
          </ContentWithLabel>
        </SpacedContent>
      </ContentWithPadding>
    </div>
  );
};

PanelShapefile.propTypes = {};

export default PanelShapefile;
