import { gql } from "@apollo/client";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import {
  UNDETERMINED_LEFT,
  UNDETERMINED_RIGHT,
  useValidatedSearchStore,
} from "../store";

export const GetFullSiteQuery = gql`
  query GetFullSiteQuery($id: Int) {
    ako_site(where: { id: { _eq: $id } }) {
      name
      id
      geom
      altitude
      centroid
      city_geonameid
      city_name
      code
      created_at
      database_id
      end_date1
      end_date2
      geom_3d
      occupation
      start_date1
      start_date2
      updated_at
      site_ranges(order_by: { id: asc }) {
        end_date1
        end_date2
        id
        start_date2
        start_date1
        site_range__characs(order_by: { id: asc }) {
          exceptional
          id
          knowledge_type
          web_images
          charac {
            charac {
              charac {
                charac {
                  charac {
                    ark_id
                    id
                    parent_id
                    charac_trs {
                      description
                      lang_isocode
                      name
                    }
                  }
                  ark_id
                  id
                  parent_id
                  charac_trs {
                    description
                    lang_isocode
                    name
                  }
                }
                ark_id
                id
                parent_id
                charac_trs {
                  description
                  lang_isocode
                  name
                }
              }
              ark_id
              id
              parent_id
              charac_trs {
                description
                lang_isocode
                name
              }
            }
            ark_id
            id
            parent_id
            charac_trs {
              description
              lang_isocode
              name
            }
          }
          site_range__charac_trs {
            bibliography
            comment
            lang_isocode
          }
        }
      }
      database {
        database_trs {
          bibliography
          context_description
          copyright
          description
          geographical_limit
          lang_isocode
          re_use
          source_description
          source_relation
          subject
        }
        declared_creation_date
        default_language
        editor
        editor_url
        end_date
        geographical_extent
        geographical_extent_geom
        license_id
        id
        name
        owner
        database__authors {
          user {
            firstname
            lastname
          }
        }
        updated_at
      }
    }
  }
`;

export const GET_SITES_QUERY = gql`
  query GetSites {
    ako_site(limit: 10) {
      geom
      id
      name
      start_date1
      start_date2
      end_date1
      end_date2
    }
  }
`;

export class WhereSiteSearch {
  constructor() {
    this.reset();
  }

  // private
  reset() {
    this.where = {};
    this.chronology_root_where = {};
  }

  // private
  convertFromValidatedSearchStore() {
    this.reset();

    //console.log("useValidatedSearchStore.getState()", useValidatedSearchStore.getState());

    // this is to avoid caching when changing chronology
    const { selectedChronologyId } = useValidatedSearchStore.getState();
    this.chronology_root_where = {
      root_chronology_id: { _eq: selectedChronologyId },
    };

    // date filter
    const {
      chronologyStartDate,
      chronologyEndDate,
      chronologyFindIncludeUndetermined,
      chronologyFindOnlyInside,
    } = useValidatedSearchStore.getState();
    if (
      chronologyStartDate !== UNDETERMINED_LEFT ||
      chronologyEndDate !== UNDETERMINED_RIGHT
    ) {
      if (!("_and" in this.where)) this.where._and = [];

      if (chronologyFindOnlyInside) {
        this.where._and.push({
          site_ranges: {
            start_date2: { _gte: chronologyStartDate },
            end_date1: { _lte: chronologyEndDate },
          },
        });
      } else {
        this.where._and.push({
          site_ranges: {
            start_date1: { _lt: chronologyEndDate },
            end_date2: { _gt: chronologyStartDate },
          },
        });
      }
    }

    if (!chronologyFindIncludeUndetermined) {
      if (!("_and" in this.where)) this.where._and = [];
      this.where._and.push({
        site_ranges: {
          start_date1: { _neq: UNDETERMINED_LEFT },
          start_date2: { _neq: UNDETERMINED_RIGHT },
          end_date1: { _neq: UNDETERMINED_LEFT },
          end_date2: { _neq: UNDETERMINED_RIGHT },
        },
      });
    }

    // characs filter
    let characs = useValidatedSearchStore.getState().characSelection;
    if (characs.length > 0) {
      if (!("_and" in this.where)) this.where._and = [];
      this.where._and.push({
        site_ranges: {
          site_range__characs: {
            charac_id: { _in: [...characs] },
          },
        },
      });
    }

    // knowledgeTypes filter
    const knowledgeTypes = useValidatedSearchStore.getState().knowledgeTypes;
    if (knowledgeTypes.length > 0) {
      if (!("site_ranges" in this.where)) this.where.site_ranges = {};
      if (!("site_range__characs" in this.where.site_ranges))
        this.where.site_ranges.site_range__characs = {};
      if (!("_and" in this.where.site_ranges.site_range__characs))
        this.where.site_ranges.site_range__characs._and = [];

      this.where.site_ranges.site_range__characs._and.push({
        knowledge_type: { _in: [...knowledgeTypes] },
      });
    }

    // occupations filter
    const occupations = useValidatedSearchStore.getState().occupations;
    if (occupations.length > 0) {
      if (!("_and" in this.where)) this.where._and = [];

      this.where._and.push({
        occupation: { _in: [...occupations] },
      });
    }

    // exceptional filter
    const exceptional = useValidatedSearchStore.getState().exceptional;
    if (exceptional !== null) {
      if (!("site_ranges" in this.where)) this.where.site_ranges = {};
      if (!("site_range__characs" in this.where.site_ranges))
        this.where.site_ranges.site_range__characs = {};
      if (!("_and" in this.where.site_ranges.site_range__characs))
        this.where.site_ranges.site_range__characs._and = [];

      this.where.site_ranges.site_range__characs._and.push({
        exceptional: { _eq: exceptional },
      });
    }

    // illustrated filter
    const illustrated = useValidatedSearchStore.getState().illustrated;
    console.log("illustrated", illustrated);
    if (illustrated !== null) {
      if (!("site_ranges" in this.where)) this.where.site_ranges = {};
      if (!("site_range__characs" in this.where.site_ranges))
        this.where.site_ranges.site_range__characs = {};
      if (!("_and" in this.where.site_ranges.site_range__characs))
        this.where.site_ranges.site_range__characs._and = [];

      this.where.site_ranges.site_range__characs._and.push({
        web_images: illustrated === true ? { _neq: "" } : { _eq: "" },
      });
    }

    // centroid filter
    const centroid = useValidatedSearchStore.getState().centroid;
    if (centroid !== null) {
      if (!("_and" in this.where)) this.where._and = [];

      this.where._and.push({
        centroid: { _eq: centroid },
      });
    }

    // editor filter
    const editors = useValidatedSearchStore.getState().editors;
    if (editors.length > 0) {
      if (!("database" in this.where)) this.where.database = {};
      if (!("_and" in this.where.database)) this.where.database._and = [];

      this.where.database._and.push({
        editor: { _in: [...editors] },
      });
    }

    // datasetTypes filter
    const datasetTypes = useValidatedSearchStore.getState().datasetTypes;
    if (datasetTypes.length > 0) {
      if (!("database" in this.where)) this.where.database = {};
      if (!("_and" in this.where.database)) this.where.database._and = [];

      this.where.database._and.push({
        type: { _in: [...datasetTypes] },
      });
    }

    // scaleResolutions filter
    const scaleResolutions =
      useValidatedSearchStore.getState().scaleResolutions;
    if (scaleResolutions.length > 0) {
      if (!("database" in this.where)) this.where.database = {};
      if (!("_and" in this.where.database)) this.where.database._and = [];

      this.where.database._and.push({
        scale_resolution: { _in: [...scaleResolutions] },
      });
    }

    // authors filter
    const authors = useValidatedSearchStore.getState().authors;
    if (authors.length > 0) {
      if (!("database" in this.where)) this.where.database = {};
      if (!("database__authors" in this.where.database))
        this.where.database.database__authors = {};
      if (!("_and" in this.where.database.database__authors))
        this.where.database.database__authors._and = [];

      this.where.database.database__authors._and.push({
        user_id: { _in: [...authors] },
      });
    }

    // database filter
    const databases = useValidatedSearchStore.getState().databases;
    if (databases.length > 0) {
      this.where.database_id = { _in: [...databases] };
    }

    // textual filter
    const textual = useValidatedSearchStore.getState().textual;
    const textualOn = useValidatedSearchStore.getState().textualOn;
    if (textual !== "") {
      if (textualOn === "all" || textualOn === "dataset-name") {
        if (!("_or" in this.where)) this.where._or = [];
        this.where._or.push({
          database: {
            name: {
              _ilike: `%${textual}%`,
            },
          },
        });
      }
      if (textualOn === "all" || textualOn === "resource-name") {
        if (!("_or" in this.where)) this.where._or = [];
        this.where._or.push({
          name: {
            _ilike: `%${textual}%`,
          },
        });
      }
      if (textualOn === "all" || textualOn === "city-name") {
        if (!("_or" in this.where)) this.where._or = [];
        this.where._or.push({
          city_name: {
            _ilike: `%${textual}%`,
          },
        });
      }
      if (textualOn === "all" || textualOn === "comments") {
        if (!("_or" in this.where)) this.where._or = [];

        this.where._or.push({
          site_ranges: {
            site_range__characs: {
              site_range__charac_trs: {
                comment: {
                  _ilike: `%${textual}%`,
                },
              },
            },
          },
        });
      }
      if (textualOn === "all" || textualOn === "bibliography") {
        if (!("_or" in this.where)) this.where._or = [];

        this.where._or.push({
          site_ranges: {
            site_range__characs: {
              site_range__charac_trs: {
                bibliography: {
                  _ilike: `%${textual}%`,
                },
              },
            },
          },
        });
      }
    }

    //console.log("where: ", this.where);
    //console.log("shapefiles_where: ", this.shapefiles_where);
  }

  // private
  addCharacFilter(charac_id) {
    if (!("site_ranges" in this.where)) this.where.site_ranges = { _or: [] };
    if (!("site_range__characs" in this.where.site_ranges))
      this.where.site_ranges.site_range__characs = { _or: [] };

    this.where.site_ranges.site_range__characs._or.push({
      charac_id: { _eq: charac_id },
    });
  }

  // public
  getGqlQuery() {
    this.convertFromValidatedSearchStore();

    const queryObj = {
      GetSites: {
        ako_site: {
          __args: {
            limit: 100000,
            where: this.where,
          },
          geom: true,
          id: true,
          name: true,
          start_date1: true,
          start_date2: true,
          end_date1: true,
          end_date2: true,
          centroid: true,
          site_ranges: {
            site_range__characs: {
              knowledge_type: true,
            },
          },
          exceptional_count: {
            __aliasFor: "site_ranges_aggregate",
            __args: {
              where: { site_range__characs: { exceptional: { _eq: true } } },
            },
            aggregate: {
              count: true,
            },
          },
        },
        ako_chronology_root: {
          __args: {
            where: this.chronology_root_where,
          },
          root_chronology_id: true,
        },
      },
    };
    const query = jsonToGraphQLQuery(queryObj);
    //console.log("query : ", query);
    const ret = gql`query ${query}`;
    //console.log("ret", ret);
    return ret;
  }
}
