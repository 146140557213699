import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import './styles/overrides.scss';
import App from './App';
//import reportWebVitals from './reportWebVitals';

// remove the primary loading element from index.html
document.getElementById('primary-loading').remove();
document.getElementById('primary-loading-text').remove();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
