export const UNDETERMINED_LEFT = -2147483648;
export const UNDETERMINED_RIGHT = 2147483647;

export function yearToHuman(year, lang="fr") {
  if (year === UNDETERMINED_RIGHT || year === UNDETERMINED_LEFT) {
    switch (lang) {
      case 'fr':
        return "indeterminé";
      case 'en':
        return "undetermined";
      default:
    }
  }

  if (year <= 0) year=year-1;
  switch (lang) {
    case 'fr':
      return year < 0 ? `${-year} AEC` : `${year} EC`;
    case 'en':
      return year < 0 ? `${-year} BCE` : `${year} CE`;
    default:
  }
}

export function yearToHumanNumber(year) {
  if (year === -2147483648 || year === 2147483647) return "";
  if (year <= 0) year=year-1;
  return year;
}

export function HumanNumberToComputerYear(year, undetermined = UNDETERMINED_LEFT) {
  if (year === '') return undetermined;
  if (year <= 0) year=parseInt(year)+1;
  return year;
}

