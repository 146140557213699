"use strict";

import { ArkeoClustering } from "./ArkeoClustering";
import { ArkeoSpiderfy } from "./ArkeoSpiderfy";
import config from "../../config";

export class ArkeoLayer {
  constructor(map, id, selectedChronologyId, geojson, ungrouped = false) {
    console.log(
      "creating new ArkeoLayer instance with id : " + id,
      "ungrouped: ",
      ungrouped
    );
    this.map = map;
    this.id = id;
    this.selectedChronologyId = selectedChronologyId;
    this.geojson = geojson;
    this.ungrouped = ungrouped;

    // map source
    this.mapSource = null;

    // clusters layer
    this.layerClusters = null;

    // discs layer
    this.layerDiscs = null;

    // arkeo cluster
    this.arkeocluster = new ArkeoClustering(
      this.map,
      this.id,
      this.selectedChronologyId,
      this.geojson
    );
    this.arkeoClusterInited = false;

    // arkeo spiderfy
    this.arkeospiderfy = new ArkeoSpiderfy(this.map, this.id);
  }

  setUngrouped(ungrouped) {
    const layers = this.map.getStyle().layers;
    layers.forEach((layer) => {
      if (layer.id.startsWith(`cluster_${this.geojsonResultId}-spiderfy-`)) {
        console.log("spiderfy removing layer : ", layer.id);
        this.map.removeLayer(layer.id);
      }
    });

    this.ungrouped = ungrouped;
    //this.arkeospiderfy.removeSpiderfy();
    this.map.getSource(this.id).setClusterOptions({
      cluster: !ungrouped,
    });
    //this.arkeospiderfy = new ArkeoSpiderfy(this.map, this.id);
    //this.map.redraw();
  }

  addToMap = () => {
    console.assert(this.mapSource === null, "mapSource not set");
    console.assert(this.layerDiscs === null, "layerDiscs not set");
    console.assert(this.layerClusters === null, "layerClusters not set");
    this.mapSource = this.map.addSource(this.id, this._makeSource());
    this.map.addLayer(this._makeClustersLayer());
    this.layerClusters = this.map.getLayer("cluster_" + this.id);
    this.map.addLayer(this._makeDiscsLayer());
    this.layerDiscs = this.map.getLayer("discs_" + this.id);

    // add events listener
    this.mapSource.on("data", this._onSourceAdded);
    //this.map.on('styledata', (e) => console.log("styledata: ", e));
  };

  removeFromMap = () => {
    console.log("removing ArkeoLayer instance with id : " + this.id);
    if (this.mapSource === null) {
      return;
    }
    // remove clusters & spiderfy
    this.arkeospiderfy.removeSpiderfy();
    this.arkeocluster.removeClustersMarkers();

    // remove events listener
    this.mapSource.off("data", this._onSourceAdded);

    // remove layers & source
    this.map.removeLayer("discs_" + this.id);
    this.map.removeLayer("cluster_" + this.id);
    this.map.removeSource(this.id);

    this.mapSource = null;
  };

  _makeSource = () => {
    return {
      type: "geojson",
      data: this.geojson,
      cluster: !this.ungrouped,
      clusterRadius: 60,
      clusterMaxZoom: 22,
      clusterProperties: this.arkeocluster.clusterProperties,
      attribution: config.map.attribution,
    };
  };

  _makeDiscsLayer = () => {
    return {
      id: "discs_" + this.id,
      type: "symbol",
      source: this.id,
      filter: ["!=", "cluster", true],
      layout: {
        "icon-image": [
          "concat",
          "arkeo_",
          ["get", "exceptional"],
          "_",
          ["get", "color"],
          "_",
          ["get", "knowledge"],
          "_",
          ["get", "centroid"],
        ],
        "icon-anchor": ["match", ["get", "centroid"], 1, "center", "bottom"],
        "icon-offset": [
          "match",
          ["get", "centroid"],
          1,
          ["literal", [0, 0]],
          ["literal", [0, 8]],
        ],
        //'icon-size': 0.375,
        "icon-allow-overlap": true, // recommended
        //'text-field': "M",
      },
    };
  };

  _makeClustersLayer = () => {
    return {
      id: "cluster_" + this.id,
      type: "symbol", // must be symbol
      source: this.id,
      filter: ["==", "cluster", true],
      layout: {
        "icon-image": "arkeo-disc", //'arkeo_no_#f39306_5', //'arkeo-disc',
        "icon-allow-overlap": true, // recommended
      },
      paint: {
        "icon-color": "transparent",
      },
    };
  };

  _onSourceAdded = (e) => {
    if (this.id !== e.sourceId) return;
    //console.log("e: "+this.id, e.sourceId);
    if (!this.arkeoClusterInited && e.isSourceLoaded) {
      this.arkeocluster.loadClustersMarkers();
      this.arkeoClusterInited = true;

      this.arkeospiderfy.loadSpiderfy();
    }
  };
}
