import React, { useEffect, useState } from 'react'
//import PropTypes from 'prop-types'
import { getAllRenderedFeatures } from '../../lib/map/tools';

// i18n
import { useTranslation } from 'react-i18next';

import LegendWhen from './LegendWhen';
import LegendWhat from './LegendWhat';
import LegendWhere from './LegendWhere';
import LegendOther from './LegendOther';
import LegendCitations from './LegendCitations';
import ShareButton from '../ShareButton/ShareButton';
import SlidableTabs from '../SlidableTabs/SlidableTabs';

import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'

import './Legend.scss'
import CloseButton from '../CloseButton/CloseButton';

const Legend = () => {
  const { t, i18n } = useTranslation();
  const [ features, setFeatures ] = useState([]);
  const [ sidePanelOpened, map, mapUpdated, allMapDrawed, rightPanel, setRightPanel ] = useStore(state => [
    state.sidePanelOpened, state.mapObject, state.mapUpdated, state.allMapDrawed, state.rightPanel, state.setRightPanel
  ], shallow)

  useEffect(() => {
    console.log("Legend useEffect", allMapDrawed,mapUpdated);
    if (map && allMapDrawed && sidePanelOpened === "right") {
      getAllRenderedFeatures(map).then(features => {
        setFeatures(features);
      })
    }
  }, [map, mapUpdated, allMapDrawed, sidePanelOpened]);

  const tabs = [
    {
      code: "when",
      text: t('components.Legend.when.title'),
      content: <LegendWhen features={features}/>
    },
    {
      code: "what",
      text: t('components.Legend.what.title'),
      content: <LegendWhat features={features}/>
    },
    {
      code: "where",
      text: t('components.Legend.where.title'),
      content: <LegendWhere />
    },
    {
      code: "other",
      text: t('components.Legend.other.title'),
      content:  <LegendOther features={features}/>
    },
    {
      code: "citations",
      text: t('components.Legend.citations.title'),
      content:  <LegendCitations features={features}/>
    },
  ]

  return (
    <div className="Legend">
      <CloseButton />
      <div className="LegendHeader">
        <div className="LegendHeaderTitle">{t('components.Legend.title')}</div>
        <div className="LegendContainerRight">
          <div className="LegendResultSummary">
            <div>{t('components.Legend.ResultsSummary.on')} {new Date().toLocaleDateString(i18n.language)}</div>
            <div>{features.length} {t('components.Legend.ResultsSummary.count')}</div>
          </div>
          <div className="LegendHeaderButtons">
            <ShareButton />
          </div>
        </div>
      </div>
      <SlidableTabs id="LegendTabs" tabs={tabs} onSelect={k => setRightPanel({...rightPanel, id: k})} activeKey={rightPanel.id} umountOnExit></SlidableTabs>
    </div>
  )
}

Legend.propTypes = {}

export default Legend
