import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import LanguageDetector from 'i18next-browser-languagedetector';
import { Language } from "./enums/Language";
import translationEN from './locales/en.json';
import translationFR from './locales/fr.json';

let defaultLanguage = Language.FR;
if (navigator) {
  if (navigator.userLanguage && navigator.userLanguage.startsWith('en')) defaultLanguage = Language.EN;
  else if (navigator.language && navigator.language.startsWith('en')) defaultLanguage = Language.EN;
  else if (navigator.languages && navigator.languages.length>0 && navigator.languages[0].startsWith('en')) defaultLanguage = Language.EN;
}

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};

i18n
  //.use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLanguage,
    fallbackLng: Language.EN,

    keySeparator: ".",  // to support nested translations

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    saveMissing: true, // send not translated keys to endpoint
});

export default i18n;
