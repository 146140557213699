import { gql } from '@apollo/client';

/*
export const GetRootChronologies = gql`
query GetRootChronologies {
  ako_chronology(order_by: {start_date: asc}, where: {_and: {parent_id: {_eq: 0}, id: {_neq: 0}}}) {
    chronology_root {
      credits
      geom
    }
    id
    parent_id
    start_date
    end_date
    color
    chronology_trs {
      description
      name
      lang_isocode
    }
  }
}
`;
*/

export const GetRootChronologies = gql`
query GetRootChronologies {
  ako_chronology(order_by: {start_date: asc}, where: {_and: {parent_id: {_eq: 0}, id: {_neq: 0}}}) {
    chronology_root {
      credits
      geom
      editor
      deposit_uri
      editor_uri
    }
    id
    parent_id
    start_date
    end_date
    color
    id_ark_periodo
    id_ark_pactols
    chronology_trs {
      description
      name
      lang_isocode
    }
    chronologies(order_by: {start_date: asc}) {
      id
      parent_id
      start_date
      end_date
      color
      id_ark_periodo
      id_ark_pactols
      chronology_trs {
        description
        name
        lang_isocode
      }
      chronologies(order_by: {start_date: asc}) {
        id
        parent_id
        start_date
        end_date
        color
        id_ark_periodo
        id_ark_pactols
        chronology_trs {
          description
          name
          lang_isocode
        }
        chronologies(order_by: {start_date: asc}) {
          id
          parent_id
          start_date
          end_date
          color
          id_ark_periodo
          id_ark_pactols
          chronology_trs {
            description
            name
            lang_isocode
          }
            chronologies(order_by: {start_date: asc}) {
            id
            parent_id
            start_date
            end_date
            color
            id_ark_periodo
            id_ark_pactols
            chronology_trs {
              description
              name
              lang_isocode
            }
          }
        }
      }
    }
  }
}
`;

export const GetChronology = gql`
query GetChronology($id: Int) {
  ako_chronology(where: {_and: {parent_id: {_eq: 0}, id: {_eq: $id}}}) {
    chronology_root {
      credits
      geom
    }
    id
    parent_id
    start_date
    end_date
    color
    id_ark_periodo
    id_ark_pactols
    chronology_trs {
      description
      name
      lang_isocode
    }
    chronologies {
      id
      parent_id
      start_date
      end_date
      color
      id_ark_periodo
      id_ark_pactols
      chronology_trs {
        description
        name
        lang_isocode
      }
      chronologies {
        id
        parent_id
        start_date
        end_date
        color
        id_ark_periodo
        id_ark_pactols
        chronology_trs {
          description
          name
          lang_isocode
        }
        chronologies {
          id
          parent_id
          start_date
          end_date
          color
          id_ark_periodo
          id_ark_pactols
          chronology_trs {
            description
            name
            lang_isocode
          }
            chronologies {
            id
            parent_id
            start_date
            end_date
            color
            id_ark_periodo
            id_ark_pactols
            chronology_trs {
              description
              name
              lang_isocode
            }
          }
        }
      }
    }
  }
}
`;

export const GetChronologyDetails = gql`
query GetChronologyDetails($chronology_id: Int!) {
  ako_chronology_root(where: {root_chronology_id: {_eq: $chronology_id}}) {
    databases {
      database__authors {
        user {
          firstname
          lastname
          id
        }
      }
      id
      name
    }
    chronology {
      created_at
      updated_at
      start_date
      end_date
      chronologies(order_by: {start_date: asc}) {
        color
        start_date
        end_date
        chronology_trs {
          lang_isocode
          name
          description
        }
      }
      chronology_trs {
        lang_isocode
        name
        description
      }
      id
      id_ark_pactols
      id_ark_periodo
    }
    credits
    editor
    editor_uri
    geom
    root_chronology_id
    deposit_uri
    user {
      firstname
      lastname
      id
    }
    chronology_root_trs {
      lang_isocode
      geographical_covering
    }
    group {
      user__groups {
        user {
          firstname
          lastname
        }
      }
    }
  }
}
`;
