import { gql } from "@apollo/client";
import { useValidatedSearchStore } from "../store";
import { jsonToGraphQLQuery } from "json-to-graphql-query";

export const GetShapefiles = gql`
  query GetShapefiles {
    ako_shapefile(order_by: { id: asc }) {
      shapefile_trs {
        description
        citation
        name
        lang_isocode
      }
      id
      end_date
      start_date
    }
  }
`;

export const GetShapefilesByIds = gql`
  query GetShapefilesByIds($ids: [Int!]) {
    ako_shapefile(where: { id: { _in: $ids } }) {
      id
      geojson
      shapefile_trs {
        lang_isocode
        attribution
      }
    }
  }
`;

export const GetFullShapefile = gql`
  query GetFullShapefile($shapefile_id: Int!) {
    ako_shapefile(where: { id: { _eq: $shapefile_id } }) {
      declared_creation_date
      created_at
      updated_at
      start_date
      end_date
      deposit_uri
      editor
      editor_uri
      licenseByLicenseId {
        name
        url
        id
      }
      shapefile_trs {
        citation
        geographical_covering
        description
        name
        lang_isocode
        attribution
      }
      geojson
      shapefile__authors {
        user {
          firstname
          lastname
        }
      }
    }
  }
`;

export class getShapefiles {
  constructor() {
    this.reset();
  }

  reset() {
    this.shapefiles_where = {};
  }

  convertFromValidatedSearchStore() {
    this.reset();

    const selectedShapefiles =
      useValidatedSearchStore.getState().selectedShapefiles;
    this.shapefiles_where.id = {
      _in: selectedShapefiles,
    };
  }

  // public
  getGqlQuery() {
    this.convertFromValidatedSearchStore();

    const queryObj = {
      ako_shapefile: {
        __args: {
          where: this.shapefiles_where,
        },
        id: true,
        geojson: true,
        shapefile_trs: {
          lang_isocode: true,
          attribution: true,
        },
      },
    };

    const query = jsonToGraphQLQuery(queryObj);
    //console.log("query : ", query);
    const ret = gql`query ${query}`;
    //console.log("ret", ret);
    return ret;
  }
}
