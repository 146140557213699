import { getInLang } from "../../lib/translate";

/**
 * Draws a GeoJSON polygon on the map.
 *
 * @param {mapboxgl.Map} map - The map object.
 * @param {string} id - The ID of the polygon.
 * @param {Object} geojson - The GeoJSON data representing the polygon.
 * @param {string} [beforeLayerId] - The ID of the layer to insert the polygon before.
 * @param {string} [attribution] - The attribution for the polygon.
 */
export const drawGeojsonPolygon = (
  map,
  id,
  geojson,
  beforeLayerId = undefined,
  attribution = undefined
) => {
  const sourcedef = {
    type: "geojson",
    data: geojson,
  };
  if (attribution) sourcedef.attribution = attribution;
  try {
    map.addSource(`arkeoshp-src-${id}`, sourcedef);
  } catch (err) {
    console.log("shapefile add source error : ", err);
  }

  map.addLayer(
    {
      id: `arkeoshp-fill-${id}`,
      type: "fill",
      source: `arkeoshp-src-${id}`,
      filter: ["==", "$type", "Polygon"],
      layout: {},
      paint: {
        "fill-color": "#0080ff", // blue color fill
        "fill-opacity": 0.3,
      },
    },
    beforeLayerId
  );

  map.addLayer(
    {
      id: `arkeoshp-outline-${id}`,
      type: "line",
      source: `arkeoshp-src-${id}`,
      layout: {},
      //filter: ['==', '$type', 'LineString'],
      paint: {
        "line-color": "#000",
        "line-width": 3,
      },
    },
    beforeLayerId
  );
};

/**
 * Removes shapefiles from the map.
 *
 * @param {Map} map - The map object.
 */
export const removeShapefiles = (map) => {
  const idstoremove = map
    .getLayersOrder()
    .filter((name) => name.startsWith("arkeoshp-fill-"))
    .map((name) => name.split("-")[2]);

  for (const idtoremove of idstoremove) {
    map.removeLayer(`arkeoshp-fill-${idtoremove}`);
    map.removeLayer(`arkeoshp-outline-${idtoremove}`);
    map.removeSource(`arkeoshp-src-${idtoremove}`);
  }
};

/**
 * Loads shapefiles and draws them as polygons on the map.
 *
 * @param {Map} map - The map object.
 * @param {Array} shapefiles - An array of shapefiles (as geojson not parsed) to load.
 * @param {string} isolang - The language for the shapefile attribution.
 */
export const loadShapefiles = (map, shapefiles, isolang) => {
  removeShapefiles(map);

  const beforeLayerId = map
    .getLayersOrder()
    .filter((name) => name.startsWith("cluster_arkeosites-"))
    .pop();

  shapefiles.forEach((shapefile) => {
    const geojson_shp = JSON.parse(shapefile.geojson);
    drawGeojsonPolygon(
      map,
      shapefile.id,
      geojson_shp,
      beforeLayerId,
      getInLang(shapefile.shapefile_trs, isolang).attribution
    );
  });
};
