// zustand
import useStore, { useSearchStore } from './store'
//import {shallow} from 'zustand/shallow'
//

import _ from 'underscore';

/**
 * Return a Chronology Object giving an id.
 *
 * @param {Object[]} chronologies The global chronologies tree
 * @param {int} id
 * @returns {Object}
 */
export function getChronologyById(chronologies, id) {
  for (let chronology of chronologies) {
    if (chronology.id === id) return chronology;
    const _chronology = getChronologyById(chronology.chronologies, id);
    if (_chronology) return _chronology;
  }
  return null;
}

/**
 * Return a Chronology Object giving start and end date.
 *
 * @param {Object[]} chronologies The global chronologies tree
 * @param {int} id
 * @returns {Object}
 */
 export function getChronologyByDate(chronologies, start_date, end_date) {
  for (let chronology of chronologies) {
    if (chronology.start_date === start_date && chronology.end_date === end_date) return chronology;
    const _chronology = getChronologyByDate(chronology.chronologies, start_date, end_date);
    if (_chronology) return _chronology;
  }
  return null;
}

/**
 * Return the top level Chronology Object giving a start and end date.
 *
 * @param {Object[]} chronologies The global chronologies tree
 * @param {int} id
 * @returns {Object}
 */
 export function getChronologyByDatel1(chronologies, start_date, end_date) {
  for (let chronology of chronologies) {
    if (start_date >= chronology.start_date && end_date <= chronology.end_date) return chronology;
  }
  return null;
}

/**
 * Return the fineset level Chronology Object giving a start and end date.
 *
 * @param {Object[]} chronologies The global chronologies tree
 * @param {int} id
 * @returns {Object}
 */
export function getChronologyByDatel4(chronologies, start_date, end_date) {
  for (let chronology of chronologies) {
    if (start_date >= chronology.start_date && end_date <= chronology.end_date) {
      if (chronology.chronologies) {
        const finest = getChronologyByDatel4(chronology.chronologies, start_date, end_date);
        if (finest) return finest;
      }
      return chronology;
    }
  }
  return null;
}


/**
 * Test if all sub chronologies are selected or not
 * This code is an adaptation of the one from Arkeogis 4.x
 *
 * @param {int[]} selecteds
 * @param {Object} chronology
 * @param {int[]} concerneds
 * @returns {boolean}
 */
 function testSubChronologiesSelection(selecteds, chronology, concerneds) {
  concerneds.push(chronology.id);
  if (selecteds.indexOf(chronology.id) !== -1) {
    var ok = true;
    _.each(chronology.chronologies, function(subchronology) {
      if (!testSubChronologiesSelection(selecteds, subchronology, concerneds))
        ok=false;
    });
    return ok;
  }
  return false;
}

/**
 * Build a path for one Chronology
 * This code is an adaptation of the one from Arkeogis 4.x
 *
 * @param {Object[]} chronologies : The global chronologies tree
 * @param {Object} chronology : The chronology
 * @param {Ref of int[]} selectedsref : This is a Ref to an array of chronology ids (selection)
 * @returns {int[][]}
 */
function buildPath(chronologies, chronology, selectedsref) {

  // check if the parent is in selection, so we build the path from the parent before.
  if (chronology.parent && selectedsref.current.indexOf(chronology.parent.id) !== -1)
    return buildPath(chronologies, chronology.parent, selectedsref);

  // check if all childrends are also in selection, or not
  var concerneds = [];
  var withChildrens = false;
  if (testSubChronologiesSelection(selectedsref.current, chronology, concerneds)) {
    // this chronology have all it's childrends selecteds.

    if (concerneds.length > 1)
      withChildrens = true;

    // remove all concerneds from selecteds, because theses are childrens
    selectedsref.current = selectedsref.current.filter(id => !concerneds.includes(id))

  } else {
    // this chronology do NOT have all it's childrends selecteds.

    withChildrens = false;

    // remove only this chronology, not childrens
    var i=selectedsref.current.indexOf(chronology.id);
    if (i > -1) selectedsref.current.splice(i, 1);
  }

  // now build the full path
  var c=chronology;

  /*
  var path="";
  while(c) {
    path=getInLang(c.chronology_trs, 'fr').name+(path != '' ? ' / '+path : '');
    c=c.parent;
  }
  if (withChildrens) path+='*';
  */

  /*
  let path=[];
  while(c) {
    path = [ c.id, ...path ]
    c=c.parent;
  }
  if (withChildrens) path[path.length-1]=-path[path.length-1];

  return path;
  */

  return withChildrens ? -c.id : c.id;
}

/**
 * Build paths of selecteds chronologies.
 * This code is an adaptation of the one from Arkeogis 4.x
 *
 * @param {Object[]} chronologies : The global chronologies tree
 * @param {int[]} selecteds : An id array of seleteds chronologies (not compacted)
 * @returns
 */
export function buildPaths(chronologies, selecteds) {
  var paths=[];
  const selectedsref = {
    current: [...selecteds ]
  }
  while (selectedsref.current.length > 0) {
    var chronology = getChronologyById(chronologies, selectedsref.current[0]);
    if (chronology)
      paths.push(buildPath(chronologies, chronology, selectedsref));
    else {
      console.warn("a chronology doesn't exist anymore, id: ", selectedsref.current[0])
      selectedsref.current.splice(0, 1);
    }
  }
  return paths;
}

export function compactChronologiesSelection(selecteds) {
  const r = buildPaths(useStore.getState().chronologies, selecteds);
  console.log("compacted: ", r);
  return r;
}

/**
 * Get All Chronologies Ids of a chronology and it's childrens
 *
 * @param {Object} chronology : The chronology Object
 * @param {int[]} result : An array of chronology ids
 * @returns
 */
const getAllChronologiesIds = (chronology, result=[]) => {
  result.push(chronology.id);
  chronology.chronologies.forEach(_chronology => getAllChronologiesIds(_chronology, result));
  return result;
}

/**
 * Set the selection in the Serach Store, including childrens
 *
 * @param {Object} chronology : The chronology Object
 * @param {boolean} selected : set selected or not
 */
export const setChronologySelected = (chronology, selected) => {
  let chronologySelection = useSearchStore.getState().chronologySelection; // we need a fresh version of chronologySelection, the one took from useStore may be not the latest due to the trigger filtering, and may not be the latest version
  const ids = getAllChronologiesIds(chronology);
  let newSelection = selected ? [ ...chronologySelection, ...ids ] : chronologySelection.filter(id => !ids.includes(id));

  //useSearchStore.setState({chronologySelection : newSelection });
  useSearchStore.getState().setChronologySelection(newSelection);
}

/**
 *
 * @param {Object[]} chronologies : The global chronologies tree
 * @param {*} compactSelection
 * @returns
 */
export const decompactSelection = (chronologies, compactSelection) => {
  let result = [];
  compactSelection.forEach(selection => {
    if (selection < 0) {
      const chronology = getChronologyById(chronologies, -selection);
      result = [ ...result, ...getAllChronologiesIds(chronology) ];
    } else {
      result.push(selection);
    }
  })
  return result;
}

export const decompactChronologiesSelection = (compactSelection) => {
  return decompactSelection(useStore.getState().chronologies, compactSelection);
}
