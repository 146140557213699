// react
import { Suspense } from 'react'
import {
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom"
//

// translations
import './i18n';

//import { StoreRoute } from './lib/storeroute';
import StoreRoute  from './components/StoreRoute/StoreRoute';

import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from "@apollo/client";


// css
import styles from './App.module.scss';
//

// components
import InitMainData from './components/InitMainData/InitMainData';
import Header from './components/Header/Header'
import Main from './components/Main/Main'
//import About from './components/About/About'
//import Boot from './components/Boot/Boot'
import Loader from './components/Loader/Loader'
import WelcomeModal from './components/WelcomeModal/WelcomeModal'
import BurgerModal from './components/BurgerModal/BurgerModal'
//

// Apollo Client (GRAPHQL) SETUP
// This setup is only needed once per application;
const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    resultCacheMaxSize: Math.pow(2,19), // 2^17 was insuffisient. 2^18 was ok. So 2^19 is large. (2^19=512kB)
  }),
  link: new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
    //uri: "http://home.keblo.net:5001/v1/graphql",
    fetchOptions: {
      //mode: 'no-cors',
      credentials: 'include',
    },
  }),
});

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <Suspense fallback={<Loader fullHeight={true}/>}>
          <InitMainData>
            <div className={styles.App}>
              <Routes>
                <Route exact path="/" element={<Main/>}/>
                {/*<Route path="/about" element={<About/>}/>*/}
                {/*<Route path="/boot" element={<Boot/>}/>*/}
              </Routes>
              <Header></Header>
              <WelcomeModal/>
              <BurgerModal/>
            </div>
            <StoreRoute/>
          </InitMainData>
        </Suspense>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App;
