import React from 'react'
//import PropTypes from 'prop-types'
import FlatSelect from '../FlatSelect/FlatSelect'

// i18n
import { getInLang } from '../../lib/translate';
//

// zustand
import { useSearchStore } from '../../lib/store'
//import {shallow} from 'zustand/shallow'
//

//import _ from 'underscore';

import { setCharacSelected } from '../../lib/characsSelection';

/*
const forEachSubCharac = (charac, cb) => {
  if (charac.characs) {
    charac.characs.forEach(subcharac=> {
      cb(subcharac);
      forEachSubCharac(subcharac, cb);
    });
  }
}
*/

const subCharacsToArray = charac => {
  const result=[];

  const addSubs = _charac => {
    if (_charac.characs) {
      _charac.characs.forEach(subcharac => {
        result.push(subcharac);
        addSubs(subcharac);
      })
    }
  }

  addSubs(charac);

  return result;
}

const useCharacSelect = (charac) => {
  const characSelection = useSearchStore(
    state => state.characSelection,
    // 2022-01-08: disabled this optimisation when added isPartiallySelected.
    //(oldSelection, newSelection) => !(oldSelection.includes(charac.id) || newSelection.includes(charac.id)),
  );
  const isSelected = characSelection.includes(charac.id);
  const isPartiallySelected = subCharacsToArray(charac).find(c => characSelection.includes(c.id)) ? true : false;

  const setSelected = (selected) => {
    if (isSelected === selected) return;
    setCharacSelected(charac, selected);
  }

  return [ isSelected, isPartiallySelected, setSelected ];
}

const CharacMenu = props => {
  const { characs, charac, lang, path } = props;
  const [ isSelected, isPartiallySelected, setSelected] = useCharacSelect(charac);

  const notleaf = charac.characs && charac.characs.length > 0;
  const subcharacs = notleaf ? charac.characs : [];

  const onSelect = setSelected;

  return (
    <FlatSelect
      key={charac.id}
      label={getInLang(charac.charac_trs, lang).name}
      leaf={!notleaf}
      onSelect={onSelect}
      selected={isSelected}
      partiallySelected={isPartiallySelected}
      >
      {subcharacs.map(_charac => <CharacMenu
                                    key={_charac.id}
                                    characs={characs}
                                    charac={_charac}
                                    lang={lang}
                                    path={[...path, _charac.id]}
      />)}
    </FlatSelect>
  )
};

//CharacMenu.propTypes = {}

export default CharacMenu;
