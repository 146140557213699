import React, { useState, useEffect } from 'react';

// zustand
import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'
//

import InitCharacsData from './InitCharacsData';
import InitChronologiesData from './InitChronologiesData';
import InitShapefilesData from './InitShapefilesData';
import Loader from '../Loader/Loader'

const InitMainData = (props) => {
  const [ ready, setReady ] = useState(false);
  const [ characs, chronologies ] = useStore(state => [ state.characs, state.chronologies ], shallow);

  useEffect(() => {
    if (
      (characs && characs.length > 0)
      && (chronologies && chronologies.length > 0)
     ) setReady(true);
  }, [ characs, chronologies ]);

  if (ready) {
    return props.children;
  } else {
    return (
      <React.Fragment>
        <Loader fullHeight={true}/>
        <InitCharacsData />
        <InitChronologiesData />
        <InitShapefilesData />
      </React.Fragment>
    );
  }
};

export default InitMainData;
