import React, { useCallback, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import getManifest from "../../lib/nakala2iiif/nakala2iiif";
import CloseButton from "../CloseButton/CloseButton";
import { useMountTransition } from "../../lib/hook";

// i18n
import { useTranslation } from "react-i18next";

import styles from "./PicturesButton.module.scss";

export const PicturesButton = ({ site }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showResourceList, setShowResourceList] = useState();
  const [urls, setUrls] = useState([]);
  const hasTransitionedIn = useMountTransition(show, 1000);
  const [disableNavigation, setDisableNavigation] = useState(false);

  const _setShow = (show) => {
    setShow(show);
  };

  useEffect(() => {
    let _urls = new Set();
    site.site_ranges.forEach((range) => {
      range.site_range__characs.forEach((rc) => {
        rc.web_images
          .trim()
          .split(/[, ]+/)
          .forEach((url) => (url !== "" ? _urls.add(url) : ""));
      });
    });
    //_urls.push("https://iiif.home.keblo.net/manifest/10.34847/nkl.dad5x46w"); // martinique jpeg
    //_urls.push("https://iiif.home.keblo.net/manifest/10.34847/nkl.d86c3i95"); // png
    //_urls.push("https://iiif.home.keblo.net/manifest/10.34847/nkl.0c675041"); // pdf 499 pages
    //_urls.push("https://nakala.fr/10.34847/nkl.0c675041"); // pdf 499 pages
    //_urls.push("https://nakala.fr/10.34847/nkl.d6bem54v"); // jpeg2000 plusieurs images
    //_urls.push("https://nakala.fr/10.34847/nkl.397avfm8"); // SVG
    //_urls.push("https://nakala.fr/10.34847/nkl.95aac784"); // TIFF
    //_urls.push("https://nakala.fr/10.34847/nkl.cceeu6t6"); // jpeg + gif
    //_urls.push("https://nakala.fr/10.34847/nkl.a45dz9z6"); // BMP
    //_urls.push("https://nakala.fr/10.34847/nkl.5ad252tu"); // mp4 (video)

    setUrls(Array.from(_urls));
  }, [site.site_ranges]);

  return (
    <div className={styles.PicturesButton}>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="top"
        overlay={
          <Popover id={`popover-positioned-top`}>
            <Popover.Body>
              {urls.length > 0
                ? t("components.PicturesButton.pictures_label")
                : t("components.PicturesButton.nopictures_label")}
            </Popover.Body>
          </Popover>
        }
      >
        <button className={styles.button} onClick={(e) => _setShow(true)} disabled={urls.length === 0}>
          <img alt="pictures" src="/icons/pictures.png" />
        </button>
      </OverlayTrigger>

      {(show || hasTransitionedIn) && (
        <div className={`${styles.iframeContainer} ${hasTransitionedIn && styles.in} ${show && styles.show}`}>
          <div className={styles.iframeHeader}>
            <div className={styles.siteName}>{site.name}</div>
            <CloseButton
              onClick={() => {
                _setShow(false);
              }}
              withNavigation
              leftArrowFunc={
                disableNavigation
                  ? null
                  : () => {
                      setShowResourceList(Date.now());
                    }
              }
            />
          </div>

          <div className={styles.iframeContent}>
            {urls.length > 0 && (
              <MyFrame
                urls={urls}
                site={site}
                showResourceList={showResourceList}
                setDisableNavigation={setDisableNavigation}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const MyFrame = ({ urls, setDisableNavigation, showResourceList }) => {
  const { i18n } = useTranslation();
  const [newUrls, setNewUrls] = useState([]);

  const myRef = React.createRef();

  const onIframeLoaded = useCallback(() => {
    const fetchUrls = async () => {
      if (urls.length === 0) return;
      const resolvedUrls = await Promise.all(
        urls.map(async (url) => {
          if (url.startsWith("https://doi.org/") || url.startsWith("https://nakala.fr/")) {
            const murl = url.match(/^https:\/\/(doi\.org|nakala\.fr)\/([0-9]+\.[0-9]+)\/(nkl\.[0-9a-z]+)$/);
            let manifest = await getManifest(murl[2], murl[3]);
            // convert manifest text content to a data url
            const blob = new Blob([JSON.stringify(manifest, null, 2)], { type: "application/json" });
            return URL.createObjectURL(blob);
          } else {
            return url;
          }
        })
      );
      if (resolvedUrls.length > 0) {
        setNewUrls(resolvedUrls);
      }
    };
    fetchUrls(urls).catch((error) => console.error(error));
  }, [urls]);

  const onReceivedMessage = (event) => {
    if (event.origin !== window.location.origin || !event.data.miradorMessage) return;
    if (event.data.miradorMessage === "isWorkspaceAddVisible") {
      setDisableNavigation(event.data.status);
    }
  };

  useEffect(() => {
    myRef.current.contentWindow.postMessage(
      { arkeopenMessage: "ready", iiifresources: newUrls, language: i18n.language },
      "*"
    );
  }, [newUrls]);

  useEffect(() => {
    myRef.current.contentWindow.postMessage({ arkeopenMessage: "switchLang", language: i18n.language }, "*");
  }, [i18n.language]);

  useEffect(() => {
    if (showResourceList) {
      myRef.current.contentWindow.postMessage({ arkeopenMessage: "showResourceList" }, "*");
    }
  }, [showResourceList]);

  useEffect(function () {
    window.addEventListener("message", onReceivedMessage);
    return function () {
      window.removeEventListener("message", onReceivedMessage);
    };
  });

  return (
    <iframe
      className={styles.iframe}
      ref={myRef}
      style={{ opacity: newUrls.length > 0 ? 1 : 0 }}
      title="Mirador"
      width="100%"
      height="100%"
      src="/mirador/index.html"
      onLoad={onIframeLoaded}
    />
  );
};

export default PicturesButton;
