import React from "react";
//import PropTypes from 'prop-types'
import ShareButton from "../ShareButton/ShareButton";

import { GetDatabaseDetails } from "../../lib/queries/database";

import { getTranslatedLang } from "../../lib/translate";

import { useQuery } from "@apollo/client";

import Linkify from "react-linkify";

import useStore from "../../lib/store";

// i18n
import { useTranslation } from "react-i18next";
import { getInLang, getInLangWKey } from "../../lib/translate";
/** some translations "npm run extract-translations" : 'undefined','academic-work','contract','research_team','other'
 * t`arkeo.database.type.undefined`
 * t`arkeo.database.type.inventory`
 * t`arkeo.database.type.research`
 * t`arkeo.database.type.literary-work`
 *
 * t`arkeo.database.geographical_extent.undefined`
 * t`arkeo.database.geographical_extent.country`
 * t`arkeo.database.geographical_extent.continent`
 * t`arkeo.database.geographical_extent.international_waters`
 * t`arkeo.database.geographical_extent.world`
 *
 * t`arkeo.database.state.undefined`
 * t`arkeo.database.state.in-progress`
 * t`arkeo.database.state.finished`
 *
 * t`arkeo.database.context.undefined`
 * t`arkeo.database.context.academic-work`
 * t`arkeo.database.context.contract`
 * t`arkeo.database.context.research_team`
 * t`arkeo.database.context.other`
 */

import styles from "./PanelDataset.module.scss";
import Loader from "../Loader/Loader";
import MiniMap from "../MiniMap/MiniMap";
import Markdown from "../Markdown/Markdown";
import { yearToHuman } from "../../lib/year";

import CloseButton from "../CloseButton/CloseButton";
import {
  Align,
  ContentWithPadding,
  ContentWithLabel,
  TitleWithIcons,
  DescriptionBlock,
  SpacedContent,
} from "../ArkeoUI/ArkeoUI";

const myLink = (decoratedHref, decoratedText, key) => {
  return (
    <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
      {decoratedText}
    </a>
  );
};

const PanelDataset = (props) => {
  const { t, i18n } = useTranslation();
  const { id } = props;

  const setRightPanel = useStore((state) => state.setRightPanel);

  const database = useQuery(GetDatabaseDetails, {
    variables: {
      database_id: id,
    },
  }); // database = { loading, error, data }

  const loadingOrError = database.loading ? (
    <Loader />
  ) : database.error ? (
    database.error.message
  ) : !database.data.ako_database || database.data.ako_database.length === 0 ? (
    "Dataset not found."
  ) : null;

  if (loadingOrError) return <div className={styles.PanelDataset}>{loadingOrError}</div>;

  const db = database.data.ako_database[0];

  const geojson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: db.geographical_extent_geom,
      },
    ],
  };

  const nakalaurls = [];
  db.illustrations
    .trim()
    .split(/[, ]+/)
    .forEach((url) => (url !== "" ? nakalaurls.push(url) : ""));

  const CSS_LABEL_WIDTH = 230;

  return (
    <div className={styles.PanelDataset}>
      <MiniMap minimaptype="polygon" geojson={geojson} />
      <CloseButton rp={{ type: "legend", id: "other" }} />
      <ContentWithPadding>
        <TitleWithIcons title={db.name}>
          <ShareButton />
        </TitleWithIcons>
        <Align smallFont split className={styles.headingBlocks}>
          <div>
            <ContentWithLabel label={t`components.PanelDataset.temporal.title`} noColon />
            <ContentWithLabel label={t`components.PanelDataset.temporal.begin.title`} lang={i18n.language}>
              <div className="date">{yearToHuman(db.start_date)}</div>
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelDataset.temporal.end.title`} lang={i18n.language}>
              <div className="date">{yearToHuman(db.end_date)}</div>
            </ContentWithLabel>
            {/*
          <td className="chrononame">{getInLang(db.chronologies[0].chronology_trs, i18n.language).name}</td>
          <td><div className="chronocolor" style={{ backgroundColor: '#'+db.chronologies[0].color}}></div></td>
          */}
            {/*
          <td className="chrononame">{getInLang(db.chronologies.at(-1).chronology_trs, i18n.language).name}</td>
          <td><div className="chronocolor" style={{ backgroundColor: '#'+db.chronologies.at(-1).color}}></div></td>
              */}
            <ContentWithLabel label={t`components.PanelDataset.chronology.title`} lang={i18n.language}>
              <a
                href="/#"
                rel="noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  setRightPanel({
                    type: "chronology",
                    id: db.root_chronology_id,
                  });
                }}
              >
                {db.chronology_root.chronology && db.chronology_root.chronology.chronology_trs
                  ? getInLang(db.chronology_root.chronology.chronology_trs, i18n.language).name
                  : ""}
              </a>
            </ContentWithLabel>
          </div>

          <div>
            <ContentWithLabel label={t`components.PanelDataset.geographical.title`} noColon />
            {db.database__continents.length > 0 && (
              <>
                <ContentWithLabel label={t`components.PanelDataset.continents.title`} lang={i18n.language}>
                  {db.database__continents
                    .map((dc) => getInLang(dc.continent.continent_trs, i18n.language).name)
                    .join(", ")}
                </ContentWithLabel>
              </>
            )}
            {db.database__countries.length > 0 && (
              <ContentWithLabel label={t`components.PanelDataset.countries.title`} lang={i18n.language}>
                {db.database__countries.map((dc) => getInLang(dc.country.country_trs).name, i18n.language).join(", ")}
              </ContentWithLabel>
            )}
            {getInLang(db.database_trs, i18n.language).geographical_limit}
          </div>
        </Align>

        <hr />

        <Align smallFont split className={styles.headingBlocks}>
          <div>
            <ContentWithLabel label={t`components.PanelDataset.coordinator.title`} lang={i18n.language}>
              {`${db.user.lastname} ${db.user.firstname}`}
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelDataset.authors.title`} lang={i18n.language}>
              {db.database__authors.map((ug) => `${ug.user.lastname} ${ug.user.firstname}`).join(", ")}
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelDataset.editors.title`} lang={i18n.language}>
              {db.editor_url ? (
                <a href={db.editor_url} target="_blank" rel="noreferrer">
                  {db.editor}
                </a>
              ) : (
                db.editor
              )}
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelDataset.contributor.title`} lang={i18n.language}>
              {db.contributor}
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelDataset.type.title`} lang={i18n.language}>
              {t(`arkeo.database.type.${db.type}`)}
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelDataset.scale_resolution.title`} lang={i18n.language}>
              {t(`arkeo.database.scale_resolution.${db.scale_resolution}`)}
            </ContentWithLabel>
          </div>

          <div>
            <ContentWithLabel label={t`components.PanelDataset.date-created.title`} lang={i18n.language}>
              {new Date(db.created_at).toLocaleDateString(i18n.language)}
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelDataset.date-updated.title`} lang={i18n.language}>
              {new Date(db.updated_at).toLocaleDateString(i18n.language)}
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelDataset.state.title`} lang={i18n.language}>
              {t(`arkeo.database.state.${db.state}`)}
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelDataset.number_of_lines.title`} lang={i18n.language}>
              {db.imports[0].number_of_lines}
            </ContentWithLabel>
            <ContentWithLabel label={t`components.PanelDataset.number_of_sites.title`} lang={i18n.language}>
              {db.imports[0].number_of_sites}
            </ContentWithLabel>
          </div>
        </Align>

        <hr />

        <ContentWithLabel label={t`components.PanelDataset.subject.title`} lang={i18n.language} split>
          <Markdown>{getInLang(db.database_trs, i18n.language).subject}</Markdown>
        </ContentWithLabel>

        <hr />

        <DescriptionBlock label={t`components.PanelDataset.description.title`}>
          <Markdown>{getInLang(db.database_trs, i18n.language).description}</Markdown>
        </DescriptionBlock>

        <hr />

        <SpacedContent>
          <ContentWithLabel
            label={t`components.PanelDataset.source_description.title`}
            lang={i18n.language}
            labelMinWidth={CSS_LABEL_WIDTH}
            split
          >
            <Linkify componentDecorator={myLink}>
              {getInLangWKey(db.database_trs, "source_description", i18n.language, { emptyIsUndefined: true })}
            </Linkify>
          </ContentWithLabel>

          <ContentWithLabel
            label={t`components.PanelDataset.source_relation.title`}
            lang={i18n.language}
            labelMinWidth={CSS_LABEL_WIDTH}
            split
          >
            <Linkify componentDecorator={myLink}>
              {getInLangWKey(db.database_trs, "source_relation", i18n.language, {
                emptyIsUndefined: true,
              })}
            </Linkify>
          </ContentWithLabel>

          <ContentWithLabel
            label={t`components.PanelDataset.contexts.title`}
            lang={i18n.language}
            labelMinWidth={CSS_LABEL_WIDTH}
            split
          >
            {db.database_contexts.map((c) => t(`arkeo.database.context.${c.context}`)).join(", ")}
          </ContentWithLabel>

          <hr />

          <ContentWithLabel
            label={t`components.PanelDataset.DefaultLanguage.title`}
            lang={i18n.language}
            labelMinWidth={CSS_LABEL_WIDTH}
            split
          >
            {getTranslatedLang(t, db.default_language)}
          </ContentWithLabel>

          <ContentWithLabel
            label={t`components.PanelDataset.ReUse.title`}
            lang={i18n.language}
            labelMinWidth={CSS_LABEL_WIDTH}
            split
          >
            {getInLang(db.database_trs, i18n.language).re_use}
          </ContentWithLabel>

          <ContentWithLabel
            label={t`components.PanelDataset.Citation.title`}
            lang={i18n.language}
            labelMinWidth={CSS_LABEL_WIDTH}
            split
          >
            <Linkify componentDecorator={myLink}>{getInLang(db.database_trs, i18n.language).bibliography}</Linkify>
          </ContentWithLabel>

          <ContentWithLabel
            label={t`components.PanelDataset.license.title`}
            lang={i18n.language}
            labelMinWidth={CSS_LABEL_WIDTH}
            split
          >
            <a href={db.license.url} target="_blank" rel="noreferrer">
              {db.license.name}
            </a>
          </ContentWithLabel>

          <ContentWithLabel
            label={t`components.PanelDataset.HandleURL.title`}
            lang={i18n.language}
            labelMinWidth={CSS_LABEL_WIDTH}
            split
          >
            <Linkify componentDecorator={myLink}>{db.database_handles[0].url}</Linkify>
          </ContentWithLabel>

          <ContentWithLabel
            label={t`components.PanelDataset.HandleURI.title`}
            lang={i18n.language}
            labelMinWidth={CSS_LABEL_WIDTH}
            split
          >
            <Linkify componentDecorator={myLink}>{db.database_handles[0].identifier}</Linkify>
          </ContentWithLabel>

          <ContentWithLabel
            label={t`components.PanelDataset.mediator.title`}
            lang={i18n.language}
            labelMinWidth={CSS_LABEL_WIDTH}
            split
          >
            {t`components.PanelChronology.mediator.content`}{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={t`components.PanelDataset.mediator.url`}
            >{t`components.PanelDataset.mediator.urltext`}</a>
          </ContentWithLabel>
        </SpacedContent>
      </ContentWithPadding>
    </div>
  );
};

PanelDataset.propTypes = {};

export default PanelDataset;
