import React from "react";

// zustand
import useStore, { useSearchStore, useValidatedSearchStore, defaults } from "../../lib/store";
//import {shallow} from 'zustand/shallow'
//

// graphql
import { useQuery } from "@apollo/client";
import { GetRootChronologies } from "../../lib/queries/chronology.js";
import { useEffect } from "react";
//

import _ from "underscore";

import config from "../../config.json";

const convertChronologies = (chronologies, parent = null) => {
  return chronologies.map((chronology) => {
    const _chronologie = _.pick(
      chronology,
      "id",
      "parent_id",
      "start_date",
      "end_date",
      "color",
      "id_ark_periodo",
      "id_ark_pactols",
      "chronology_trs",
      "chronology_root"
    );
    _chronologie.chronologies = chronology.chronologies
      ? convertChronologies(chronology.chronologies, _chronologie)
      : [];
    _chronologie.parent = parent;
    return _chronologie;
  });
};

export default function InitChronologiesData(props) {
  const { /*loading, error, */ data } = useQuery(GetRootChronologies, { fetchPolicy: "no-cache" });
  const setChronologies = useStore((state) => state.setChronologies);
  const setSelectedChronology = useSearchStore((state) => state.setSelectedChronology);
  const setValidatedSelectedChronology = useValidatedSearchStore((state) => state.setSelectedChronology);

  useEffect(() => {
    if (data) {
      //console.log("data.ako_chronology", data.ako_chronology);
      const chronologies = convertChronologies(data.ako_chronology);
      setChronologies(chronologies);
      /*
      let searchedchronoselected = chronologies.find(c => c.id === config.chronology.default);
      defaults.selectedChronology = searchedchronoselected; // <- set the default chronologies when reset store
      //if (!searchedchronoselected) searchedchronoselected = chronologies[0];
      setSelectedChronology(searchedchronoselected)
      setValidatedSelectedChronology(searchedchronoselected)
      */
    }
  }, [data, setChronologies /*, setSelectedChronology, setValidatedSelectedChronology */]);

  return <React.Fragment />;
}
