import axios from "axios";
import { get } from "underscore";
import dcTerms from "./dcTerms";

async function getDataInfos(nakid1, nakid2) {
  var url = `https://api.nakala.fr/datas/${nakid1}/${nakid2}`;
  var response = await axios.get(url);
  //console.log("response: ", response.data);
  return response.data; // this will be the JSON data from the response
}

/*
function getMetaValue(metas, keys, defValue = null) {
  let value = null;

  console.log("getMetaValue: ", metas, keys, defValue);

  keys.forEach((key) => {
      if (value !== null) return;
      metas.forEach((meta) => {
          if (value !== null) return;
          if (meta.propertyUri === key) value = meta.value;
      });
  });

  return value !== null ? value : defValue;
}
*/

/**
 * Determines the type of a given property URI.
 *
 * @param {string} propertyUri - The property URI to determine the type of.
 * @returns {string} The type of the property URI. Possible values are "string", "uri", or "unknown".
 */
function knowTypeOfPropertyUri(propertyUri) {
  switch (propertyUri) {
    case "http://purl.org/dc/terms/title":
    case "http://nakala.fr/terms#title":
    case "http://purl.org/dc/terms/description":
    case "http://nakala.fr/terms#description":
    case "http://purl.org/dc/terms/license":
    case "http://nakala.fr/terms#license":
    case "http://purl.org/dc/terms/rightsHolder":
    case "http://nakala.fr/terms#rightsHolder":
    case "http://nakala.fr/terms#creator":
    case "http://www.w3.org/2001/XMLSchema#string":
      return "string";
    case "http://www.w3.org/2001/XMLSchema#anyURI":
      return "uri";
    default:
      return "unknown";
  }
}

function getMetaValueAsIIIFValue(metas, keys, defValue = []) {
  let value = [];

  //console.log("getMetaValue: ", metas, keys, defValue);

  keys.forEach((key) => {
    metas.forEach((meta) => {
      if (meta.propertyUri === key)
        value.push({
          "@value": meta.value,
          "@language": meta.lang ? meta.lang : "fr",
        });
    });
  });

  return value.length > 0 ? value : defValue;
}

function getAllMetaValueAsIIIFMetadata(nakametas) {
  let metadatas = [];

  nakametas.forEach((nakameta) => {
    const lang = nakameta.lang ? nakameta.lang : "none";
    const key = nakameta.propertyUri;
    if (!key) return;

    let term = "";
    if (key.startsWith("http://purl.org/dc/terms/")) {
      term = key.substring("http://purl.org/dc/terms/".length);
    } else if (key.startsWith("http://nakala.fr/terms#")) {
      term = key.substring("http://nakala.fr/terms#".length);
    }

    if (!term) return;

    if (!(term in metadatas)) {
      let dcTerm = dcTerms.find((dcTerm) => dcTerm.term === term);
      if (!dcTerm)
        dcTerm = {
          label: { none: [term] },
        };
      metadatas[term] = {
        label: dcTerm.label,
      };
    }
    if (!("value" in metadatas[term])) metadatas[term].value = {};

    const _setValue = (value) => {
      if (!(lang in metadatas[term].value)) metadatas[term].value[lang] = [value];
      else metadatas[term].value[lang].push(value);
    };

    if (nakameta.typeUri === "http://www.w3.org/2001/XMLSchema#string") _setValue(nakameta.value);
    else if (nakameta.typeUri === "http://www.w3.org/2001/XMLSchema#anyURI") _setValue(nakameta.value);
    else if (nakameta.propertyUri === "http://nakala.fr/terms#creator")
      _setValue(nakameta.value ? nakameta.value.fullName : "");
    else if (["string", "uri"].includes(knowTypeOfPropertyUri(nakameta.propertyUri))) _setValue(nakameta.value);
    else if (!nakameta.typeUri)
      // at the end, if typeUri is not defined, it is considered has string by nakala...
      _setValue(nakameta.value);
    else console.warn("unknown typeUri / propertyUri: ", nakameta);
  });
  return metadatas;
}

async function getManifest(nakid1, nakid2) {
  let dataMetadataJSON = await getDataInfos(nakid1, nakid2);
  let dataCitation = dataMetadataJSON["citation"];

  let nakameta = getAllMetaValueAsIIIFMetadata(dataMetadataJSON.metas);

  let manifest = {
    "@context": "http://iiif.io/api/presentation/3/context.json",
    //"@id": "https://api.nakala.fr/datas/" + nakid1 + "/" + nakid2,
    //"@id": `https://iiif.home.keblo.net/manifest/${nakid1}/${nakid2}`,
    "@type": "sc:Manifest",
    /*
      "label": getMetaValueAsIIIFValue(dataMetadataJSON.metas, [
          "http://purl.org/dc/terms/title",
          "http://nakala.fr/terms#title",
      ], dataCitation),
      */
    label: nakameta.title ? nakameta.title.value : dataCitation,
    logo: "https://arkeogis.org/wp-content/uploads/2021/04/logo_nakala.png",
    //"metadata": getAllMetaValueAsIIIFMetadata(dataMetadataJSON.metas),
    description: nakameta.description ? nakameta.description.value : "",
    license: getMetaValueAsIIIFValue(
      dataMetadataJSON.metas,
      [
        "http://purl.org/dc/terms/license",
        "http://nakala.fr/terms#license",
        "http://purl.org/dc/terms/rightsHolder",
        "http://nakala.fr/terms#rightsHolder",
      ],
      "UNKNOWN"
    ),
    attribution: dataCitation, //"[nakala2iiif: Attribution]",
    sequences: [
      {
        "@id": "https://api.nakala.fr/data/" + nakid1 + "/" + nakid2 + "/sequence/normal",
        "@type": "sc:Sequence",
        label: [
          {
            "@value": "Normal Sequence",
            "@language": "en",
          },
        ],
        canvases: [],
      },
    ],
    structures: [],

    nakala2iiif: {
      metadata: dataMetadataJSON,
    },
  };

  /*
  manifest.metadata.push({
    "label": { "none": "Nakala ID" },
    "value": { "none": `${nakid1}/${nakid2}` },
  });*/
  /*
  manifest.metadata.nakaid = {
    "label": { "none": "Nakala ID" },
    "value": { "none": `${nakid1}/${nakid2}` },
  };
  */

  manifest.metadata = Object.values(nakameta);

  let data = dataMetadataJSON; //await getDataInfos(nakid1, nakid2);

  console.log("data: ", data);

  let supportedFormats = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "image/jp2",
    //'image/svg+xml', // tested not supported by nakala iiif
    "image/tiff",
    //'image/gif',  // tested not supported by nakala iiif
    //'image/bmp',  // tested not supported by nakala iiif
    "image/webp", // not tested
    //'image/x-icon',
    //'image/vnd.microsoft.icon',
    //'image/vnd.dwg',
    //'video/mp4',  // tested not supported by nakala iiif
  ];
  data.files
    .filter((file) => !supportedFormats.includes(file.mime_type))
    .forEach((file) => {
      console.log("file format not supported : ", file.mime_type, file.name);
    });

  // @todo: respect order, the promise.all is not respecting the order of the files
  await Promise.all(
    data.files
      .filter((file) => supportedFormats.includes(file.mime_type))
      .map(async (file) => {
        let sha1 = file.sha1;
        let url = `https://api.nakala.fr/iiif/${nakid1}/${nakid2}/${sha1}/info.json`;
        let response = await axios.get(url);
        let info = response.data;

        let canvas = {
          //"@id": "https://virtual-id.nakala.fr/iiif/" + nakid1 + "/" + nakid2 + "/" + sha1 + "/canvas",
          "@id": url,
          "@type": "sc:Canvas",
          label: file.name,
          height: info.height,
          width: info.width,
          images: [
            {
              "@context": "http://iiif.io/api/presentation/2/context.json",
              //"@id": "https://virtual-id.nakala.fr/iiif/" + nakid1 + "/" + nakid2 + "/" + sha1 + "/images",
              "@id": url,
              "@type": "oa:Annotation",
              motivation: "sc:painting",
              resource: {
                "@id": "https://api.nakala.fr/iiif/" + nakid1 + "/" + nakid2 + "/" + sha1 + "/full/full/0/default.jpg",
                "@type": "dctypes:Image",
                format: "image/jpeg",
                service: {
                  "@context": "http://iiif.io/api/image/3/context.json",
                  //"@id": "https://virtual-id.nakala.fr/iiif/" + nakid1 + "/" + nakid2 + "/" + sha1,
                  "@id": "https://api.nakala.fr/iiif/" + nakid1 + "/" + nakid2 + "/" + sha1,
                  profile: "level2",
                },
                height: info.height,
                width: info.width,
              },
              //"on": "https://virtual-id.nakala.fr/iiif/" + nakid1 + "/" + nakid2 + "/" + sha1 + "/canvas"
              on: url,
            },
          ],
          related: "",
          thumbnail: {
            "@id": "https://api.nakala.fr/iiif/" + nakid1 + "/" + nakid2 + "/" + sha1 + "/full/!200,200/0/default.jpg",
            "@type": "dctypes:Image",
            format: "image/jpeg",
            height: 200,
            width: 200,
            service: {
              "@context": "http://iiif.io/api/image/3/context.json",
              //"@id": "https://virtual-id.nakala.fr/iiif/" + nakid1 + "/" + nakid2 + "/" + sha1,
              "@id": "https://api.nakala.fr/iiif/" + nakid1 + "/" + nakid2 + "/" + sha1,
              profile: "level2",
            },
          },
          /*
          "nakala2iiif": {
              "info.json": info
          }
          */
        };

        manifest.sequences[0].canvases.push(canvas);
      })
  );

  console.log("manifest: ", manifest);

  return manifest;
}

export default getManifest;
