import React from "react";

import Accordion from "react-bootstrap/Accordion";

import styles from "./ArkeoUI.module.scss";

export const ContentWithLabel = ({
  label,
  children,
  lang = "fr",
  inline = false,
  labelMinWidth = 46,
  labelMaxWidth = 230,
  noColon = false,
  split = false,
}) => (
  <div
    className={`${styles.contentWithLabel} ${inline ? styles.inline : ""} ${split ? styles.split : ""} ${
      !children ? styles.noChildren : ""
    }`}
  >
    <div style={{ minWidth: labelMinWidth, maxWidth: labelMaxWidth }}>
      {label}
      {noColon === true ? null : lang === "fr" ? " : " : ":"}
    </div>
    <div>{children}</div>
  </div>
);

export const Align = ({ children, className, gap, split = false, smallFont = false }) => (
  <div
    className={`${styles.align} ${split && styles.split} ${className ? className : ""} ${
      smallFont ? styles.smallFont : ""
    }`}
    style={{ gap }}
  >
    {children}
  </div>
);

export const TitleWithIcons = ({ children, className, title }) => (
  <div className={`${styles.titleWithIcons} ${className ? className : ""}`}>
    <h1>{title}</h1>
    <div>{children}</div>
  </div>
);

export const ContentWithPadding = ({ children, className, padding = 30 }) => (
  <div style={{ padding }} className={className ? className : ""}>
    {children}
  </div>
);

export const SimpleAccordion = ({ children, className, title, defaultActiveKey }) => {
  const activeKey = defaultActiveKey === null ? null : "0";
  return (
    <Accordion className={`${styles.simpleAccordion} ${className ? className : ""}`} flush defaultActiveKey={activeKey}>
      <Accordion.Item className={styles.item} eventKey="0">
        <Accordion.Header className={styles.header}>{title}</Accordion.Header>
        <Accordion.Body className={styles.body}>{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export const Sticker = ({ children, className }) => {
  return <div className={`${styles.sticker} ${className ? className : ""}`}>{children}</div>;
};

export const DescriptionBlock = ({ label, children, className }) => {
  return (
    <div className={`${styles.descriptionBlock} ${className ? className : ""}`}>
      <div>{label}</div>
      <div>{children}</div>
    </div>
  );
};

export const SpacedContent = ({ children, className, gap }) => {
  return (
    <div className={`${styles.spacedContent} ${className ? className : ""}`} style={{gap}}>
      {children}
    </div>
  );
};
