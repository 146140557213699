export const HelpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      fill="currentColor"
      d="M12 0A12.003 12.003 0 0 0 0 12a12.002 12.002 0 0 0 12 12 12.002 12.002 0 0 0 12-12 12.01 12.01 0 0 0-3.52-8.48A12.01 12.01 0 0 0 12 0Zm0 22.154c-2.693 0-5.275-1.07-7.18-2.974a10.157 10.157 0 0 1 0-14.36 10.157 10.157 0 0 1 14.36 0A10.157 10.157 0 0 1 22.154 12 10.163 10.163 0 0 1 12 22.154Z"
    />
    <path
      fill="currentColor"
      d="M13.385 17.077c0 1.846-2.77 1.846-2.77 0s2.77-1.846 2.77 0Zm-.723-11.481a3.694 3.694 0 0 0-4.354 3.635.923.923 0 1 0 1.846 0c0-.55.244-1.071.667-1.423.423-.35.982-.495 1.522-.393.746.146 1.331.73 1.477 1.476.162.86-.3 1.716-1.108 2.05a2.694 2.694 0 0 0-1.635 2.517v.388a.923.923 0 1 0 1.846 0v-.388a.867.867 0 0 1 .487-.814 3.687 3.687 0 0 0 2.224-4.076 3.726 3.726 0 0 0-2.972-2.972Z"
    />
  </svg>
);

export const FullScreenIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none">
    <path
      fill="currentColor"
      d="M21.083 14.667c-.55 0-.916.366-.916.916v3.3l-4.859-4.858a.886.886 0 0 0-1.283 0 .886.886 0 0 0 0 1.284l4.858 4.858h-3.3c-.55 0-.916.366-.916.916s.366.917.916.917h5.5c.55 0 .917-.367.917-.917v-5.5c0-.55-.367-.916-.917-.916Zm0-14.667h-5.5c-.55 0-.916.367-.916.917s.366.916.916.916h3.3l-4.858 4.859a.886.886 0 0 0 0 1.283.886.886 0 0 0 1.284 0l4.858-4.858v3.3c0 .55.366.916.916.916S22 6.967 22 6.417v-5.5c0-.55-.367-.917-.917-.917ZM3.117 1.833h3.3c.55 0 .916-.366.916-.916S6.967 0 6.417 0h-5.5C.367 0 0 .367 0 .917v5.5c0 .55.367.916.917.916s.916-.366.916-.916v-3.3l4.859 4.858a.886.886 0 0 0 1.283 0 .886.886 0 0 0 0-1.283L3.117 1.833Zm3.575 12.192-4.859 4.858v-3.3c0-.55-.366-.917-.916-.917S0 15.033 0 15.583v5.5c0 .55.367.917.917.917h5.5c.55 0 .916-.367.916-.917s-.366-.917-.916-.917h-3.3l4.858-4.858a.886.886 0 0 0 0-1.283.886.886 0 0 0-1.283 0Z"
    />
  </svg>
);

export const BurgerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="15" fill="none">
    <path
      fill="currentColor"
      d="M.979 1.957A.978.978 0 1 1 .979 0h16.304a.979.979 0 0 1 0 1.957H.98Zm0 6.522a.978.978 0 1 1 0-1.958h16.304a.978.978 0 0 1 0 1.958L.98 8.478Zm0 6.521a.978.978 0 1 1 0-1.957h16.304a.978.978 0 1 1 0 1.957H.979Z"
    />
  </svg>
);

export const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" fill="none">
    <path
      fill="currentColor"
      d="m39.334 35.31-9.574-9.577c4.814-6.259 4.334-15.301-1.444-21.025A15.916 15.916 0 0 0 16.975 0c-4.119 0-8.186 1.55-11.34 4.708-6.26 6.259-6.26 16.423 0 22.682a15.916 15.916 0 0 0 11.34 4.708c3.423 0 6.848-1.07 9.684-3.264l9.627 9.524c.428.429.963.642 1.55.642.535 0 1.125-.213 1.551-.642.805-.802.805-2.194-.052-3.048Zm-22.307-7.544c-3.156 0-6.046-1.231-8.292-3.423-4.547-4.547-4.547-11.982 0-16.584a11.65 11.65 0 0 1 8.292-3.423c3.155 0 6.046 1.23 8.292 3.423 2.246 2.194 3.423 5.136 3.423 8.292 0 3.155-1.231 6.045-3.423 8.292-2.194 2.246-5.189 3.423-8.292 3.423Z"
    />
  </svg>
);

export const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22.049" height="22.049" viewBox="0 0 5.834 5.834" fill="none"><g fill="currentColor"><path d="M122.58 131.007c.166-.166.426-.175.581-.02.155.155.146.415-.02.581l-5.011 5.011c-.166.166-.426.175-.581.02-.155-.155-.146-.415.02-.58l5.011-5.012z" style={{strokeWidth:.264583}} transform="translate(-117.625 -130.876)"/><path d="M123.328 136.018c.167.166.176.426.02.581-.154.155-.415.146-.58-.02l-5.012-5.011c-.166-.166-.175-.426-.02-.581.155-.155.415-.146.581.02l5.011 5.011z" style={{strokeWidth:.264583}} transform="translate(-117.625 -130.876)"/></g></svg>
)

export const ArrowLeftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.606" height="18.874" fill="none"><path fill="currentColor" d="M10.13 16.662c.547.548.595 1.387.107 1.875s-1.328.44-1.875-.107L.444 10.512c-.547-.547-.595-1.386-.107-1.874.488-.489 1.328-.44 1.874.106z"/><path fill="currentColor" d="M8.394.444C8.942-.103 9.78-.15 10.269.337s.44 1.327-.107 1.875L2.244 10.13c-.547.547-1.386.595-1.875.107-.488-.489-.44-1.328.107-1.875z"/></svg>
)

export const ArrowRightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10.606" height="18.874" fill="none"><path fill="currentColor" d="M.476 2.212C-.07 1.664-.119.825.37.337s1.328-.44 1.875.107l7.918 7.918c.547.547.595 1.386.107 1.874-.489.489-1.328.44-1.875-.106z"/><path fill="currentColor" d="M2.212 18.43c-.548.547-1.387.595-1.875.107s-.44-1.328.107-1.875l7.918-7.918c.547-.547 1.386-.595 1.874-.107.489.489.44 1.328-.106 1.875z"/></svg>
)

export const ImageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none"><path fill="currentColor" d="M10.267 6.233c2.227 0 4.033 1.888 4.033 4.217 0 2.329-1.806 4.217-4.033 4.217-2.228 0-4.034-1.888-4.034-4.217 0-2.329 1.806-4.217 4.034-4.217Zm0 6.325c1.114 0 2.016-.944 2.016-2.108 0-1.164-.902-2.108-2.016-2.108-1.115 0-2.017.944-2.017 2.108 0 1.165.902 2.108 2.017 2.108ZM30.937 0A2.06 2.06 0 0 1 33 2.063v28.875A2.06 2.06 0 0 1 30.937 33H2.063A2.061 2.061 0 0 1 0 30.937V2.063C0 .923.922 0 2.063 0h28.875ZM2.063 29.906Zm28.875 0v-3.095l-7.518-7.518a1.031 1.031 0 0 0-1.458 0l-6.063 6.063c-1.095 1.095-1.834 1.08-2.916 0-1.08-1.082-1.938-1.938-1.938-1.938a1.031 1.031 0 0 0-1.458 0l-7.22 7.219c.186.186.444.3.728.3h26.812a1.03 1.03 0 0 0 1.032-1.03Zm0-6.012v-20.8a1.03 1.03 0 0 0-1.03-1.032H3.093a1.03 1.03 0 0 0-1.031 1.032v24.931l6.794-6.794a2.06 2.06 0 0 1 2.916 0l2.667 2.667 6.792-6.792a2.06 2.06 0 0 1 2.916 0l6.79 6.787Z"/></svg>
)

export const SVGIcon = () => {
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="37" fill="none"><path fill="currentColor" d="M9.287 13.86h-2.73c-.56 0-1.014.453-1.014 1.014v7.251a1.014 1.014 0 1 0 2.03 0v-2.61h1.714a2.84 2.84 0 0 0 2.827-2.827 2.806 2.806 0 0 0-.819-2.01 2.801 2.801 0 0 0-2.008-.819Zm0 3.625H7.572v-1.596h1.715a.777.777 0 0 1 .797.799c0 .44-.357.797-.797.797Zm8.006-3.638h-2.741c-.561 0-1.015.454-1.015 1.015v7.275a1.014 1.014 0 0 0 1.015 1.015h2.74a2.842 2.842 0 0 0 2.842-2.843v-3.637a2.841 2.841 0 0 0-2.843-2.825h.002Zm.813 6.472h-.002a.81.81 0 0 1-.813.811h-1.724v-5.253h1.724a.812.812 0 0 1 .813.813l.002 3.63Zm3.486 1.828-.002-7.284c0-.561.456-1.015 1.015-1.015h4.558a1.014 1.014 0 1 1 0 2.03h-3.55v1.607h2.352a1.014 1.014 0 1 1 0 2.03h-2.353v2.622a1.014 1.014 0 1 1-2.03 0l.01.01ZM33.663 4.9A4.909 4.909 0 0 0 28.76 0H4.902A4.909 4.909 0 0 0 0 4.9v27.2A4.909 4.909 0 0 0 4.902 37h18.244c.133 0 .266-.028.389-.08a.999.999 0 0 0 .329-.22l9.497-9.496a1.027 1.027 0 0 0 .3-.72L33.663 4.9ZM2.03 32.1V4.9a2.878 2.878 0 0 1 2.872-2.87H28.76a2.878 2.878 0 0 1 2.872 2.87v20.57h-4.6a4.906 4.906 0 0 0-4.9 4.901v4.597l-17.23.002A2.878 2.878 0 0 1 2.03 32.1Zm28.165-4.599-6.034 6.034v-3.163a2.875 2.875 0 0 1 2.87-2.872l3.164.001Z"/></svg>
}

export const ShareIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none"><path fill="currentColor" d="M27.107 0c-3.241 0-5.893 2.653-5.893 5.892 0 .529.076 1.038.21 1.525L10.52 12.87a5.884 5.884 0 0 0-4.628-2.262C2.652 10.608 0 13.26 0 16.5s2.653 5.892 5.892 5.892c1.872 0 3.545-.89 4.627-2.26l10.905 5.452a5.838 5.838 0 0 0-.21 1.524c0 3.24 2.654 5.892 5.893 5.892 3.24 0 5.892-2.653 5.892-5.892 0-3.241-2.653-5.893-5.892-5.893a5.89 5.89 0 0 0-4.628 2.263l-10.903-5.453c.132-.487.209-.998.209-1.525 0-.528-.076-1.036-.209-1.524l10.905-5.452a5.886 5.886 0 0 0 4.626 2.26c3.241 0 5.893-2.652 5.893-5.892S30.347 0 27.107 0Zm0 2.358a3.519 3.519 0 0 1 3.536 3.536 3.519 3.519 0 0 1-3.536 3.536 3.519 3.519 0 0 1-3.536-3.536 3.519 3.519 0 0 1 3.536-3.536ZM5.89 12.965A3.519 3.519 0 0 1 9.427 16.5a3.519 3.519 0 0 1-3.536 3.536A3.519 3.519 0 0 1 2.355 16.5a3.519 3.519 0 0 1 3.536-3.536Zm21.216 10.608a3.519 3.519 0 0 1 3.536 3.536 3.519 3.519 0 0 1-3.536 3.536 3.519 3.519 0 0 1-3.536-3.536 3.519 3.519 0 0 1 3.536-3.536Z"/></svg>
)

export const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="32" fill="none"><path fill="currentColor" d="M23.92 13.44a1.28 1.28 0 0 0-1.84 0l-6.92 6.92V1.32c0-.72-.6-1.32-1.32-1.32-.72 0-1.32.6-1.32 1.32v19.041l-6.88-6.92a1.28 1.28 0 0 0-1.84 0 1.28 1.28 0 0 0 0 1.84l9.16 9.16c.12.12.279.2.44.28.12.079.281.079.44.079.16 0 .32-.04.48-.12.16-.08.32-.16.44-.28l9.16-9.16c.48-.44.48-1.28 0-1.8Z"/><path fill="currentColor" d="M26.361 32H1.321C.6 32 0 31.4 0 30.68V22.2c0-.72.6-1.32 1.32-1.32.72 0 1.32.6 1.32 1.32v7.2h22.4v-7.2c0-.72.6-1.32 1.321-1.32.72 0 1.32.6 1.32 1.32v8.48c-.04.72-.6 1.32-1.32 1.32Z"/></svg>
)

export const ChevronIcon = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15" height="8" fill="none"><path fill="currentColor" d="M.266.281a.908.908 0 0 0 0 1.284l6.164 6.17a.907.907 0 0 0 1.284 0l6.164-6.17A.907.907 0 1 0 12.594.283L7.072 5.81 1.55.283A.908.908 0 0 0 .266.28Z"/></svg>
)

export const PrevArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" fill="none"><path fill="currentColor" fillRule="evenodd" d="M9.969 10.672c.158.632-.71 1.44-1.382 1.026L.26 6.548c-.336-.179-.355-1.087 0-1.284L8.587.114c.671-.415 1.54.394 1.382 1.025v9.533Z" clipRule="evenodd"/></svg>
)
export const NextArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" fill="none"><path fill="currentColor" fillRule="evenodd" d="M.019 1.14C-.14.508.729-.301 1.4.113l8.328 5.152c.335.177.355 1.085 0 1.282L1.4 11.698c-.671.415-1.54-.394-1.382-1.026V1.14Z" clipRule="evenodd"/></svg>
)

export const ResetIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" fill="none"><path fill="currentColor" fillRule="evenodd" d="M7.9 4.4V0L2.4 5.5 7.9 11V6.6h1.101c3.64 0 6.6 2.96 6.6 6.6 0 3.64-2.96 6.6-6.6 6.6-3.64 0-6.6-2.96-6.6-6.6v-1.1H.2v1.1C.2 18.052 4.148 22 9 22s8.8-3.948 8.8-8.8S13.853 4.4 9 4.4H7.9Z" clipRule="evenodd"/></svg>
)
