// maplibregl-mapbox-request-transformer plugin

import { isMapboxURL, transformMapboxUrl } from 'maplibregl-mapbox-request-transformer'

const mapboxKey = 'pk.eyJ1IjoiYXJrZW9maSIsImEiOiJja2dvcTFranUwNG5yMnlvaG45bzFsNmtwIn0.NJRCLNOJTIFpUH-pq6ew3g'

const transformRequest = (url, resourceType) => {
  if (isMapboxURL(url)) {
    return transformMapboxUrl(url, resourceType, mapboxKey)
  }
  
  // Do any other transforms you want
  return {url}
}

export default transformRequest;
