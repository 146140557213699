import React from 'react'
//import PropTypes from 'prop-types'
import FlatSelect from '../FlatSelect/FlatSelect'

// i18n
import { getInLang } from '../../lib/translate';
import { useTranslation } from 'react-i18next';
//

// zustand
import { useSearchStore, UNDETERMINED_LEFT, UNDETERMINED_RIGHT } from '../../lib/store'
import {shallow} from 'zustand/shallow'
//

import Popover from 'react-bootstrap/Popover';

import { yearToHuman } from '../../lib/year';

//import _ from 'underscore';

//import { setChronologySelected } from '../../lib/chronologiesSelection';

const useChronologySelect = (chronologies, chronology, path) => {
  const[
    chronologyStartDate,
    chronologyEndDate,
    setChronologyStartDate,
    setChronologyEndDate,
    setChronologyStartEndDate,
  ] = useSearchStore(state => [
    state.chronologyStartDate,
    state.chronologyEndDate,
    state.setChronologyStartDate,
    state.setChronologyEndDate,
    state.setChronologyStartEndDate,
  ], shallow);

  const isSelected = chronologyStartDate !== UNDETERMINED_LEFT
                  && chronologyEndDate !== UNDETERMINED_RIGHT
                  && chronology.start_date >= chronologyStartDate
                  && chronology.end_date <= chronologyEndDate;

  const isPartiallySelected = chronologyStartDate !== UNDETERMINED_LEFT
                            && chronologyEndDate !== UNDETERMINED_RIGHT
                            && chronologyStartDate <= chronology.end_date
                            && chronologyEndDate >= chronology.start_date;

  const setSelected = (/*selected*/) => {
    //if (isSelected === selected) return;

    if (chronologyStartDate === UNDETERMINED_LEFT) {
      setChronologyStartEndDate(chronology.start_date, chronology.end_date);
    } else if (chronology.start_date < chronologyStartDate) {
      setChronologyStartDate(chronology.start_date);
    } else if (chronology.end_date > chronologyEndDate) {
      setChronologyEndDate(chronology.end_date);
    } else {
      setChronologyStartEndDate(UNDETERMINED_LEFT, UNDETERMINED_RIGHT);
    }
  }

  return [ isSelected, isPartiallySelected, setSelected ];
}

const ChronologyMenu = props => {
  const { t, i18n } = useTranslation();
  const { chronologies, chronology, lang, path, disabled } = props;
  const [ isSelected, isPartiallySelected, setSelected] = useChronologySelect(chronologies, chronology, path);

  const notleaf = chronology.chronologies && chronology.chronologies.length > 0;
  const subchronologies = notleaf ? chronology.chronologies : [];

  //console.log("chronology.color", chronology.color)

  return (
    <FlatSelect
      key={chronology.id}
      label={chronology.parent_id === 0 ? t('components.FormSearchWhen.periodmenu.label') : getInLang(chronology.chronology_trs, lang).name}
      leaf={!notleaf}
      onSelect={setSelected}
      selected={isSelected}
      partiallySelected={isPartiallySelected}
      unselectable={chronology.parent_id === 0}
      disabled={disabled}
      pop={
        <Popover id={`popover-period`}>
          <Popover.Body>
            <strong>{yearToHuman(chronology.start_date, i18n.language)} =&gt; {yearToHuman(chronology.end_date, i18n.language)}</strong>
            {chronology.color ? <div className="periodmenu-colorblock" style={{backgroundColor: '#'+chronology.color}}/> : ''}
          </Popover.Body>
        </Popover>
      }
    >
      {subchronologies.map(_chronology => <ChronologyMenu
                                  key={_chronology.id}
                                  chronologies={chronologies}
                                  chronology={_chronology}
                                  lang={lang}
                                  path={[...path, _chronology.id]}
      />)}
    </FlatSelect>
  )
};

//ChronologyMenu.propTypes = {}

export default ChronologyMenu;
