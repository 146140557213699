import { useEffect, useRef, useState } from "react";

/**
 * Custom hook to manage fullscreen mode for a specified element or the whole document.
 * @param {string | HTMLElement} elementOrElementId - The element or element ID to display in fullscreen mode.
 * @returns {[boolean, function, boolean]} - An array containing the fullscreen mode status, function to toggle fullscreen, and whether fullscreen mode is enabled.
 */
export const useFullScreen = (elementOrElementId = null) => {
  // On umount, exit fullscreen
  const [fullscreenIsActive, setFullscreenIsActive] = useState(false);
  const element = useRef();

  element.current = !elementOrElementId
    ? document.documentElement
    : elementOrElementId === "string"
    ? document.getElementById(elementOrElementId)
    : element;

  useEffect(() => {
    const handleChange = () => {
      setFullscreenIsActive(document.fullscreenElement === element.current);
    };
    element.current.addEventListener("fullscreenchange", handleChange);
    return () => {
      if (element.current) {
        element.current.removeEventListener("fullscreenchange", handleChange);
      }
      if (document.fullscreenElement === element.current) {
        return document.exitFullscreen();
      }
    };
  }, []);

  const toggleFullscreen = () => {
    // if there's another element currently full screen, exit first
    if (
      document.fullscreenElement &&
      document.fullscreenElement !== element.current
    ) {
      document.exitFullscreen();
    }
    if (!document.fullscreenElement) {
      element.current.requestFullscreen();
      setFullscreenIsActive(true);
    } else {
      document.exitFullscreen();
      setFullscreenIsActive(false);
    }
  };

  return [fullscreenIsActive, toggleFullscreen, document.fullscreenEnabled];
};

/**
 * Custom hook that manages transition states based on mount and unmount events.
 *
 * @param {boolean} isMounted - Flag indicating if the component is mounted.
 * @param {number} unmountDelay - Delay in milliseconds before transitioning out on unmount.
 * @returns {boolean} - State indicating if the transition has occurred.
 */
export const useMountTransition = (isMounted, unmountDelay) => {
  const [hasTransitionedIn, setHasTransitionedIn] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (isMounted && !hasTransitionedIn) {
      setHasTransitionedIn(true);
    } else if (!isMounted && hasTransitionedIn) {
      timeoutId = setTimeout(() => setHasTransitionedIn(false), unmountDelay);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [unmountDelay, isMounted, hasTransitionedIn]);

  return hasTransitionedIn;
};
