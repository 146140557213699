// import underscore
import _ from "underscore";

/**
 * Retrieves all rendered sites features from the map. Includes Arkeo site also from clusters.
 * This function is async because it needs to fetch the cluster leaves.
 *
 * @param {Object} map - The map object.
 * @param {(PointLike | Array<PointLike>)?} geom - The geometry to query features in.
 * @returns {Array} - An array of all rendered features.
 */
export const getAllRenderedFeatures = async (map, geom = undefined) => {
  const allfeatures = [];
  const features_circle = map.queryRenderedFeatures(undefined, {
    layers: map
      .getLayersOrder()
      .filter((name) => name.startsWith("discs_arkeosites-")),
  });
  allfeatures.push(...features_circle);

  const features_cluster = map.queryRenderedFeatures(undefined, {
    layers: map
      .getLayersOrder()
      .filter((name) => name.match(/^cluster_arkeosites-[0-9]+$/)),
  });
  const clusterSources = _.filter(map.style.sourceCaches, (source) =>
    source.id.startsWith("arkeosites-")
  );
  for (const feature_cluster of features_cluster) {
    for (const clusterSource of clusterSources) {
      const source = map.getSource(clusterSource.id);
      const leaves = await source.getClusterLeaves(
        feature_cluster.id,
        1000000,
        0
      );
      allfeatures.push(...leaves);
    }
  }
  return allfeatures;
};

/**
 * Retrieves all rendered sites features from the map. Includes Arkeo site also from OPENED ONLY clusters.
 * This function is sync.
 *
 * @param {Object} map - The map object.
 * @param {(PointLike | Array<PointLike>)?} geom - The geometry to query features in.
 * @returns {Array} - An array of all rendered features.
 */
export const getAllRenderedFeaturesIncludingOpenedClusters = (
  map,
  geom = undefined
) => {
  const layers = map
    .getLayersOrder()
    .filter(
      (name) =>
        name.startsWith("discs_arkeosites-") ||
        name.match(/^cluster_arkeosites-[0-9]+-spiderfy-leaf[0-9]+$/)
    );
  const features = map.queryRenderedFeatures(geom, {
    filter: ["has", "site_id"],
    layers,
  });

  return features;
};

/**
 * Finds the cluster and feature for a given site ID.
 *
 * @param {Object} map - The map instance.
 * @param {string} sourceId - The ID of the map source to query.
 * @param {string} site_id - The site ID to find.
 * @returns {Promise<Array>} A promise that resolves to an array containing the cluster and the site feature.
 *                           If the site is not in any cluster, the first element will be null.
 */
export const findClusterOfFeature = async (map, sourceId, site_id) => {
  let clusters = map.querySourceFeatures(sourceId, {
    filter: ["has", "cluster_id"],
  });

  for (const cluster of clusters) {
    let clusterId = cluster.properties.cluster_id;
    const leaves = await map
      .getSource(sourceId)
      .getClusterLeaves(clusterId, Infinity, 0);
    const site_feature = leaves.find((f) => f.properties.site_id === site_id);
    if (site_feature) {
      return [cluster, site_feature];
    }
  }

  // not in any cluster
  const site_features = map.queryRenderedFeatures({
    filter: ["==", ["get", "site_id"], site_id],
  });
  return [null, site_features.length > 0 ? site_features[0] : null];
};

/**
 * Searches for a feature in the map based on the provided site ID.
 * @param {mapboxgl.Map} map - The map object.
 * @param {string} site_id - The site ID to search for.
 * @returns {Promise<Object|null>} - The found feature object or null if not found.
 */
export const searchFeature = async (map, site_id) => {
  // not in any cluster
  const site_features = map.queryRenderedFeatures({
    filter: ["==", ["get", "site_id"], site_id],
  });
  return site_features.length > 0 ? site_features[0] : null;
};

/**
 * Calculates the distance between a given point and a feature on the map.
 *
 * @param {Map} map - The map object.
 * @param {Event} event - The event object containing the point coordinates.
 * @param {Feature} feature - The feature object to calculate the distance from.
 * @returns {number} The distance between the event point and the feature.
 */
export const calcDist = (map, point, feature) => {
  const fp = map.project(feature.geometry.coordinates);
  const distance = Math.sqrt(
    Math.pow(point.x - fp.x, 2) +
      Math.pow(point.y - fp.y + (feature.properties.centroid > 0 ? 0 : 10), 2)
  );
  return distance;
};

/**
 * Retrieves the feature from a given mouse point on the map.
 *
 * @param {Map} map - The map object.
 * @param {Point} point - The mouse point on the map.
 * @returns {Feature|null} - The found feature or null if no feature is found.
 */
export const getSiteFromMousePoint = (map, point) => {
  const founds = getAllRenderedFeaturesIncludingOpenedClusters(map, point)
    .filter((f) => "site_id" in f.properties)
    .sort((a, b) => calcDist(map, point, a) - calcDist(map, point, b));
  return founds.length > 0 ? founds[0] : null;
};
