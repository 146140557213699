const dcTerms = [
  {
    "term": "abstract",
    "label": { "en": [ "Abstract" ], "fr": [ "Résumé" ]},
    "uri": "http://purl.org/dc/terms/abstract",
    "definition": { "en": [ "A summary of the resource." ], "fr": [ "Un résumé de la ressource." ]},
  },
  {
    "term": "accessRights",
    "label": { "en": [ "Access Rights" ], "fr": [ "Droits d'accès" ]},
    "uri": "http://purl.org/dc/terms/accessRights",
    "definition": { "en": [ "Information about who can access the resource or an indication of its security status." ], "fr": [ "Informations sur qui peut accéder à la ressource ou une indication de son statut de sécurité." ]},
  },
  {
    "term": "accrualMethod",
    "label": { "en": [ "Accrual Method" ], "fr": [ "Méthode d'accroissement" ]},
    "uri": "http://purl.org/dc/terms/accrualMethod",
    "definition": { "en": [ "The method by which items are added to a collection." ], "fr": [ "La méthode par laquelle des éléments sont ajoutés à une collection." ]},
  },
  {
    "term": "accrualPeriodicity",
    "label": { "en": [ "Accrual Periodicity" ], "fr": [ "Périodicité d'accroissement" ]},
    "uri": "http://purl.org/dc/terms/accrualPeriodicity",
    "definition": { "en": [ "The frequency with which items are added to a collection." ], "fr": [ "La fréquence à laquelle des éléments sont ajoutés à une collection." ]},
  },
  {
    "term": "accrualPolicy",
    "label": { "en": [ "Accrual Policy" ], "fr": [ "Politique d'accroissement" ]},
    "uri": "http://purl.org/dc/terms/accrualPolicy",
    "definition": { "en": [ "The policy governing the addition of items to a collection." ], "fr": [ "La politique régissant l'ajout d'éléments à une collection." ]},
  },
  {
    "term": "alternative",
    "label": { "en": [ "Alternative Title" ], "fr": [ "Titre alternatif" ]},
    "uri": "http://purl.org/dc/terms/alternative",
    "definition": { "en": [ "An alternative name for the resource." ], "fr": [ "Un nom alternatif pour la ressource." ]},
  },
  {
    "term": "audience",
    "label": { "en": [ "Audience" ], "fr": [ "Public" ]},
    "uri": "http://purl.org/dc/terms/audience",
    "definition": { "en": [ "A class of entity for whom the resource is intended or useful." ], "fr": [ "Une classe d'entité pour laquelle la ressource est destinée ou utile." ]},
  },
  {
    "term": "available",
    "label": { "en": [ "Date Available" ], "fr": [ "Date de disponibilité" ]},
    "uri": "http://purl.org/dc/terms/available",
    "definition": { "en": [ "Date (often a range) that the resource became or will become available." ], "fr": [ "Date (souvent une plage) à laquelle la ressource est devenue ou deviendra disponible." ]},
  },
  {
    "term": "bibliographicCitation",
    "label": { "en": [ "Bibliographic Citation" ], "fr": [ "Citation bibliographique" ]},
    "uri": "http://purl.org/dc/terms/bibliographicCitation",
    "definition": { "en": [ "A bibliographic reference for the resource." ], "fr": [ "Une référence bibliographique pour la ressource." ]},
  },
  {
    "term": "conformsTo",
    "label": { "en": [ "Conforms To" ], "fr": [ "Conforme à" ]},
    "uri": "http://purl.org/dc/terms/conformsTo",
    "definition": { "en": [ "An established standard to which the described resource conforms." ], "fr": [ "Une norme établie à laquelle la ressource décrite se conforme." ]},
  },
  {
    "term": "contributor",
    "label": { "en": [ "Contributor" ], "fr": [ "Contributeur" ]},
    "uri": "http://purl.org/dc/terms/contributor",
    "definition": { "en": [ "An entity responsible for making contributions to the resource." ], "fr": [ "Une entité responsable de faire des contributions à la ressource." ]},
  },
  {
    "term": "coverage",
    "label": { "en": [ "Coverage" ], "fr": [ "Couverture" ]},
    "uri": "http://purl.org/dc/terms/coverage",
    "definition": { "en": [ "The spatial or temporal topic of the resource, the spatial applicability of the resource, or the jurisdiction under which the resource is relevant." ], "fr": [ "Le sujet spatial ou temporel de la ressource, l'applicabilité spatiale de la ressource, ou la juridiction sous laquelle la ressource est pertinente." ]},
  },
  {
    "term": "created",
    "label": { "en": [ "Date Created" ], "fr": [ "Date de création" ]},
    "uri": "http://purl.org/dc/terms/created",
    "definition": { "en": [ "Date of creation of the resource." ], "fr": [ "Date de création de la ressource." ]},
  },
  {
    "term": "creator",
    "label": { "en": [ "Creator" ], "fr": [ "Créateur" ]},
    "uri": "http://purl.org/dc/terms/creator",
    "definition": { "en": [ "An entity primarily responsible for making the resource." ], "fr": [ "Une entité principalement responsable de la création de la ressource." ]},
  },
  {
    "term": "date",
    "label": { "en": [ "Date" ], "fr": [ "Date" ]},
    "uri": "http://purl.org/dc/terms/date",
    "definition": { "en": [ "A point or period of time associated with an event in the lifecycle of the resource." ], "fr": [ "Un point ou une période de temps associé à un événement dans le cycle de vie de la ressource." ]},
  },
  {
    "term": "dateAccepted",
    "label": { "en": [ "Date Accepted" ], "fr": [ "Date d'acceptation" ]},
    "uri": "http://purl.org/dc/terms/dateAccepted",
    "definition": { "en": [ "Date of acceptance of the resource." ], "fr": [ "Date d'acceptation de la ressource." ]},
  },
  {
    "term": "dateCopyrighted",
    "label": { "en": [ "Date Copyrighted" ], "fr": [ "Date de copyright" ]},
    "uri": "http://purl.org/dc/terms/dateCopyrighted",
    "definition": { "en": [ "Date of copyright." ], "fr": [ "Date de copyright." ]},
  },
  {
    "term": "dateSubmitted",
    "label": { "en": [ "Date Submitted" ], "fr": [ "Date de soumission" ]},
    "uri": "http://purl.org/dc/terms/dateSubmitted",
    "definition": { "en": [ "Date of submission of the resource." ], "fr": [ "Date de soumission de la ressource." ]},
  },
  {
    "term": "description",
    "label": { "en": [ "Description" ], "fr": [ "Description" ]},
    "uri": "http://purl.org/dc/terms/description",
    "definition": { "en": [ "An account of the resource." ], "fr": [ "Un compte rendu de la ressource." ]},
  },
  {
    "term": "educationLevel",
    "label": { "en": [ "Audience Education Level" ], "fr": [ "Niveau d'éducation du public" ]},
    "uri": "http://purl.org/dc/terms/educationLevel",
    "definition": { "en": [ "A class of entity, defined in terms of progression through an educational or training context, for which the described resource is intended." ], "fr": [ "Une classe d'entité, définie en termes de progression à travers un contexte éducatif ou de formation, pour laquelle la ressource décrite est destinée." ]},
  },
  {
    "term": "extent",
    "label": { "en": [ "Extent" ], "fr": [ "Étendue" ]},
    "uri": "http://purl.org/dc/terms/extent",
    "definition": { "en": [ "The size or duration of the resource." ], "fr": [ "La taille ou la durée de la ressource." ]},
  },
  {
    "term": "format",
    "label": { "en": [ "Format" ], "fr": [ "Format" ]},
    "uri": "http://purl.org/dc/terms/format",
    "definition": { "en": [ "The file format, physical medium, or dimensions of the resource." ], "fr": [ "Le format de fichier, le support physique, ou les dimensions de la ressource." ]},
  },
  {
    "term": "hasFormat",
    "label": { "en": [ "Has Format" ], "fr": [ "A le format" ]},
    "uri": "http://purl.org/dc/terms/hasFormat",
    "definition": { "en": [ "A related resource that is substantially the same as the pre-existing described resource, but in another format." ], "fr": [ "Une ressource associée qui est sensiblement la même que la ressource décrite préexistante, mais dans un autre format." ]},
  },
  {
    "term": "hasPart",
    "label": { "en": [ "Has Part" ], "fr": [ "A une partie" ]},
    "uri": "http://purl.org/dc/terms/hasPart",
    "definition": { "en": [ "A related resource that is included either physically or logically in the described resource." ], "fr": [ "Une ressource associée qui est incluse soit physiquement soit logiquement dans la ressource décrite." ]},
  },
  {
    "term": "hasVersion",
    "label": { "en": [ "Has Version" ], "fr": [ "A une version" ]},
    "uri": "http://purl.org/dc/terms/hasVersion",
    "definition": { "en": [ "A related resource that is a version, edition, or adaptation of the described resource." ], "fr": [ "Une ressource associée qui est une version, une édition, ou une adaptation de la ressource décrite." ]},
  },
  {
    "term": "identifier",
    "label": { "en": [ "Identifier" ], "fr": [ "Identifiant" ]},
    "uri": "http://purl.org/dc/terms/identifier",
    "definition": { "en": [ "An unambiguous reference to the resource within a given context." ], "fr": [ "Une référence non ambiguë à la ressource dans un contexte donné." ]},
  },
  {
    "term": "instructionalMethod",
    "label": { "en": [ "Instructional Method" ], "fr": [ "Méthode pédagogique" ]},
    "uri": "http://purl.org/dc/terms/instructionalMethod",
    "definition": { "en": [ "A process, used to engender knowledge, attitudes and skills, that the described resource is designed to support." ], "fr": [ "Un processus, utilisé pour engendrer des connaissances, des attitudes et des compétences, que la ressource décrite est conçue pour soutenir." ]},
  },
  {
    "term": "isFormatOf",
    "label": { "en": [ "Is Format Of" ], "fr": [ "Est le format de" ]},
    "uri": "http://purl.org/dc/terms/isFormatOf",
    "definition": { "en": [ "A related resource that is substantially the same as the described resource, but in another format." ], "fr": [ "Une ressource associée qui est sensiblement la même que la ressource décrite, mais dans un autre format." ]},
  },
  {
    "term": "isPartOf",
    "label": { "en": [ "Is Part Of" ], "fr": [ "Fait partie de" ]},
    "uri": "http://purl.org/dc/terms/isPartOf",
    "definition": { "en": [ "A related resource in which the described resource is physically or logically included." ], "fr": [ "Une ressource associée dans laquelle la ressource décrite est physiquement ou logiquement incluse." ]},
  },
  {
    "term": "isReferencedBy",
    "label": { "en": [ "Is Referenced By" ], "fr": [ "Est référencé par" ]},
    "uri": "http://purl.org/dc/terms/isReferencedBy",
    "definition": { "en": [ "A related resource that references, cites, or otherwise points to the described resource." ], "fr": [ "Une ressource associée qui référence, cite, ou pointe vers la ressource décrite." ]},
  },
  {
    "term": "isReplacedBy",
    "label": { "en": [ "Is Replaced By" ], "fr": [ "Est remplacé par" ]},
    "uri": "http://purl.org/dc/terms/isReplacedBy",
    "definition": { "en": [ "A related resource that supplants, displaces, or supersedes the described resource." ], "fr": [ "Une ressource associée qui supprime, déplace, ou remplace la ressource décrite." ]},
  },
  {
    "term": "isRequiredBy",
    "label": { "en": [ "Is Required By" ], "fr": [ "Est requis par" ]},
    "uri": "http://purl.org/dc/terms/isRequiredBy",
    "definition": { "en": [ "A related resource that requires the described resource to support its function, delivery, or coherence." ], "fr": [ "Une ressource associée qui nécessite que la ressource décrite soutienne sa fonction, sa livraison, ou sa cohérence." ]},
  },
  {
    "term": "issued",
    "label": { "en": [ "Date Issued" ], "fr": [ "Date de publication" ]},
    "uri": "http://purl.org/dc/terms/issued",
    "definition": { "en": [ "Date of formal issuance (e.g., publication) of the resource." ], "fr": [ "Date d'émission formelle (par exemple, publication) de la ressource." ]},
  },
  {
    "term": "isVersionOf",
    "label": { "en": [ "Is Version Of" ], "fr": [ "Est la version de" ]},
    "uri": "http://purl.org/dc/terms/isVersionOf",
    "definition": { "en": [ "A related resource of which the described resource is a version, edition, or adaptation." ], "fr": [ "Une ressource associée dont la ressource décrite est une version, une édition, ou une adaptation." ]},
  },
  {
    "term": "language",
    "label": { "en": [ "Language" ], "fr": [ "Langue" ]},
    "uri": "http://purl.org/dc/terms/language",
    "definition": { "en": [ "A language of the resource." ], "fr": [ "Une langue de la ressource." ]},
  },
  {
    "term": "license",
    "label": { "en": [ "License" ], "fr": [ "Licence" ]},
    "uri": "http://purl.org/dc/terms/license",
    "definition": { "en": [ "A legal document giving official permission to do something with the resource." ], "fr": [ "Un document légal donnant l'autorisation officielle de faire quelque chose avec la ressource." ]},
  },
  {
    "term": "mediator",
    "label": { "en": [ "Mediator" ], "fr": [ "Médiateur" ]},
    "uri": "http://purl.org/dc/terms/mediator",
    "definition": { "en": [ "An entity that mediates access to the resource and for whom the resource is intended or useful." ], "fr": [ "Une entité qui médiatise l'accès à la ressource et pour qui la ressource est destinée ou utile." ]},
  },
  {
    "term": "medium",
    "label": { "en": [ "Medium" ], "fr": [ "Support" ]},
    "uri": "http://purl.org/dc/terms/medium",
    "definition": { "en": [ "The material or physical carrier of the resource." ], "fr": [ "Le matériau ou le support physique de la ressource." ]},
  },
  {
    "term": "modified",
    "label": { "en": [ "Date Modified" ], "fr": [ "Date de modification" ]},
    "uri": "http://purl.org/dc/terms/modified",
    "definition": { "en": [ "Date on which the resource was changed." ], "fr": [ "Date à laquelle la ressource a été modifiée." ]},
  },
  {
    "term": "provenance",
    "label": { "en": [ "Provenance" ], "fr": [ "Provenance" ]},
    "uri": "http://purl.org/dc/terms/provenance",
    "definition": { "en": [ "A statement of any changes in ownership and custody of the resource since its creation that are significant for its authenticity, integrity, and interpretation." ], "fr": [ "Une déclaration de tout changement de propriété et de garde de la ressource depuis sa création qui sont significatifs pour son authenticité, son intégrité, et son interprétation." ]},
  },
  {
    "term": "publisher",
    "label": { "en": [ "Publisher" ], "fr": [ "Éditeur" ]},
    "uri": "http://purl.org/dc/terms/publisher",
    "definition": { "en": [ "An entity responsible for making the resource available." ], "fr": [ "Une entité responsable de rendre la ressource disponible." ]},
  },
  {
    "term": "references",
    "label": { "en": [ "References" ], "fr": [ "Références" ]},
    "uri": "http://purl.org/dc/terms/references",
    "definition": { "en": [ "A related resource that is referenced, cited, or otherwise pointed to by the described resource." ], "fr": [ "Une ressource associée qui est référencée, citée, ou autrement pointée par la ressource décrite." ]},
  },
  {
    "term": "relation",
    "label": { "en": [ "Relation" ], "fr": [ "Relation" ]},
    "uri": "http://purl.org/dc/terms/relation",
    "definition": { "en": [ "A related resource." ], "fr": [ "Une ressource associée." ]},
  },
  {
    "term": "replaces",
    "label": { "en": [ "Replaces" ], "fr": [ "Remplace" ]},
    "uri": "http://purl.org/dc/terms/replaces",
    "definition": { "en": [ "A related resource that is supplanted, displaced, or superseded by the described resource." ], "fr": [ "Une ressource associée qui est supplantée, déplacée, ou remplacée par la ressource décrite." ]},
  },
  {
    "term": "requires",
    "label": { "en": [ "Requires" ], "fr": [ "Requiert" ]},
    "uri": "http://purl.org/dc/terms/requires",
    "definition": { "en": [ "A related resource that is required by the described resource to support its function, delivery, or coherence." ], "fr": [ "Une ressource associée qui est requise par la ressource décrite pour soutenir sa fonction, sa livraison, ou sa cohérence." ]},
  },
  {
    "term": "rights",
    "label": { "en": [ "Rights" ], "fr": [ "Droits" ]},
    "uri": "http://purl.org/dc/terms/rights",
    "definition": { "en": [ "Information about rights held in and over the resource." ], "fr": [ "Informations sur les droits détenus dans et sur la ressource." ]},
  },
  {
    "term": "rightsHolder",
    "label": { "en": [ "Rights Holder" ], "fr": [ "Détenteur des droits" ]},
    "uri": "http://purl.org/dc/terms/rightsHolder",
    "definition": { "en": [ "A person or organization owning or managing rights over the resource." ], "fr": [ "Une personne ou une organisation détenant ou gérant des droits sur la ressource." ]},
  },
  {
    "term": "source",
    "label": { "en": [ "Source" ], "fr": [ "Source" ]},
    "uri": "http://purl.org/dc/terms/source",
    "definition": { "en": [ "A related resource from which the described resource is derived." ], "fr": [ "Une ressource associée à partir de laquelle la ressource décrite est dérivée." ]},
  },
  {
    "term": "spatial",
    "label": { "en": [ "Spatial Coverage" ], "fr": [ "Couverture spatiale" ]},
    "uri": "http://purl.org/dc/terms/spatial",
    "definition": { "en": [ "Spatial characteristics of the resource." ], "fr": [ "Caractéristiques spatiales de la ressource." ]},
  },
  {
    "term": "subject",
    "label": { "en": [ "Subject" ], "fr": [ "Sujet" ]},
    "uri": "http://purl.org/dc/terms/subject",
    "definition": "The topic of the resource. Typically, the subject will be represented using keywords, key phrases, or classification codes. Recommended best practice is to use a controlled vocabulary."
  },
  {
    "term": "tableOfContents",
    "label": { "en": [ "Table Of Contents" ], "fr": [ "Table des matières" ]},
    "uri": "http://purl.org/dc/terms/tableOfContents",
    "definition": { "en": [ "A list of subunits of the resource." ], "fr": [ "Une liste de sous-unités de la ressource." ]},
  },
  {
    "term": "temporal",
    "label": { "en": [ "Temporal Coverage" ], "fr": [ "Couverture temporelle" ]},
    "uri": "http://purl.org/dc/terms/temporal",
    "definition": { "en": [ "Temporal characteristics of the resource." ], "fr": [ "Caractéristiques temporelles de la ressource." ]},
  },
  {
    "term": "title",
    "label": { "en": [ "Title" ], "fr": [ "Titre" ]},
    "uri": "http://purl.org/dc/terms/title",
    "definition": { "en": [ "A name given to the resource." ], "fr": [ "Un nom donné à la ressource." ]},
  },
  {
    "term": "type",
    "label": { "en": [ "Type" ], "fr": [ "Type" ]},
    "uri": "http://purl.org/dc/terms/type",
    "definition": { "en": [ "The nature or genre of the resource." ], "fr": [ "La nature ou le genre de la ressource." ]},
  },
  {
    "term": "valid",
    "label": { "en": [ "Date Valid" ], "fr": [ "Date de validité" ]},
    "uri": "http://purl.org/dc/terms/valid",
    "definition": { "en": [ "Date (often a range) of validity of a resource." ], "fr": [ "Date (souvent une plage) de validité d'une ressource." ]},
  }
];

export default dcTerms;
