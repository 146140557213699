import React from 'react'
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon} from "../Icons/Icons";

// zustand
import useStore from '../../lib/store'
import { shallow } from 'zustand/shallow'

import styles from './CloseButton.module.scss'

export const CloseButton = ({ rp, withNavigation, leftArrowFunc, rightArrowFunc, className, onClick }) => {
  const [ setSidePanelOpened, setRightPanel ] = useStore(state => [ state.setSidePanelOpened, state.setRightPanel], shallow)

  const close = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (rp) {
      setRightPanel(rp);
    } else setSidePanelOpened('none');
  }

  return (
    <div className={`${styles.closeButton} ${className ? className : ''}`}>
      <div className={styles.button} onClick={() => close()}><CloseIcon /></div>
      {withNavigation && <div className={`${styles.arrowLeft} ${!leftArrowFunc && styles.disabled}`} onClick={leftArrowFunc}><ArrowLeftIcon /></div>}
      {withNavigation && <div className={`${styles.arrowRight} ${!rightArrowFunc && styles.disabled}`} onClick={rightArrowFunc}><ArrowRightIcon /></div>}
    </div>
  )
}

export default CloseButton;
