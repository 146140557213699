import maplibregl from "maplibre-gl";
import _ from "underscore";
import useStore, { useValidatedSearchStore } from "../store";
import { buildHtmlMarker } from "../../lib/map/ArkeoMarkerBuilder";

import config from "../../config.json";

export class ArkeoClustering {
  constructor(map, geojsonResultId, selectedChronologyId) {
    this.map = map;
    this.geojsonResultId = geojsonResultId;
    this.selectedChronologyId = selectedChronologyId;
    this.clusterMarkersLoaded = false;
    this._spiderfy = null;
    this._hoverMarker = null;
    this._events = {
      render: null,
      cluster_click: null,
    };

    let [clusterProperties, chronosClusterProperties] = this.loadClusters(
      this.selectedChronologyId
    );
    this.clusterProperties = clusterProperties;
    this.chronosClusterProperties = chronosClusterProperties;
  }

  toFlatChrono = (chronology, flat = []) => {
    chronology.chronologies.forEach((chrono) => {
      this.toFlatChrono(chrono, flat);
      flat.push(chrono);
    });
    return flat;
  };

  toFlatChronol1 = (chronology, flat = []) => {
    chronology.chronologies.forEach((chrono) => {
      //toFlatChrono(chrono, flat);
      flat.push(chrono);
    });
    return flat;
  };

  _buildArkeoCluster = () => {
    const chronologies = useStore.getState().chronologies;
    const selectedChronology = chronologies.find(
      (c) => c.id === this.selectedChronologyId
    );
    const flatChronology = this.toFlatChronol1(selectedChronology);

    //console.log("buidling for chrono : ", selectedChronologyId);

    const chronos = [];
    flatChronology.forEach((p) => {
      chronos.push({
        color: "#" + p.color,
        mag: ["==", ["get", "periodeid"], p.id],
      });
    });
    chronos.push({
      color: config.chronology.undetermined.color, // undetermined
      mag: ["==", ["get", "periodeid"], config.chronology.undetermined.id],
    });
    chronos.push({
      color: config.chronology.outside.color, // outside of chronology
      mag: ["==", ["get", "periodeid"], config.chronology.outside.id],
    });
    return chronos;
  };

  loadClusters = () => {
    let chronos = this._buildArkeoCluster();
    //console.log("load clusters...", hack, chronos.length);

    const clusterProperties = _.object(
      chronos.map((chrono, i) => `mag${i}`),
      chronos.map((chrono, i) => ["+", ["case", chrono.mag, 1, 0]])
    );

    return [clusterProperties, chronos];
  };

  removeClustersMarkers = () => {
    const map = this.map;
    const source = map.getSource(this.geojsonResultId);
    map.off("render", this._events.render);
    map.off("cluster_click", this._events.cluster_click);
    const markersOnScreen = source.cachedMarkersOnScreen;
    for (const id in markersOnScreen) {
      markersOnScreen[id].remove();
    }
    source.cachedMarkersOnScreen = {};
    source.cachedMarkers = {};
    if (this._hoverMarker) {
      this._hoverMarker.remove();
      this._hoverMarker = null;
    }
    this.clusterMarkersLoaded = false;
  };

  loadClustersMarkers = () => {
    const map = this.map;
    const chronos = this.chronosClusterProperties;
    const geojsonResultId = this.geojsonResultId;
    function updateMarkers() {
      //console.log("update markers...", hack, chronos.length);

      const source = map.getSource(geojsonResultId);
      // objects for caching and keeping track of HTML marker objects (for performance)

      if (!source.cachedMarkers) source.cachedMarkers = {};
      if (!source.cachedMarkersOnScreen) source.cachedMarkersOnScreen = {};
      const markers = source.cachedMarkers;
      const markersOnScreen = source.cachedMarkersOnScreen;

      const newMarkers = {};
      const features = map.querySourceFeatures(geojsonResultId);
      //console.log("arkeosites features len : ", features.length, chronos.length);

      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (const feature of features) {
        const coords = feature.geometry.coordinates;
        const props = feature.properties;
        if (!props.cluster) continue;
        const id = props.cluster_id;

        let marker = markers[id];
        if (!marker) {
          const el = createDonutChart(props);
          marker = markers[id] = new maplibregl.Marker({
            element: el,
          }).setLngLat(coords);
        }
        newMarkers[id] = marker;

        if (!markersOnScreen[id]) marker.addTo(map);
      }

      // for every marker we've added previously, remove those that are no longer visible
      for (const id in markersOnScreen) {
        if (!newMarkers[id]) markersOnScreen[id].remove();
      }
      source.cachedMarkersOnScreen = newMarkers;
    }

    // after the GeoJSON data is loaded, update markers on the screen on every frame
    map.on(
      "render",
      (this._events.render = () => {
        if (!map.isSourceLoaded(geojsonResultId)) return;
        updateMarkers();
      })
    );

    // inspect a cluster on click
    map.on(
      "click",
      "cluster_" + geojsonResultId,
      (this._events.cluster_click = async (e) => {
        const zoom = map.getZoom();
        if (zoom < 12)
          map.easeTo({
            zoom: zoom + 0.5,
            center: e.features[0].geometry.coordinates,
          });
      })
    );

    // code for creating an SVG donut chart from feature properties
    function createDonutChart(props) {
      const offsets = [];
      const counts = _.values(
        _.pick(props, (val, key) => key.startsWith("mag"))
      );

      //if (counts.length != chronos.length) console.log("counts.length != chronos.length", counts.length, chronos.length);
      //else console.log("COUNTS OK");

      let total = 0;
      for (const count of counts) {
        offsets.push(total);
        total += count;
      }
      let fontSize =
        total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
      let r = total >= 1000 ? 48 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
      let r0 = Math.round(r * 0.7);
      let w = r * 2;

      let html =
        '<div style="cursor: pointer;"><svg width="' +
        w +
        '" height="' +
        w +
        '" viewbox="0 0 ' +
        w +
        " " +
        w +
        '" text-anchor="middle" style="font: ' +
        fontSize +
        'px sans-serif; display: block">';

      //for (let i = 0; i < Math.min(counts.length, chronos.length); i++) {
      for (let i = 0; i < counts.length; i++) {
        html += donutSegment(
          offsets[i] / total,
          (offsets[i] + counts[i]) / total,
          r,
          r0,
          chronos[i].color
        );
      }
      html +=
        '<circle cx="' +
        r +
        '" cy="' +
        r +
        '" r="' +
        r0 +
        '" fill="white" /><text dominant-baseline="central" transform="translate(' +
        r +
        ", " +
        r +
        ')">' +
        total.toLocaleString() +
        "</text></svg></div>";

      let el = document.createElement("div");
      el.innerHTML = html;
      return el.firstChild;
    }

    function donutSegment(start, end, r, r0, color) {
      if (end - start === 1) end -= 0.00001;
      const a0 = 2 * Math.PI * (start - 0.25);
      const a1 = 2 * Math.PI * (end - 0.25);
      const x0 = Math.cos(a0),
        y0 = Math.sin(a0);
      const x1 = Math.cos(a1),
        y1 = Math.sin(a1);
      const largeArc = end - start > 0.5 ? 1 : 0;

      return [
        '<path d="M',
        r + r0 * x0,
        r + r0 * y0,
        "L",
        r + r * x0,
        r + r * y0,
        "A",
        r,
        r,
        0,
        largeArc,
        1,
        r + r * x1,
        r + r * y1,
        "L",
        r + r0 * x1,
        r + r0 * y1,
        "A",
        r0,
        r0,
        0,
        largeArc,
        0,
        r + r0 * x0,
        r + r0 * y0,
        '" fill="' + color + '" />',
      ].join(" ");
    }

    updateMarkers();
    this.clusterMarkersLoaded = true;
  };
}
