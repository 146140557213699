export const basemaps = {
  'PositronBlack': {
    url: '/layers/positron-black.json',
    name: "Positron Black",
    icon: '/layers/ico-map-positron-dark.jpg'
  },
  'arkeopen': {
    url: 'mapbox://styles/arkeofi/cl9wf7qdx009z14qtf61wxfwj/draft',
    name: "Mapbox / ArkeOpen",
    icon: '/layers/ico-map-mapbox_arkeopen.jpg',
  },
  /*
  'openmaptiles-osm-bright': {
    url: 'https://openmaptiles.github.io/osm-bright-gl-style/style-cdn.json',
    name: "OpenMapTiles - OSM Bright",
  },
  'maptiler-sat-hybrid': {
    url: "https://api.maptiler.com/maps/hybrid/style.json?key=k0tbxqAqkpwuJyvZEucC",
    name: "MapTiler / Satellite Hybrid",
  },
  'maptiler-topographique': {
    url: "https://api.maptiler.com/maps/topographique/style.json?key=k0tbxqAqkpwuJyvZEucC",
    name: "MapTiler / Topographique",
  },
  'maptiler-outdoor': {
    url: 'https://api.maptiler.com/maps/outdoor/style.json?key=k0tbxqAqkpwuJyvZEucC',
    name: "MapTiler / Outdoor",
  },
  */
  /* (problem)
  'MapTiler / Basic WGS84': {
    url: 'https://api.maptiler.com/maps/basic-4326/style.json?key=k0tbxqAqkpwuJyvZEucC',
    name: "MapTiler / Basic WGS84",
  },
  */
  /*
  'MapTiler / Street': {
    url: 'https://api.maptiler.com/maps/streets/style.json?key=k0tbxqAqkpwuJyvZEucC',
    name: "MapTiler / Street",
  },*/
  'Positron': {
    url: 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json',
    name: "Positron",
    icon: '/layers/ico-map-positron.jpg'
  },
  /*'Etalab': {
    url: 'https://openmaptiles.geo.data.gouv.fr/styles/osm-bright/style.json',
    name: "Etalab",
  },
  */
  /* (problem)
  'IGN plan': {
    url: 'https://wxs.ign.fr/essentiels/static/vectorTiles/styles/PLAN.IGN/standard.json',
    name: "IGN plan",
  },
  */
  /*
  'Institut Cartogràfic i Geològic de Catalunya': {
    url: 'https://geoserveis.icgc.cat/contextmaps/icgc.json',
    name: "Institut Cartogràfic i Geològic de Catalunya",
  },
  */
  /*
  'StamenDesign': {
    url: '/layers/StamenDesign.json',
    name: "Stamen Design",
  },
  */
  'OpenTopoMap': {
    url: '/layers/OpenTopoMap.json',
    name: "OpenTopoMap",
    icon: '/layers/ico-map-opentopomap.jpg',
  },
  'OpenStreetMap': {
    url: '/layers/OpenStreetMap.json',
    name: "OpenStreetMap",
    icon: '/layers/ico-map-openstreetmap.jpg',
  },
  'ESRIMapTiles': {
    url: '/layers/ESRIMapTiles.json',
    name: "ESRI Map Tiles",
    icon: '/layers/ico-map-esri.jpg',
  },
  /* (Seem not working anymore in 2024)
  'NASAGibsSat': {
    url: '/layers/NASAGibsSat.json',
    name: "NASA - GIBS - BlueMarble ShadedRelief Bathymetry",
    icon: '/layers/ico-map-nasa.jpg',
  },
  */
  /*
  'OSMNico': {
    url: 'https://tiles.home.keblo.net/styles/basic-preview/style.json',
    name: "OpenStreetMap chez Nico",
    icon: '/layers/ico-map-esri.jpg',
  },
  'DarkNico': {
    url: '/layers/dark_nico.json',
    name: "Dark Nico",
    icon: '/layers/ico-map-esri.jpg',
  },
  */
  'MapTilerBasicV2Dark': {
    url: '/layers/MapTilesDark.json',
    name: "MapTiler Basic V2 Dark",
    icon: '/layers/ico-map-maptilesdarkv2.jpg',
  },
}
