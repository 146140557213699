import React, { useState, useEffect } from 'react'
//import PropTypes from 'prop-types'

// i18n
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { GetCitations } from '../../lib/queries/legend'

import useStore, { useValidatedSearchStore } from '../../lib/store'
import {shallow} from 'zustand/shallow'
import { getInLang, getInLangWKey } from '../../lib/translate';

import Markdown from '../Markdown/Markdown'
import Loader from '../Loader/Loader';

const LegendCitations = props => {
  const { features } = props;
  const { t, i18n } = useTranslation();

  const site_ids = features.map(f => f.properties.site_id);

  const [ chronologies, shapefiles, mapObject, mapUpdated ] = useStore(state => [
    state.chronologies, state.shapefiles, state.mapObject, state.mapUpdated,
  ], shallow)

  const [ selectedShapefiles, selectedChronologyId ] = useValidatedSearchStore(state => [
    state.selectedShapefiles, state.selectedChronologyId,
  ], shallow)

  const citations = useQuery(GetCitations, {
    variables: {
      site_ids: site_ids,
    }
  }) // others = { loading, error, data }

  const [ mapAttributions, setMapAttributions ] = useState([]);

  useEffect(() => {
    let a = [];
    if (mapObject) {
      let sources = mapObject.getStyle().sources;
      for (let k in sources) {
        if (k.startsWith("arkeo")) continue;
        if (k === "composite") continue;
        let source = mapObject.getSource(k);
        a.push(source.attribution)
      }

    }
    setMapAttributions(a);
  }, [ mapObject, setMapAttributions, mapUpdated ]);


  if (citations.loading) return <Loader />
  if (citations.error) return <div className="Error">{citations.error.message}</div>

  const selectedChronology = chronologies.find(c => c.id === selectedChronologyId);

  return (
    <div className="LegendCitations">

      <h2>{t`components.LegendCitations.when.title`}</h2>
      <h3>{t`components.LegendCitations.when.chronology.header`}</h3>
      <ul>
        <li>
          <Markdown>{selectedChronology.chronology_root.credits}</Markdown>
        </li>
      </ul>

      {citations.data.datasets.length > 0 && (
        <>
          <h2>{t`components.LegendCitations.what.title`}</h2>
          <h3>{t`components.LegendCitations.what.datasets.header`}</h3>
          <ul>
            {citations.data.datasets.map(dataset => (
              <li key={dataset.id}>
                <Markdown>{getInLang(dataset.database_trs, i18n.language).bibliography}</Markdown>
              </li>
            ))}
          </ul>
        </>
      )}

      {mapAttributions.length > 0 && (
        <>
          <h2>{t`components.LegendCitations.where.title`}</h2>
          <h3>{t`components.LegendCitations.where.basemaps.header`}</h3>
          <ul>
            <li>
              {mapAttributions.map((a, i) => (
                <p key={i} dangerouslySetInnerHTML={{__html: a}} />
              ))}
            </li>
          </ul>
        </>
      )}

      {selectedShapefiles.length > 0 && (
        <>
          <h3>{t`components.LegendCitations.where.shps.header`}</h3>
          <ul>
            {selectedShapefiles.map(shapefile_id => {
              const shapefile = shapefiles.find(shapefile => shapefile.id === shapefile_id)
              if (!shapefile) return <div key={shapefile.id}>unknwon shapefile id</div>
              return (
                <li key={shapefile.id}>
                  <Markdown>{getInLangWKey(shapefile.shapefile_trs, 'citation', i18n.language, {emptyIsUndefined: true})}</Markdown>
                </li>
              )
            })}
          </ul>
        </>
      )}

    </div>
  )
}

LegendCitations.propTypes = {
}

export default LegendCitations
