// react
import React from "react";
//import PropTypes from 'prop-types';
//

// bootstrap
import Form from "react-bootstrap/Form";
//

// i18n
import { useTranslation } from "react-i18next";
//import { getInLang } from '../../lib/translate';
//

// Components
import CharacMenu from "./CharacMenu";
//

// zustand
import useStore from "../../lib/store";
import { useSearchStore } from "../../lib/store";
import { shallow } from "zustand/shallow";
//

import FlatSelect from "../FlatSelect/FlatSelect";

import "./FormSearchWhat.scss";

/**
 * The 'what' Tab in the Search Form (left side bar)
 * @param {*} props
 * @returns The 'What' Component
 */
function FormSearchWhat() {
  const { t, i18n } = useTranslation();
  const characs = useStore((state) => state.characs);

  const [
    illustrated,
    setIllustrated,
    exceptional,
    setExceptional,
    knowledgeTypes,
    updateKnowledgeTypes,
    occupations,
    updateOccupations,
    centroid,
    setCentroid,
  ] = useSearchStore(
    (state) => [
      state.illustrated,
      state.setIllustrated,
      state.exceptional,
      state.setExceptional,
      state.knowledgeTypes,
      state.updateKnowledgeTypes,
      state.occupations,
      state.updateOccupations,
      state.centroid,
      state.setCentroid,
    ],
    shallow
  );

  return (
    <div className="FormSearchWhat">
      <div>
        <Form.Label>{t("components.FormSearchWhat.label")}</Form.Label>

        {characs.map((charac) => (
          <CharacMenu
            key={charac.id}
            characs={characs}
            charac={charac}
            lang={i18n.language}
            path={[charac.id]}
          />
        ))}
      </div>

      <div>
        <Form.Label>{t("components.FormSearchWhat.only-sites-objects.label")}</Form.Label>

        <div className="switchsContainer">
          <Form.Check
            id="switch_exceptional"
            label={t(
              "components.FormSearchWhat.exceptional.label"
            )}
            type="switch"
            checked={exceptional === true}
            onChange={() => {
                setExceptional(exceptional ? null : true)
             }
            }
          />

          <Form.Check
            id="switch_illustrated"
            label={t(
              "components.FormSearchWhat.illustrated.label"
            )}
            type="switch"
            checked={illustrated === true}
            onChange={() => {
              setIllustrated(illustrated ? null : true)
            }}
          />

          <Form.Check
            id="switch_precision"
            label={t(
              "components.FormSearchWhat.precision.label"
            )}
            type="switch"
            checked={centroid === true}
            onChange={() => {
              setCentroid(centroid ? null : true)
            }
            }
          />

        </div>
      </div>

      <div>
        <Form.Label>{t("components.FormSearchWhat.refine.label")}</Form.Label>

        <FlatSelect
          unselectable={true}
          label={t("components.FormSearchWhat.occupation.label")}
          partiallySelected={occupations.length > 0}
        >
          <FlatSelect
            leaf={true}
            label={t("arkeo.occupation.single")}
            selected={occupations.includes("single")}
            onSelect={(sel) => updateOccupations(sel, "single")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.occupation.continuous")}
            selected={occupations.includes("continuous")}
            onSelect={(sel) => updateOccupations(sel, "continuous")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.occupation.multiple")}
            selected={occupations.includes("multiple")}
            onSelect={(sel) => updateOccupations(sel, "multiple")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.occupation.not_documented")}
            selected={occupations.includes("not_documented")}
            onSelect={(sel) => updateOccupations(sel, "not_documented")}
          />
        </FlatSelect>

        <FlatSelect
          unselectable={true}
          label={t("components.FormSearchWhat.knowledge_type.label")}
          partiallySelected={knowledgeTypes.length > 0}
        >
          <FlatSelect
            leaf={true}
            label={t("arkeo.knowledge_type.not_documented")}
            selected={knowledgeTypes.includes("not_documented")}
            onSelect={(sel) => updateKnowledgeTypes(sel, "not_documented")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.knowledge_type.literature")}
            selected={knowledgeTypes.includes("literature")}
            onSelect={(sel) => updateKnowledgeTypes(sel, "literature")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.knowledge_type.prospected_aerial")}
            selected={knowledgeTypes.includes("prospected_aerial")}
            onSelect={(sel) => updateKnowledgeTypes(sel, "prospected_aerial")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.knowledge_type.prospected_pedestrian")}
            selected={knowledgeTypes.includes("prospected_pedestrian")}
            onSelect={(sel) =>
              updateKnowledgeTypes(sel, "prospected_pedestrian")
            }
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.knowledge_type.surveyed")}
            selected={knowledgeTypes.includes("surveyed")}
            onSelect={(sel) => updateKnowledgeTypes(sel, "surveyed")}
          />
          <FlatSelect
            leaf={true}
            label={t("arkeo.knowledge_type.dig")}
            selected={knowledgeTypes.includes("dig")}
            onSelect={(sel) => updateKnowledgeTypes(sel, "dig")}
          />
        </FlatSelect>
      </div>
    </div>
  );
}

export default FormSearchWhat;
