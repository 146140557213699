import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

import "./MiniMap.scss";

import { basemaps } from "../../lib/map/basemaps";
import { buildMarker } from "../../lib/map/ArkeoMarkerBuilder";
import { drawGeojsonPolygon } from "../../lib/map/ArkeoShapefile";

const toBBox = require("geojson-bounding-box");

/**
 * MiniMap component displays a mini map using Mapbox GL JS library.
 *
 * @component
 * @example
 * // Usage
 * <MiniMap geojson={geojsonData} minimaptype="site" />
 *
 * @param {Object} props - The component props.
 * @param {Object} props.geojson - The GeoJSON data to be displayed on the map.
 * @param {string} props.minimaptype - The type of mini map to be displayed. Possible values are "site" and "polygon".
 *
 * @returns {JSX.Element} The MiniMap component.
 */
function MiniMap(props) {
  const mapContainer = useRef(null);
  const rmap = useRef(null);
  const { geojson, minimaptype, showbuttons = false } = props;

  //console.log("geojson: ", geojson);

  const initMap = () => {
    const map = (rmap.current = new maplibregl.Map({
      container: mapContainer.current,
      style: minimaptype === "site" ? basemaps["ESRIMapTiles"].url : basemaps["Positron"].url,
      attributionControl: false,
      renderWorldCopies: false,
      //transformRequest: transformRequest,
    }));

    map.addControl(
      new maplibregl.AttributionControl({
        compact: false,
      }),
      "bottom-right"
    );

    /**
     * Add Scale Bar (echelle)
     */
    let scale1 = new maplibregl.ScaleControl({
      maxWidth: 100,
      unit: "imperial",
    });
    map.addControl(scale1, "bottom-right");
    //scale1.setUnit('metric');

    /**
     * Add Scale Bar (echelle)
     */
    let scale2 = new maplibregl.ScaleControl({
      maxWidth: 100,
      unit: "metric",
    });
    map.addControl(scale2, "bottom-right");
    //scale2.setUnit('metric');

    //map.dragRotate.disable();
    //map.touchZoomRotate.disableRotation();
    if (showbuttons) {
      map.addControl(
        new maplibregl.NavigationControl({
          showCompass: true,
        }),
        "bottom-right"
      );
    }

    const loadImages = async () => {
      const iconMapDisc = await map.loadImage("/icons-maps/map-disc-36.png");
      map.addImage("arkeo-disc", iconMapDisc.data, { sdf: true });

      //const iconMapDiscE = await map.loadImage('/icons-maps/map-disc-exceptional2-36.png');
      //map.addImage('arkeo-disc-exceptional', iconMapDiscE.data, { sdf: true });

      map.on("styleimagemissing", async (e) => {
        await buildMarker(map, e.id);
      });
    };

    const onLoadForPolygon = async () => {
      if (geojson) {
        const bbox = toBBox(geojson);
        //console.log("bbox: ", bbox);
        map.fitBounds(bbox, {
          padding: { top: 50, bottom: 50, left: 50, right: 50 },
        });

        drawGeojsonPolygon(map, "thegeojson", geojson);
      }
    };

    const onLoadForSite = async () => {
      await loadImages();

      const bbox = toBBox(geojson);
      map.flyTo({
        center: [bbox[0], bbox[1]],
        zoom: 15,
        speed: 5,
      });

      map.addSource("arkeosites", {
        type: "geojson",
        data: geojson,
        attribution: '<a href="?p=right&rp=project§credits">Made by FiNiCh 2024</a>',
      });

      map.addLayer({
        id: "arkeosite_circle",
        type: "symbol",
        source: "arkeosites",
        layout: {
          "icon-image": [
            "concat",
            "arkeo_",
            ["get", "exceptional"],
            "_",
            ["get", "color"],
            "_",
            ["get", "knowledge"],
            "_",
            ["get", "centroid"],
          ],
          "icon-anchor": ["match", ["get", "centroid"], 1, "center", "bottom"],
          //'icon-size': 0.375,
          "icon-allow-overlap": true, // recommended
          //'text-field': "M",
        },
      });
    };

    map.on("load", async (e) => {
      if (minimaptype === "polygon") {
        onLoadForPolygon();
      } else if (minimaptype === "site") {
        onLoadForSite();
      }
    });
  };

  /**
   * This useEffect will create the map when we get the map data.
   */
  useEffect(() => {
    if (rmap.current) {
      rmap.current.remove();
      rmap.current = undefined;
    }
    initMap();
  }, [geojson]);

  /**
   * This useEffect will destroy the map instance when the component is unmounted.
   */
  useEffect(() => {
    return () => {
      if (rmap.current) {
        rmap.current.remove();
        rmap.current = undefined;
      }
    };
  }, []);

  return (
    <div className="minimap-container-container">
      <div ref={mapContainer} className="minimap-container" />
    </div>
  );
}

MiniMap.propTypes = {
  geojson: PropTypes.object.isRequired,
  minimaptype: PropTypes.oneOf(["site", "polygon"]).isRequired,
  showbuttons: PropTypes.bool,
};

export default MiniMap;
