import React from "react";

import styles from "./SlidingLabel.module.scss";

const SlidingLabel = ({ label, children, hide = false }) => {
  return (
    <div className={styles.container}>
      {!hide && <div className={styles.slidingLabel}>{label}</div>}
      {children}
    </div>
  );
};

export default SlidingLabel;
