import { createRoot } from 'react-dom/client';

class MyReactControl {
    constructor(options) {
      this._options = options;
    }
    onAdd(map) {
      this._map = map;
      this._container = document.createElement('div');
      this._container.className='maplibregl-ctrl maplibregl-ctrl-group mapboxgl-ctrl mapboxgl-ctrl-group';
      this._root = createRoot(this._container);
      this._root.render(this._options.element)
      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      setTimeout(() => { // setTimeout avoid race conditions when unmounting composent while redrawing
        this._root.unmount();
        this._map = undefined;
      }, 0);
    }
}

export default MyReactControl;
