import React, { useRef, useEffect, useState } from "react";
import config from "../../config.json";
import { useTranslation } from "react-i18next";
import _ from "underscore";

import { searchFeature, getSiteFromMousePoint } from "../../lib/map/tools";

import "./MainMap.scss";
import "./../../styles/common.scss";
import Loader from "../Loader/Loader";

import maplibregl from "maplibre-gl";

import transformRequest from "../../lib/map/transformRequest";

import { basemaps } from "../../lib/map/basemaps";
import { ArkeoLayer } from "../../lib/map/ArkeoLayer";

// map controls
import MyReactControl from "./MyReactControl";
import MapButtonLayers from "./MapButtonLayers";
//import MapButtonExportMap from "./MapButtonExportMap";
import MapButtonStaticLegend from "../StaticLegend/StaticLegend";
import MapButtonGroupUngroup from "./MapButtonGroupUngroup";

// zustand
import useStore, { useValidatedSearchStore } from "../../lib/store";
import { shallow } from "zustand/shallow";

// graphql
import { useQuery } from "@apollo/client";
import { GET_SITES_QUERY, WhereSiteSearch } from "../../lib/queries/sites";
import { GetShapefilesByIds } from "../../lib/queries/shapefile";

import { UNDETERMINED_LEFT } from "../../lib/year";
import { getChronologyByDatel1, getChronologyByDatel4 } from "../../lib/chronologiesSelection";

import { buildMarker, buildHtmlMarker } from "../../lib/map/ArkeoMarkerBuilder";
import { loadShapefiles } from "../../lib/map/ArkeoShapefile";

/**
 * MainMap is the Main Map of the application
 * @param {} props
 */

const MainMap = (props) => {
  const [siteIdHover, setSiteIdHover] = useState(0);
  const [gqlQuery, setGqlQuery] = useState(GET_SITES_QUERY);
  const pois = useQuery(gqlQuery, { fetchPolicy: "no-cache" }); // pois = { loading, error, data }
  const mapContainer = useRef(null);
  const rmap = useRef(null);

  const [loadedCount, setLoadedCount] = useState(0);
  useEffect(() => {
    setLoadedCount((loadedCount) => loadedCount + 1);
  }, []);

  // Main Application Store parameters
  const [
    basemap,
    sidePanelOpened,
    setSidePanelOpened,
    rightPanel,
    setRightPanel,
    lng,
    setLng,
    lat,
    setLat,
    zoom,
    setZoom,
    mapObject,
    setMapObject,
    mapUpdated,
    setMapUpdated,
    allMapDrawed,
    setAllMapDrawed,
    chronologies,
    geoLocateTrigger,
    flyTo,
    setFlyTo,
    lastSiteIdSelected,
    ungrouped,
    setFirstMapDrawed,
  ] = useStore(
    (state) => [
      state.basemap,
      state.sidePanelOpened,
      state.setSidePanelOpened,
      state.rightPanel,
      state.setRightPanel,
      state.lng,
      state.setLng,
      state.lat,
      state.setLat,
      state.zoom,
      state.setZoom,
      state.mapObject,
      state.setMapObject,
      state.mapUpdated,
      state.setMapUpdated,
      state.allMapDrawed,
      state.setAllMapDrawed,
      state.chronologies,
      state.geoLocateTrigger,
      state.flyTo,
      state.setFlyTo,
      state.lastSiteIdSelected,
      state.ungrouped,
      state.setFirstMapDrawed,
    ],
    shallow
  );

  // Validated Search Store parameters
  const [
    geojsonResult,
    setGeojsonResult,
    selectedChronologyId,
    characSelectionCompacted,
    chronologySelectionCompacted,
    selectedShapefiles,
    knowledgeTypes, // if it change, update the map
    occupations, // if it change, update the map
    datasetTypes, // if it change, update the map
    scaleResolutions, // if it change, update the map
    exceptional, // if it change, update the map
    illustrated, // if it change, update the map
    centroid, // if it change, update the map
    editors, // if it change, update the map
    authors, // if it change, update the map
    databases, // if it change, update the map
    textual, // if it change, update the map
    textualOn, // if it change, update the map
    chronologyStartDate, // if it change, update the map
    chronologyEndDate, // if it change, update the map
    chronologyFindIncludeUndetermined, // if it change, update the map
    chronologyFindOnlyInside, // if it change, update the map
  ] = useValidatedSearchStore((state) => [
    state.geojsonResult,
    state.setGeojsonResult,
    state.selectedChronologyId,
    state.characSelectionCompacted,
    state.chronologySelectionCompacted,
    state.selectedShapefiles,
    state.knowledgeTypes,
    state.occupations,
    state.datasetTypes,
    state.scaleResolutions,
    state.exceptional,
    state.illustrated,
    state.centroid,
    state.editors,
    state.authors,
    state.databases,
    state.textual,
    state.textualOn,
    state.chronologyStartDate,
    state.chronologyEndDate,
    state.chronologyFindIncludeUndetermined,
    state.chronologyFindOnlyInside,
  ]);

  const shapefiles = useQuery(GetShapefilesByIds, {
    variables: { ids: selectedShapefiles },
  });

  const { i18n, t } = useTranslation();

  /**
   * Create a geojsonresult object from the graphql query result
   */
  useEffect(() => {
    if (pois.loading) {
      /*console.log("no geojson : loading...")*/ return;
    }
    if (pois.error) {
      console.log("no geojson : error ", pois.error);
      return;
    }
    if (!pois.data) {
      console.log("no geojson : no data ", pois.data);
      return;
    }

    //unloadCluster(map);

    const selectedChronology = chronologies.find((c) => c.id === selectedChronologyId);
    const searchId = _.uniqueId();

    const reduceRangeCharacs = (accumulator, siteRanges) => {
      //console.log("siteRanges", siteRanges);
      let r = siteRanges.site_range__characs.reduce((accumulator2, siteRangeCharac) => {
        const t = siteRangeCharac.knowledge_type; // enum:"not_documented,literature,prospected_aerial,prospected_pedestrian,surveyed,dig"
        let r2 = 0;
        switch (t) {
          case "dig":
            r2 = 5;
            break;
          case "surveyed":
            r2 = 4;
            break;
          case "prospected_pedestrian":
            r2 = 3;
            break;
          case "prospected_aerial":
            r2 = 2;
            break;
          case "literature":
            r2 = 1;
            break;
          //case 'not_documented': r2=0; break;
        }
        return r2 > accumulator2 ? r2 : accumulator2;
      }, accumulator);
      return r > accumulator ? r : accumulator;
    };

    const geojson_result = {
      id: `arkeosites-${searchId}`,
      type: "FeatureCollection",
      features:
        pois.data && pois.data.ako_site
          ? pois.data.ako_site.map((site) => {
              const p1 = getChronologyByDatel1(selectedChronology.chronologies, site.end_date1, site.end_date2);
              const p2 = getChronologyByDatel4(selectedChronology.chronologies, site.end_date1, site.end_date2);
              return {
                type: "Feature",
                properties: {
                  name: site.name,
                  //"mag": site.end_date1,
                  //"dates": [ site.start_date1, site.start_date2, site.end_date1, site.end_date2],
                  start_date1: site.start_date1,
                  start_date2: site.start_date2,
                  end_date1: site.end_date1,
                  end_date2: site.end_date2,
                  centroid: site.centroid ? 1 : 0,
                  periodeid: p1
                    ? p1.id
                    : site.end_date1 === UNDETERMINED_LEFT
                    ? config.chronology.undetermined.id
                    : config.chronology.outside.id,
                  periodeid2: p2
                    ? p2.id
                    : site.end_date1 === UNDETERMINED_LEFT
                    ? config.chronology.undetermined.id
                    : config.chronology.outside.id,
                  color: p2
                    ? "#" + p2.color
                    : site.end_date1 === UNDETERMINED_LEFT
                    ? config.chronology.undetermined.color
                    : config.chronology.outside.color,
                  exceptional: site.exceptional_count.aggregate.count > 0 ? "yes" : "no",
                  site_id: site.id,
                  knowledge: site.site_ranges.reduce(reduceRangeCharacs, 0),
                },
                geometry: {
                  type: "Point",
                  coordinates: site.geom.coordinates,
                },
              };
            })
          : [],
    };
    //console.log("setGeojsonResult......................")
    setGeojsonResult(geojson_result);
  }, [pois.data, pois.loading, pois.error, setGeojsonResult]);

  /**
   * listen to validated search parameters, so we query again the server
   */
  useEffect(() => {
    //console.log("NEW QUERY")
    const whereSiteSearch = new WhereSiteSearch();
    setGqlQuery(whereSiteSearch.getGqlQuery());
  }, [
    selectedChronologyId,
    characSelectionCompacted,
    chronologySelectionCompacted,
    knowledgeTypes,
    occupations,
    datasetTypes,
    scaleResolutions,
    exceptional,
    illustrated,
    centroid,
    editors,
    authors,
    databases,
    textual,
    textualOn,
    chronologyStartDate,
    chronologyEndDate,
    chronologyFindIncludeUndetermined,
    chronologyFindOnlyInside,
    selectedChronologyId,
  ]);

  /***
   * Update the arkeolayer when the geojsonResult is updated
   */
  useEffect(() => {
    const map = mapObject;
    if (!map) return;

    if (map._arkeoLayer) {
      console.log("remove from map : ", map._arkeoLayer.geojsonResultId);
      map._arkeoLayer.removeFromMap(); // remove the old one (default for arkeopen)
      map._arkeoLayer = null;
    }

    if (!geojsonResult) return;

    map._arkeoLayer = new ArkeoLayer(map, geojsonResult.id, selectedChronologyId, geojsonResult, ungrouped);
    map._arkeoLayer.addToMap();

    if (!shapefiles.loading && !shapefiles.error && shapefiles.data.ako_shapefile)
      loadShapefiles(map, shapefiles.data.ako_shapefile, i18n.language);
  }, [mapObject, selectedChronologyId, geojsonResult, shapefiles, ungrouped]);

  /**
   * Update the basemap
   */
  useEffect(() => {
    const map = mapObject;
    if (!map) return;

    map.setStyle(basemaps[basemap].url, {
      transformStyle: (previousStyle, nextStyle) => ({
        ...nextStyle,
        sources: {
          ...nextStyle.sources,
          ...Object.fromEntries(
            Object.entries(previousStyle.sources).filter(
              ([key, value]) =>
                key.startsWith("arkeosites-") || key.startsWith("cluster_arkeosites-") || key.startsWith("arkeoshp-")
            )
          ),
        },
        layers: [
          ...nextStyle.layers,
          ...previousStyle.layers.filter(
            (layer) =>
              layer.id.startsWith("discs_arkeosites-") ||
              layer.id.startsWith("cluster_arkeosites-") ||
              layer.id.startsWith("arkeoshp-")
          ),
        ],
      }),
    });
  }, [mapObject, basemap, ungrouped]);

  /**
   * Update the state when the map is moved (by the user)
   * Usefull to update the url parameters
   */
  const onMove = (evt) => {
    const map = evt.target;
    const center = map.getCenter();

    const [newLng, newLat, newZoom] = [
      parseFloat(center.lng.toFixed(5)),
      parseFloat(center.lat.toFixed(5)),
      parseFloat(map.getZoom().toFixed(2)),
    ];
    //console.log("center: ", center);
    if (newLng !== lng) setLng(newLng);
    if (newLat !== lat) setLat(newLat);
    if (newZoom !== zoom) setZoom(newZoom);
  };

  /**
   * UseEffect on lng, lat, zoom to update the map
   * when the user change the url parameters
   */
  useEffect(() => {
    if (!mapObject) return;

    const sidePanelLeftWidth = document.querySelector(".SidePanel.left").getBoundingClientRect().width;
    const sidePanelRightWidth = document.querySelector(".SidePanel.right").getBoundingClientRect().width;

    const flyToOpts = {
      padding: {
        top: 0,
        bottom: 0,
        left: sidePanelOpened === "left" ? sidePanelLeftWidth : 0,
        right: sidePanelOpened === "right" ? sidePanelRightWidth : 0,
      },
      speed: loadedCount > 1 ? 999999 : 1,
    };
    if (flyTo && sidePanelOpened === "right") {
      flyToOpts.center = flyTo;
    } else {
      flyToOpts.center = [lng, lat];
      flyToOpts.zoom = zoom;
    }

    if (flyTo) setFlyTo(null);

    mapObject.flyTo(flyToOpts);
  }, [mapObject, lng, lat, zoom, sidePanelOpened]); // yes, flyTo is not in the dependencies, because we want to fly only on sidePanelOpened change

  /**
   * UseEffect on geoLocateTrigger to trigger the geolocation
   */
  useEffect(() => {
    if (!mapObject) return;
    const map = mapObject;

    if (map._geolocationcontrol) {
      if (geoLocateTrigger) {
        map._geolocationcontrol.trigger();
      }
    }
  }, [mapObject, geoLocateTrigger]);

  useEffect(() => {
    if (!mapObject) return;
    if (allMapDrawed) return;
    setAllMapDrawed(true);
  }, [mapObject, mapUpdated, allMapDrawed, setAllMapDrawed]);

  /**
   * Update the marker on the feature if hovered or selected
   */
  useEffect(() => {
    if (!geojsonResult) return;
    const map = mapObject;

    /**
     * Set the marker on the hovered feature
     * @param {Object} cluster_feature The cluster feature
     * @param {Object} site_feature The site feature
     * @returns {void}
     **/
    const setMarkerOnHoveredFeature = (
      cluster_feature,
      site_feature,
      mapSavedMarkerName = "_arkeo_hoverMarker",
      selected = 1
    ) => {
      const props = site_feature.properties;
      const offset = site_feature.layer.layout["icon-offset"] ? site_feature.layer.layout["icon-offset"] : [0, 0];
      const el = buildHtmlMarker(offset, props.exceptional, props.color, props.knowledge, props.centroid, selected);

      const coordinates = cluster_feature ? cluster_feature.geometry.coordinates : site_feature.geometry.coordinates;

      /**
       * add the new marker on the hovered feature
       */
      if (map[mapSavedMarkerName]) map[mapSavedMarkerName].remove(); // first, remove the old one if it exists
      map[mapSavedMarkerName] = new maplibregl.Marker({
        element: el,
      })
        .setLngLat(coordinates)
        .addTo(map);

      /**
       * add the popup
       */
      if (mapSavedMarkerName === "_arkeo_hoverMarker") {
        if (map._arkeo_sitePopup) map._arkeo_sitePopup.remove(); // first, remove the old one if it exists
        const popup = (map._arkeo_sitePopup = new maplibregl.Popup({
          closeButton: false,
          closeOnClick: false,
        }));
        popup
          .setLngLat(coordinates)
          .setHTML(`<div>${props.name}</div>`)
          .setOffset([offset[0], offset[1] - (props.centroid ? 20 : 50)])
          .addTo(map);
      }
    };

    const asyncJob = async (id_of_site, selected, mapSavedMarkerName) => {
      let featureFound = null;
      featureFound = await searchFeature(map, id_of_site);

      /**
       * remove the marker on the hovered feature
       */
      if (map[mapSavedMarkerName]) {
        map[mapSavedMarkerName].remove();
        map[mapSavedMarkerName] = null;
      }

      /**
       * remove the popup
       */
      if (mapSavedMarkerName === "_arkeo_hoverMarker" && map._arkeo_sitePopup) {
        map._arkeo_sitePopup.remove();
        map._arkeo_sitePopup = null;
      }

      if (featureFound) setMarkerOnHoveredFeature(null, featureFound, mapSavedMarkerName, selected);
    };

    asyncJob(siteIdHover, 2, "_arkeo_hoverMarker").catch((e) => {
      console.error(e);
    });
    if (rightPanel.type === "site" && rightPanel.id) {
      asyncJob(parseInt(rightPanel.id), 1, "_arkeo_selectedMarker").catch((e) => {
        console.error(e);
      });
    }
  }, [mapObject, rightPanel, geojsonResult, siteIdHover, lastSiteIdSelected, mapUpdated]);

  /**
   * Override map controls labels and styles.
   */
  useEffect(() => {
    if (!mapObject) return;
    const buttonTitles = [
      {
        className: ".maplibregl-ctrl-geolocate",
        label: t("components.MainMap.MapButtonGeolocate.label"),
      },
      {
        className: ".maplibregl-ctrl-zoom-in",
        label: t("components.MainMap.MapButtonZoomIn.label"),
      },
      {
        className: ".maplibregl-ctrl-zoom-out",
        label: t("components.MainMap.MapButtonZoomOut.label"),
      },
      {
        className: ".maplibregl-ctrl-compass",
        label: t("components.MainMap.MapButtonCompass.label"),
      },
    ];
    setTimeout(() => {
      buttonTitles.forEach(({ className, label }) => {
        const el = mapObject._controlContainer.querySelector(className);
        if (el) {
          el.setAttribute("title", "");
          el.setAttribute("data-title", label);
          el.classList.add("slidingTitle");
        }
      });
    }, 0);
  }, [i18n.language, mapObject, t]);

  /**
   * This useEffect will create the map when we get the map data.
   */
  useEffect(() => {
    const map = (rmap.current = new maplibregl.Map({
      container: mapContainer.current,
      attributionControl: false,
      renderWorldCopies: false,
      transformRequest: transformRequest,
      preserveDrawingBuffer: true,
      //center: [lng, lat],
      //zoom: zoom,
      style: {
        version: 8,
        layers: [],
        sources: {},
      },
    }));

    map.on("load", firstInit);

    // this is the destroying function
    /*
    return () => {
      if (rmap.current) {
        rmap.current = undefined;
        if (map._arkeoLayer) map._arkeoLayer.removeFromMap();
        map.remove();
      }
    };
    */
  }, []);

  /**
   * called by onLoad from maplibre
   * load icons, add controls, ...
   * @param {Object} e The maplibre event object
   */
  const firstInit = async (e) => {
    const map = e.target;

    const iconMapDisc = await map.loadImage("/icons-maps/map-disc-36.png");
    map.addImage("arkeo-disc", iconMapDisc.data, { sdf: true });

    //const iconMapDiscE = await map.loadImage('/icons-maps/map-disc-exceptional2-36.png');
    //map.addImage('arkeo-disc-exceptional', iconMapDiscE.data, { sdf: true });

    map.on("styleimagemissing", async (e) => {
      await buildMarker(map, e.id);
    });

    map.on("mousemove", (e) => {
      const site = getSiteFromMousePoint(map, e.point);
      if (site) {
        map.getCanvas().style.cursor = "pointer";
        setSiteIdHover(site.properties.site_id);
        return;
      } else {
        setSiteIdHover(0);
        map.getCanvas().style.cursor = "";
      }
    });

    const checkFirstDraw = () => {
      const layer = map.getLayer(`discs_${map._arkeoLayer?.id}`);
      if (layer && map.loaded()) {
        setFirstMapDrawed(true);
        map.off("render", checkFirstDraw);
      }
    };
    map.on("render", checkFirstDraw);
    /**
     * Add Scale Bar (echelle)
     */
    let scale1 = new maplibregl.ScaleControl({
      maxWidth: 100,
      unit: "imperial",
    });
    map.addControl(scale1, "bottom-right");
    //scale1.setUnit('metric');

    /**
     * Add Scale Bar (echelle)
     */
    let scale2 = new maplibregl.ScaleControl({
      maxWidth: 100,
      unit: "metric",
    });
    map.addControl(scale2, "bottom-right");
    //scale2.setUnit('metric');

    /**
     * add Buttons
     */
    map.addControl(
      new MyReactControl({
        element: <MapButtonGroupUngroup />,
      }),
      "bottom-right"
    );
    /*
    map.addControl(
      new MyReactControl({
        element: <MapButtonExportMap />,
      }),
      "bottom-right"
    );
    */
    map.addControl(
      new MyReactControl({
        element: <MapButtonStaticLegend />,
      }),
      "bottom-right"
    );
    map.addControl(
      new MyReactControl({
        element: <MapButtonLayers />,
      }),
      "bottom-right"
    );
    map.addControl(
      new maplibregl.NavigationControl({
        showCompass: true,
      }),
      "bottom-right"
    );

    map.addControl(
      new maplibregl.AttributionControl({
        compact: false,
      }),
      "bottom-left"
    );

    map.addControl(
      (map._geolocationcontrol = new maplibregl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      })),
      "bottom-right"
    );

    /**
     * Add idle event listener, and fire setMapUpdated
     * The idle event is sent when something moved on the map, but is now stable
     */
    map.on("idle", (e) => {
      setMapUpdated();
    });
    map.on("moveend", onMove);

    map.on("click", (e) => {
      const site = getSiteFromMousePoint(map, e.point);
      if (site) {
        setFlyTo(useStore.getState().sidePanelOpened === "right" ? null : e.lngLat);
        setSidePanelOpened("right");
        setRightPanel({ type: "site", id: site.properties.site_id });
      }
      return false;
    });

    setMapObject(map);
  };

  return (
    <div id="MainArkeoMap" className="map-container-container">
      <div ref={mapContainer} className="map-container" />
      {pois.loading || !geojsonResult ? <Loader /> : ""}
    </div>
  );
};

MainMap.propTypes = {};

export default MainMap;
