import { gql } from '@apollo/client';

export const GetAllCharacsT = gql`
query GetAllCharacs($lang: bpchar!) {
  ako_charac(where: {parent_id: {_eq: 0}, id: {_neq: 0}}, order_by: {order: asc}) {
    charac_root {
      root_charac_id
    }
    id
    ark_id
    aat_id
    pactols_id
    charac_trs(where: {lang_isocode: {_eq: $lang}}) {
      name
    }
    characs(order_by: {order: asc}) {
    	id
    	ark_id
      aat_id
      pactols_id
    	charac_trs(where: {lang_isocode: {_eq: $lang}}) {
      	name
    	}
 	    characs(order_by: {order: asc}) {
    		id
    		ark_id
        aat_id
        pactols_id
    		charac_trs(where: {lang_isocode: {_eq: $lang}}) {
      		name
    		}
        characs(order_by: {order: asc}) {
          id
          ark_id
          aat_id
          pactols_id
          charac_trs(where: {lang_isocode: {_eq: $lang}}) {
            name
          }
          characs(order_by: {order: asc}) {
            id
            ark_id
            aat_id
            pactols_id
            charac_trs(where: {lang_isocode: {_eq: $lang}}) {
              name
            }
          }
        }
    	}
    }
  }
}
`;

export const GetAllCharacs = gql`
query GetAllCharacs {
  ako_charac(where: {parent_id: {_eq: 0}, id: {_neq: 0}}, order_by: {order: asc}) {
    charac_root {
      root_charac_id
    }
    id
    ark_id
    aat_id
    pactols_id
    charac_trs {
      lang_isocode
      name
    }
    characs(order_by: {order: asc}) {
    	id
    	ark_id
      aat_id
      pactols_id
    	charac_trs {
        lang_isocode
      	name
    	}
 	    characs(order_by: {order: asc}) {
    		id
    		ark_id
        aat_id
        pactols_id
    		charac_trs {
          lang_isocode
      		name
    		}
        characs(order_by: {order: asc}) {
          id
          ark_id
          aat_id
          pactols_id
          charac_trs {
            lang_isocode
            name
          }
          characs(order_by: {order: asc}) {
            id
            ark_id
            aat_id
            pactols_id
            charac_trs {
              lang_isocode
              name
            }
          }
        }
    	}
    }
  }
}
`;
