import React from 'react'

// i18n
import { useTranslation } from 'react-i18next';

import TabbedMDComponent from './TabbedMDComponent'


const Help = props => {
  const { t/*, i18n*/ } = useTranslation();

  const tabs = [
    { code: 'interface', text: t("components.Help.interface.title")},
    { code: 'when', text: t("components.Help.when.title")},
    { code: 'what', text: t("components.Help.what.title")},
    { code: 'where', text: t("components.Help.where.title")},
    { code: 'others', text: t("components.Help.others.title")},
    { code: 'map', text: t("components.Help.map.title")},
  ];

  return (
    <TabbedMDComponent section={'help'} tabs={tabs} />
  )
}

export default Help
