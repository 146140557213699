import React, { useState } from "react";
//import PropTypes from 'prop-types'
import _ from "underscore";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import SlidingLabel from "../SlidingLabel/SlidingLabel";

// i18n
import { useTranslation } from "react-i18next";

import useStore from "../../lib/store";
import { shallow } from "zustand/shallow";

import { basemaps } from "../../lib/map/basemaps";

const LayersList = () => {
  const [basemap, setBasemap] = useStore(
    (state) => [state.basemap, state.setBasemap],
    shallow
  );

  return (
    <div className="arkeolayers-list">
      {_.map(basemaps, (m, k) => {
        return (
          <OverlayTrigger
            key={k}
            trigger={["hover", "focus"]}
            placement="top"
            overlay={
              <Popover id={`popover-basemap`}>
                <Popover.Body>{m.name}</Popover.Body>
              </Popover>
            }
          >
            <button
              onClick={() => setBasemap(k)}
              className={`arkeolayers-layer-button ${
                basemap === k ? "selected" : ""
              }`}
              aria-label={m.name}
            >
              <img src={m.icon} alt={m.name} width="24" height="24" />
            </button>
          </OverlayTrigger>
        );
      })}
    </div>
  );
};

const MapButtonLayers = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <SlidingLabel
      label={t("components.MainMap.MapButtonLayers.label")}
      hide={show}
    >
      <button
        className={`MapButtonLayers ${show ? "active" : ""}`}
        onClick={() => setShow(!show)}
        title=""
        aria-label={t("components.MainMap.MapButtonLayers.label")}
      >
        <img alt="layers" src="/icons-maps/layers.svg" />
      </button>
      <div className={`arkeolayers ${show ? "opened" : "closed"}`}>
        <LayersList />
      </div>
    </SlidingLabel>
  );
};

MapButtonLayers.propTypes = {};

export default MapButtonLayers;
