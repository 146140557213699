import React from 'react'
import PropTypes from 'prop-types'

// i18n
import { useTranslation } from 'react-i18next';

import useStore, { useValidatedSearchStore } from '../../lib/store'
import { shallow } from 'zustand/shallow'

import { UNDETERMINED_LEFT, UNDETERMINED_RIGHT } from '../../lib/year';
import { getInLang } from '../../lib/translate';
import { getChronologyById } from '../../lib/chronologiesSelection';
import _ from 'underscore';

import { yearToHuman } from '../../lib/year';
import config from '../../config.json';

const getPeriods = (selectedChronology, features, t) => {
  const periods = {};
  const undetermineds = periods[config.chronology.undetermined.id] = {
    count: 0,
    chronology: {
      id: config.chronology.undetermined.id,
      start_date: UNDETERMINED_LEFT,
      end_date: UNDETERMINED_RIGHT,
      color: config.chronology.undetermined.color.slice(1),
      chronology_trs: [
        { lang_isocode: 'C', name: t('components.LegendWhen.chronology.undetermined') }
      ],
    }
  }

  const outsides = periods[config.chronology.outside.id] = {
    count: 0,
    chronology: {
      id: config.chronology.outside.id,
      //start_date: UNDETERMINED_LEFT,
      //end_date: UNDETERMINED_RIGHT,
      color: config.chronology.outside.color.slice(1),
      chronology_trs: [
        { lang_isocode: 'C', name: t('components.LegendWhen.chronology.unknown') }
      ],
    }
  }

  features.forEach(feature => {
    //const chronology = getChronologyById(selectedChronology, feature.properties.periodeid2);
    //const kstart=`{site.start_date1} {site.start_date2}`;
    //periods[kstart]=null;
    //const kend=`${feature.properties.dates[2]} ${feature.properties.dates[3]}`;
    //const kend=`${feature.properties.end_date1} ${feature.properties.end_date2}`;
    const kend=feature.properties.periodeid2;
    if (kend in periods)
      periods[kend].count++;
    else
      periods[kend]={
        count: 1,
        chronology: getChronologyById(selectedChronology, feature.properties.periodeid2),
      }
  })

  delete periods[config.chronology.undetermined.id];
  delete periods[config.chronology.outside.id];
  const result = _.toArray(periods).filter(v => v.chronology !== null)

  return { periods: result/*.sort((a,b) => b.count - a.count)*/, undetermineds, outsides };
}

const chronoToLongName = (chronology, lang) => {
  let name = "";

  while (chronology && chronology.parent !== null) {
    name = getInLang(chronology.chronology_trs, lang).name + (name === "" ? "" : " / "+name);
    chronology = chronology.parent;
  }

  return name;
}

const Period = props => {
  const { i18n } = useTranslation();
  const { title, period } = props;
  return (
    <div className="Period">
      <ul><li className="PeriodName">{title !== undefined ? title : chronoToLongName(period.chronology, i18n.language)} ({period.count})</li></ul>
      <div className="PeriodColor"><div style={{ backgroundColor: '#'+period.chronology.color}}></div></div>
      <div className="PeriodDate">
        <div>{period.chronology.start_date !== undefined ? yearToHuman(period.chronology.start_date) : ''}</div>
        <div className="arrow">{period.chronology.start_date !== undefined || period.chronology.end_date !== undefined ? '=>' : ''}</div>
        <div>{period.chronology.end_date !== undefined ? yearToHuman(period.chronology.end_date) : ''}</div>
      </div>
      <div className="PeriodPactol">{period.chronology.id_ark_pactols ? <a href={period.chronology.id_ark_pactols} target="_blank" rel="noreferrer">PACTOLS</a> : ''}</div>
      <div className="PeriodPeriodo">{period.chronology.id_ark_periodo ? <a href={period.chronology.id_ark_periodo} target="_blank" rel="noreferrer">PeriodO</a> : ''}</div>
    </div>
  )
}

const LegendWhen = props => {
  const { features } = props;
  const { t, i18n } = useTranslation();

  const [
    selectedChronologyId,
    chronologyFindIncludeUndetermined,
    chronologyFindOnlyInside,
  ] = useValidatedSearchStore(state => [
    state.selectedChronologyId,
    state.chronologyFindIncludeUndetermined,
    state.chronologyFindOnlyInside,
  ]);

  const [ setRightPanel, chronologies ] = useStore(state => [ state.setRightPanel, state.chronologies ], shallow);
  const selectedChronology = chronologies.find(c => c.id === selectedChronologyId);

  const { periods, undetermineds, outsides } = getPeriods(selectedChronology.chronologies, features, t);

  return (
    <div className="LegendWhen">
      <h2>{t`components.LegendWhen.used-chronology.title`}</h2>
        <a
        href='/#'
        rel="noreferrer"
        onClick={e => {
          setRightPanel({type: 'chronology', id: selectedChronology.id})
          e.preventDefault();
        }}
      >
        {getInLang(selectedChronology.chronology_trs, i18n.language).name}
      </a>

      {periods.length > 0 && (
        <>
          <h2>{t`components.LegendWhen.periods-from-this-chrono.title`}</h2>
          <div className="Periods">
            {periods.map((period,i) => (
              <Period key={i} period={period}/>
            ))}
          </div>
        </>
      )}

      {outsides.count > 0 && (
        <React.Fragment>
          <h2>{t`components.LegendWhen.not-included.title`}</h2>
          <div className="Periods">
            <Period period={outsides} title={t`components.LegendWhen.include.outsides`}/>
          </div>
        </React.Fragment>
      )}

      <h2>{t`components.LegendWhen.include.title`}</h2>

      { chronologyFindIncludeUndetermined ? (
        <div className="Periods">
          <Period period={undetermineds} title={t`components.LegendWhen.include.undetermined`}/>
        </div>
      ) : ''}

      <div>
        {chronologyFindOnlyInside ? t`components.LegendWhen.include.only-inside` : t`components.LegendWhen.include.not-only-inside`}
      </div>
    </div>
  )
}

LegendWhen.propTypes = {
  features: PropTypes.array.isRequired,
}

export default LegendWhen
