import React from 'react'
import PropTypes from 'prop-types'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Markdown from '../Markdown/Markdown';

// i18n
import { useTranslation } from 'react-i18next';
import { getInLang } from '../../lib/translate';
//

import { yearToHuman } from '../../lib/year';


const Chronology = props => {
  const { chronology, onClick, selected } = props;
  const { i18n } = useTranslation();

  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement='auto-end'
      overlay={
        <Popover id={`popover-chronology`} className='popover-chronology'>
          <Popover.Header>
            <div>{yearToHuman(chronology.start_date, i18n.language)} =&gt; {yearToHuman(chronology.end_date, i18n.language)}</div>
          </Popover.Header>
          <Popover.Body>
            <div className="poptextwrap"><Markdown>{getInLang(chronology.chronology_trs, i18n.language).description}</Markdown></div>
          </Popover.Body>
        </Popover>
      }
    >
      <button className={`Chronology btn btn-secondary ${selected ? ' selected' : 'not-selected'}`} onClick={onClick}>
        <div className="Icon" style={{backgroundImage: `url(/icons-chronologies/chronology-${chronology.id}.svg)`}}></div>
        <div className="Name">{getInLang(chronology.chronology_trs, i18n.language).name}</div>
      </button>
    </OverlayTrigger>
  )
}

Chronology.propTypes = {
  /**
   * The Chronology Object (including id, date_start, date_end)
   */
  chronology: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
}

export default Chronology
