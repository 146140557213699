import Button from 'react-bootstrap/Button';

import React from 'react'
//import PropTypes from 'prop-types'

import FormSearchWhen from '../FormSearchWhen/FormSearchWhen';
import FormSearchWhat from '../FormSearchWhat/FormSearchWhat';
import FormSearchWhere from '../FormSearchWhere/FormSearchWhere';
import FormSearchOthers from '../FormSearchOthers/FormSearchOthers';
import SlidableTabs from '../SlidableTabs/SlidableTabs';
import { CloseIcon, ResetIcon } from '../Icons/Icons';

// i18n
import { useTranslation } from 'react-i18next';

// zustand
import useStore, { useSearchStore, useValidatedSearchStore } from '../../lib/store'
//import {shallow} from 'zustand/shallow'
//

import './MapSideSearch.scss';

/**
 * MapSideSearchButtons are the buttons of the left search sidebar
 * @param {*} props (none)
 * @returns The component
 */
const MapSideSearchButtons = props => {
  const { t/*, i18n*/ } = useTranslation();

  const setSidePanelOpened = useStore(state => state.setSidePanelOpened);
  const clearSearch = useSearchStore(state => state.clearSearch);
  const validateSearch = useValidatedSearchStore(state => state.validateSearch);

  const doTheSearch = () => {
    validateSearch();
    setSidePanelOpened('none');
  }

  return (
    <div className="SearchButtons">
      <Button className="SearchResetButton big" variant="light" onClick={clearSearch}><ResetIcon /></Button>
      <Button className="SearchButton big" variant="light" onClick={doTheSearch}>{t('components.MapSideSearch.button-validateSearch.label')}</Button>
    </div>
  );
}

/**
 * This is the left side search component inside the SideBar. It contain all tabbed components (when, what, where, etc.)
 * @param {*} props (none)
 * @returns The Map Side Search Component
 */
const MapSideSearch = props => {
  const { t/*, i18n*/ } = useTranslation();
  const setSidePanelOpened = useStore(state => state.setSidePanelOpened);

  const tabs = [
    {
      code: "when",
      text: t('components.MapSideSearch.when.title'),
      content: <FormSearchWhen />
    },
    {
      code: "what",
      text: t('components.MapSideSearch.what.title'),
      content: <FormSearchWhat />
    },
    {
      code: "where",
      text: t('components.MapSideSearch.where.title'),
      content: <FormSearchWhere />
    },
    {
      code: "other",
      text: t('components.MapSideSearch.other.title'),
      content: <FormSearchOthers />
    },
  ]

  return (
    <div className="MapSideSearch">
      <div className="SearchForm">
        <div className="SearchHeader">
          <div className="SaerchHeaderTitle">{t('components.MapSideSearch.title')}</div>
          <div className="SaerchHeaderButtons">
            <button className="CloseButton" onClick={() => { setSidePanelOpened('none'); }}><CloseIcon /></button>
          </div>
        </div>
        <SlidableTabs className="SlidableTabs" id="mapSideSearchTabs" tabs={tabs} hideNavigationOnBigScreen></SlidableTabs>
      </div>
      <MapSideSearchButtons />
    </div>
  )
}

MapSideSearch.propTypes = {}

export default MapSideSearch
