// zustand
import useStore, { useSearchStore } from './store'
//import {shallow} from 'zustand/shallow'
//

import _ from 'underscore';

/**
 * Return a Charac Object giving an id.
 *
 * @param {Object[]} characs The global characs tree
 * @param {int} id
 * @returns {Object}
 */
export function getCharacById(characs, id) {
  for (let charac of characs) {
    if (charac.id === id) return charac;
    const _charac = getCharacById(charac.characs, id);
    if (_charac) return _charac;
  }
  return null;
}


/**
 * Test if all sub characs are selected or not
 * This code is an adaptation of the one from Arkeogis 4.x
 *
 * @param {int[]} selecteds
 * @param {Object} charac
 * @param {int[]} concerneds
 * @returns {boolean}
 */
 function testSubCharacsSelection(selecteds, charac, concerneds) {
  concerneds.push(charac.id);
  if (selecteds.indexOf(charac.id) !== -1) {
    var ok = true;
    _.each(charac.characs, function(subcharac) {
      if (!testSubCharacsSelection(selecteds, subcharac, concerneds))
        ok=false;
    });
    return ok;
  }
  return false;
}

/**
 * Build a path for one Charac
 * This code is an adaptation of the one from Arkeogis 4.x
 *
 * @param {Object[]} characs : The global characs tree
 * @param {Object} charac : The charac
 * @param {Ref of int[]} selectedsref : This is a Ref to an array of charac ids (selection)
 * @returns {int[][]}
 */
function buildPath(characs, charac, selectedsref) {

  // check if the parent is in selection, so we build the path from the parent before.
  if (charac.parent && selectedsref.current.indexOf(charac.parent.id) !== -1)
    return buildPath(characs, charac.parent, selectedsref);

  // check if all childrends are also in selection, or not
  var concerneds = [];
  var withChildrens = false;
  if (testSubCharacsSelection(selectedsref.current, charac, concerneds)) {
    // this charac have all it's childrends selecteds.

    if (concerneds.length > 1)
      withChildrens = true;

    // remove all concerneds from selecteds, because theses are childrens
    selectedsref.current = selectedsref.current.filter(id => !concerneds.includes(id))

  } else {
    // this charac do NOT have all it's childrends selecteds.

    withChildrens = false;

    // remove only this charac, not childrens
    var i=selectedsref.current.indexOf(charac.id);
    if (i > -1) selectedsref.current.splice(i, 1);
  }

  // now build the full path
  var c=charac;

  /*
  var path="";
  while(c) {
    path=getInLang(c.charac_trs, 'fr').name+(path != '' ? ' / '+path : '');
    c=c.parent;
  }
  if (withChildrens) path+='*';
  */

  /*
  let path=[];
  while(c) {
    path = [ c.id, ...path ]
    c=c.parent;
  }
  if (withChildrens) path[path.length-1]=-path[path.length-1];

  return path;
  */

  return withChildrens ? -c.id : c.id;
}

/**
 * Build paths of selecteds characs.
 * This code is an adaptation of the one from Arkeogis 4.x
 *
 * @param {Object[]} characs : The global characs tree
 * @param {int[]} selecteds : An id array of seleteds characs (not compacted)
 * @returns
 */
export function buildPaths(characs, selecteds) {
  var paths=[];
  const selectedsref = {
    current: [...selecteds ]
  }
  while (selectedsref.current.length > 0) {
    var charac = getCharacById(characs, selectedsref.current[0]);
    if (charac)
      paths.push(buildPath(characs, charac, selectedsref));
    else {
      console.warn("a charac doesn't exist anymore, id: ", selectedsref.current[0])
      selectedsref.current.splice(0, 1);
    }
  }
  return paths;
}

export function compactCharacsSelection(selecteds) {
  return buildPaths(useStore.getState().characs, selecteds);
}

/**
 * Get All Characs Ids of a charac and it's childrens
 *
 * @param {Object} charac : The charac Object
 * @param {int[]} result : An array of charac ids
 * @returns
 */
const getAllCharacsIds = (charac, result=[]) => {
  result.push(charac.id);
  charac.characs.forEach(_charac => getAllCharacsIds(_charac, result));
  return result;
}

/**
 * Set the selection in the Serach Store, including childrens
 *
 * @param {Object} charac : The charac Object
 * @param {boolean} selected : set selected or not
 */
export const setCharacSelected = (charac, selected) => {
  let characSelection = useSearchStore.getState().characSelection; // we need a fresh version of characSelection, the one took from useStore may be not the latest due to the trigger filtering, and may not be the latest version
  const ids = getAllCharacsIds(charac);
  let newSelection = selected ? [ ...characSelection, ...ids ] : characSelection.filter(id => !ids.includes(id));

  //useSearchStore.setState({characSelection : newSelection });
  useSearchStore.getState().setCharacSelection(newSelection);
}

/**
 *
 * @param {Object[]} characs : The global characs tree
 * @param {*} compactSelection
 * @returns
 */
export const decompactSelection = (characs, compactSelection) => {
  let result = [];
  compactSelection.forEach(selection => {
    if (selection < 0) {
      const charac = getCharacById(characs, -selection);
      result = [ ...result, ...getAllCharacsIds(charac) ];
    } else {
      result.push(selection);
    }
  })
  return result;
}

export const decompactCharacsSelection = (compactSelection) => {
  return decompactSelection(useStore.getState().characs, compactSelection);
}
