import React, { useEffect } from "react";
import PropTypes from "prop-types";

// react router
import { useSearchParams } from "react-router-dom";

// zustand
import useStore, {
  useValidatedSearchStore,
  defaults,
  useSearchStore,
} from "../../lib/store";
import { shallow } from "zustand/shallow";
//

import _ from "underscore";

import config from "../../config.json";

const subseparator = "§";

/**
 * React component for managing and syncing state with URL parameters.
 * Manages various state values such as side panel, right panel, map coordinates, basemap, and search filters.
 * Utilizes useEffect hooks to update state based on URL parameters and vice versa.
 *
 * @returns {JSX.Element} A React fragment
 */
const StoreRoute = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [
    sidePanelOpened,
    setSidePanelOpened,
    rightPanel,
    setRightPanel,
    lng,
    setLng,
    lat,
    setLat,
    zoom,
    setZoom,
    basemap,
    setBasemap,
    ungrouped,
    setUngrouped,
    mapUpdated,
  ] = useStore(
    (state) => [
      state.sidePanelOpened,
      state.setSidePanelOpened,
      state.rightPanel,
      state.setRightPanel,
      state.lng,
      state.setLng,
      state.lat,
      state.setLat,
      state.zoom,
      state.setZoom,
      state.basemap,
      state.setBasemap,
      state.ungrouped,
      state.setUngrouped,
      state.mapUpdated,
    ],
    shallow
  );

  const [
    characSelectionCompacted,
    setCharacSelectionCompacted,
    selectedChronologyId,
    setSelectedChronologyId,
    chronologyStartDate,
    setChronologyStartDate,
    chronologyEndDate,
    setChronologyEndDate,
    chronologyFindIncludeUndetermined,
    setChronologyFindIncludeUndetermined,
    chronologyFindOnlyInside,
    setChronologyFindOnlyInside,
    selectedShapefiles,
    setSelectedShapefiles,
    knowledgeTypes,
    setKnowledgeTypes,
    datasetTypes,
    setDatasetTypes,
    scaleResolutions,
    setScaleResolutions,
    centroid,
    setCentroid,
    exceptional,
    setExceptional,
    illustrated,
    setIllustrated,
    editors,
    setEditors,
    authors,
    setAuthors,
    databases,
    setDatabases,
    textual,
    setTextual,
    textualOn,
    setTextualOn,
  ] = useValidatedSearchStore(
    (state) => [
      state.characSelectionCompacted,
      state.setCharacSelectionCompacted,
      state.selectedChronologyId,
      state.setSelectedChronologyId,
      state.chronologyStartDate,
      state.setChronologyStartDate,
      state.chronologyEndDate,
      state.setChronologyEndDate,
      state.chronologyFindIncludeUndetermined,
      state.setChronologyFindIncludeUndetermined,
      state.chronologyFindOnlyInside,
      state.setChronologyFindOnlyInside,
      state.selectedShapefiles,
      state.setSelectedShapefiles,
      state.knowledgeTypes,
      state.setKnowledgeTypes,
      state.datasetTypes,
      state.setDatasetTypes,
      state.scaleResolutions,
      state.setScaleResolutions,
      state.centroid,
      state.setCentroid,
      state.exceptional,
      state.setExceptional,
      state.illustrated,
      state.setIllustrated,
      state.editors,
      state.setEditors,
      state.authors,
      state.setAuthors,
      state.databases,
      state.setDatabases,
      state.textual,
      state.setTextual,
      state.textualOn,
      state.setTextualOn,
    ],
    shallow
  );

  const [copyFromValidated] = useSearchStore(
    (state) => [state.copyFromValidated],
    shallow
  );

  /**
   * useEffect executed when loading params from url
   */
  useEffect(() => {
    const params = {};
    let validateSearch = false;
    //searchParams.forEach((param,k) => params[k] = param);
    new URLSearchParams(window.location.search).forEach(
      (param, k) => (params[k] = param)
    );
    //console.log("url params : ", params);

    const convertFromUrlstring = (s) => s;
    const convertFromUrlBool = (b) =>
      b == "1" ? true : b == "0" ? false : null;
    const convertFromUrlInt = parseInt;
    const convertFromUrlFloat = parseFloat;
    const convertFromUrlStringArray = (v) =>
      v.split(subseparator).map((e) => e);
    const convertFromUrlIntArray = (v) =>
      v.split(subseparator).map((e) => parseInt(e));
    const convertFromUrlRightPanel = (rp) => {
      const splited = rp.split(subseparator);
      const param_rp = {};
      if (splited.length >= 1) param_rp.type = splited[0];
      if (splited.length == 2) param_rp.id = splited[1];
      return param_rp;
    };

    /**
     * Updates a value in the store based on the value from the URL parameters.
     *
     * @param {string} urlKey - The key in the URL parameters to check for.
     * @param {string} storeKey - The key in the store to update.
     * @param {any} storeValue - The current value in the store.
     * @param {function} storeSet - The function to set the new value in the store.
     * @param {function} [convertFromUrl=convertFromUrlstring] - The function to convert the URL parameter value.
     */
    const uSet = (
      urlKey,
      storeKey,
      storeValue,
      storeSet,
      convertFromUrl = convertFromUrlstring
    ) => {
      if (urlKey in params) {
        const v = convertFromUrl(params[urlKey]);
        if (!_.isEqual(v, storeValue)) {
          //console.log(`set ${storeKey} to `, v);
          storeSet(v);
          validateSearch = true;
        }
      } else {
        if (!_.isEqual(storeValue, defaults[storeKey])) {
          //console.log(`reset ${storeKey} to `, defaults[storeKey]);
          storeSet(defaults[storeKey]);
          validateSearch = true;
        }
      }
    };

    try {
      uSet(
        "p",
        "sidePanelOpened",
        sidePanelOpened,
        setSidePanelOpened,
        convertFromUrlstring
      );
      uSet(
        "rp",
        "rightPanel",
        rightPanel,
        (v) => setRightPanel(v, false),
        convertFromUrlRightPanel
      );
      uSet("lng", "lng", lng, setLng, convertFromUrlFloat);
      uSet("lat", "lat", lat, setLat, convertFromUrlFloat);
      uSet("z", "zoom", zoom, setZoom, convertFromUrlFloat);
      uSet(
        "cha",
        "characSelectionCompacted",
        characSelectionCompacted,
        setCharacSelectionCompacted,
        convertFromUrlIntArray
      );
      uSet(
        "c",
        "selectedChronologyId",
        selectedChronologyId,
        setSelectedChronologyId,
        convertFromUrlInt
      );
      uSet(
        "sd",
        "chronologyStartDate",
        chronologyStartDate,
        setChronologyStartDate,
        convertFromUrlInt
      );
      uSet(
        "ed",
        "chronologyEndDate",
        chronologyEndDate,
        setChronologyEndDate,
        convertFromUrlInt
      );
      uSet(
        "iu",
        "chronologyFindIncludeUndetermined",
        chronologyFindIncludeUndetermined,
        setChronologyFindIncludeUndetermined,
        convertFromUrlBool
      );
      uSet(
        "oi",
        "chronologyFindOnlyInside",
        chronologyFindOnlyInside,
        setChronologyFindOnlyInside,
        convertFromUrlBool
      );
      uSet(
        "shps",
        "selectedShapefiles",
        selectedShapefiles,
        setSelectedShapefiles,
        convertFromUrlIntArray
      );
      uSet(
        "kt",
        "knowledgeTypes",
        knowledgeTypes,
        setKnowledgeTypes,
        convertFromUrlStringArray
      );
      uSet(
        "dt",
        "datasetTypes",
        datasetTypes,
        setDatasetTypes,
        convertFromUrlStringArray
      );
      uSet(
        "sr",
        "scaleResolutions",
        scaleResolutions,
        setScaleResolutions,
        convertFromUrlStringArray
      );
      uSet("ce", "centroid", centroid, setCentroid, convertFromUrlBool);
      uSet(
        "exceptional",
        "exceptional",
        exceptional,
        setExceptional,
        convertFromUrlBool
      );
      uSet(
        "illustrated",
        "illustrated",
        illustrated,
        setIllustrated,
        convertFromUrlBool
      );
      uSet(
        "editors",
        "editors",
        editors,
        setEditors,
        convertFromUrlStringArray
      );
      uSet("authors", "authors", authors, setAuthors, convertFromUrlIntArray);
      uSet("db", "databases", databases, setDatabases, convertFromUrlIntArray);
      uSet("textual", "textual", textual, setTextual, convertFromUrlstring);
      uSet(
        "textualOn",
        "textualOn",
        textualOn,
        setTextualOn,
        convertFromUrlstring
      );
      uSet("basemap", "basemap", basemap, setBasemap, convertFromUrlstring);
      uSet(
        "ungrouped",
        "ungrouped",
        ungrouped,
        setUngrouped,
        convertFromUrlBool
      );

      if (validateSearch) copyFromValidated();
    } catch (err) {
      console.error(err);
    }
  }, [searchParams]);

  /**
   * useEffect executed when params changed, and we have to rewrite the url with thems
   */
  useEffect(() => {
    const params = {};
    //searchParams.forEach((param,k) => params[k] = param);
    new URLSearchParams(window.location.search).forEach(
      (param, k) => (params[k] = param)
    );
    const { sidePanelOpened, rightPanel } = useStore.getState();

    // here, we dont touch to lng/lat/zoom, so we takes theses values from url back
    //const settings = _.pick(params, ['lng', 'lat', 'z']);
    const settings = _.clone(params); // try to keep all params

    const convertToUrlString = (v) => `${v}`;
    const convertToUrlInt = (v) => `${v}`;
    const convertToUrlFloat = (v) => `${v}`;
    const convertToUrlBool = (b) => (b ? "1" : "0");
    const convertToUrlIntArray = (v) => v.join(subseparator);
    const convertToUrlStringArray = (v) => v.join(subseparator);
    const convertToUrlRightPanel = (v) =>
      `${v.type}${v.id ? subseparator + v.id : ""}`;

    /**
     * Updates the settings object with a new value based on the provided store key and value.
     * If the new store value is different from the default value, it updates the settings object with the converted value.
     * If the new store value is the same as the default value, it removes the URL key from the settings object.
     *
     * @param {string} urlKey - The key in the settings object that corresponds to the URL parameter.
     * @param {string} storeKey - The key in the store object that holds the value to be updated.
     * @param {any} storeValue - The new value to be set in the settings object.
     * @param {function} convertToUrl - A function that converts the store value to the URL parameter value.
     */
    const uSet = (urlKey, storeKey, storeValue, convertToUrl) => {
      if (
        ![
          "sidePanelOpened",
          "rightPanel",
          "basemap",
          "lat",
          "lng",
          "zoom",
          "ungrouped",
        ].includes(storeKey)
      ) {
        let storeValues = useValidatedSearchStore.getState();
        //console.log("storeValues", storeValues)
        storeValue = storeValues[storeKey];
      }
      if (!_.isEqual(defaults[storeKey], storeValue)) {
        //console.log(`set in url (${storeKey} => ${urlKey}) value: `, storeValue, convertToUrl(storeValue));
        settings[urlKey] = convertToUrl(storeValue);
      } else {
        //console.log(`reset in url (${storeKey} => ${urlKey}) storeValue: `, storeValue);
        delete settings[urlKey];
      }
    };

    uSet("lat", "lat", lat, convertToUrlFloat);
    uSet("lng", "lng", lng, convertToUrlFloat);
    uSet("z", "zoom", zoom, convertToUrlFloat);

    uSet("p", "sidePanelOpened", sidePanelOpened, convertToUrlString);
    uSet("rp", "rightPanel", rightPanel, convertToUrlRightPanel);
    uSet("c", "selectedChronologyId", selectedChronologyId, convertToUrlInt);
    uSet(
      "cha",
      "characSelectionCompacted",
      characSelectionCompacted,
      convertToUrlIntArray
    );
    uSet("sd", "chronologyStartDate", chronologyStartDate, convertToUrlInt);
    uSet("ed", "chronologyEndDate", chronologyEndDate, convertToUrlInt);
    uSet(
      "iu",
      "chronologyFindIncludeUndetermined",
      chronologyFindIncludeUndetermined,
      convertToUrlBool
    );
    uSet(
      "oi",
      "chronologyFindOnlyInside",
      chronologyFindOnlyInside,
      convertToUrlBool
    );
    uSet(
      "shps",
      "selectedShapefiles",
      selectedShapefiles,
      convertToUrlIntArray
    );
    uSet("kt", "knowledgeTypes", knowledgeTypes, convertToUrlStringArray);
    uSet("dt", "datasetTypes", datasetTypes, convertToUrlStringArray);
    uSet("sr", "scaleResolutions", scaleResolutions, convertToUrlStringArray);
    uSet("ce", "centroid", centroid, convertToUrlBool);
    uSet("exceptional", "exceptional", exceptional, convertToUrlBool);
    uSet("illustrated", "illustrated", illustrated, convertToUrlBool);
    uSet("editors", "editors", editors, convertToUrlStringArray);
    uSet("authors", "authors", authors, convertToUrlIntArray);
    uSet("db", "databases", databases, convertToUrlIntArray);
    uSet("textual", "textual", textual, convertToUrlString);
    uSet("textualOn", "textualOn", textualOn, convertToUrlString);
    uSet("basemap", "basemap", basemap, convertToUrlString);
    uSet("ungrouped", "ungrouped", ungrouped, convertToUrlBool);

    //console.log("oth params : ", params, settings, !_.isEqual(params, settings));
    if (!_.isEqual(params, settings)) {
      setSearchParams(settings);
    }
  }, [
    sidePanelOpened,
    rightPanel,
    characSelectionCompacted,
    selectedChronologyId,
    chronologyStartDate,
    chronologyEndDate,
    chronologyFindIncludeUndetermined,
    chronologyFindOnlyInside,
    selectedShapefiles,
    knowledgeTypes,
    datasetTypes,
    scaleResolutions,
    centroid,
    exceptional,
    illustrated,
    editors,
    authors,
    databases,
    textual,
    textualOn,
    basemap,
    ungrouped,
    lat,
    lng,
    zoom,
    mapUpdated,
  ]);

  /**
   * useEffect speciffically for lat/lng/zoom, and we have to rewrite the url with thems
   */
  /*
  useEffect(() => {
    const params = {};
    //searchParams.forEach((param,k) => params[k] = param);
    new URLSearchParams(window.location.search).forEach((param,k) => params[k] = param);

    const { lat, lng, zoom } = useStore.getState(); // override because reacted values are not yet updated

    // here, we touch only lng/lat/zoom, so we takes others values from url back
    const settings = _.omit(params, ['lng', 'lat', 'z']);

    if (defaults['lng'] !== lng) settings['lng']=''+lng;
    if (defaults['lat'] !== lat) settings['lat']=''+lat;
    if (defaults['zoom'] !== zoom) settings['z']=''+zoom;

    //const oldParams = _.pick(params, ['lng', 'lat', 'z']);
    //console.log("coords params : ", params, settings, !_.isEqual(params, settings));
    if (!_.isEqual(params, settings)) {
      setSearchParams(settings);
    }
  }, [ mapUpdated ]);
  */

  return <React.Fragment />;
};

StoreRoute.propTypes = {};

export default StoreRoute;
