import React from 'react'
import PropTypes from 'prop-types'

// popup
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

// zustand
import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'

import { BsChevronCompactRight, BsChevronCompactLeft } from 'react-icons/bs'

// i18n
import { useTranslation } from 'react-i18next';
//

import './SidePanel.scss';

/**
 * SidePanel is the left or right side panel that can be opened
 * @param {*} props
 * @returns The SidePanel component
 */
const SidePanel = props => {
    const { t } = useTranslation();
    const { position, children } = props;
    const [ sidePanelOpened, setSidePanelOpened ] = useStore(state => [ state.sidePanelOpened, state.setSidePanelOpened], shallow)
    const show = sidePanelOpened === position;

    const toggleShow = () => {
        setSidePanelOpened(show ? 'none': position);
    }

  return (
    <React.Fragment>
        <div className={`SidePanel ${position}`+(show ? ' show' : '')}>
          {children}
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement={`${position === 'left' ? 'right' : 'left'}`}
            overlay={
            <Popover className="panel-popover" id={`popover-positioned-${position === 'left' ? 'right' : 'left'}`}>
              <Popover.Body>
                {position === 'left' ? t("components.SidePanel.left.handle.popup") : t("components.SidePanel.right.handle.popup")}
              </Popover.Body>
            </Popover>
            }
          >
            <button
              className="SidePanelHandle"
              onClick={toggleShow}
              title=''
            >
              {position === 'left' ? <BsChevronCompactRight/> : <BsChevronCompactLeft/>}
            </button>
          </OverlayTrigger>
        </div>
    </React.Fragment>
  )
}

SidePanel.propTypes = {
    /**
     * position: string "left" or "right"
     */
    position: PropTypes.string.isRequired,
    /**
     * The node element that will be inside the SidePanel
     */
    children: PropTypes.node.isRequired,
}

export default SidePanel
