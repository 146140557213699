import React from 'react'
import PropTypes from 'prop-types'

import useAxios from 'axios-hooks'

import Markdown from '../Markdown/Markdown';


// i18n
import { useTranslation } from 'react-i18next';
//
import CloseButton from '../CloseButton/CloseButton';
import FundersLogos from '../FundersLogos/FundersLogos';

import styles from './PagesProject.module.scss'

const PagesProject = props => {
  const { /*t, */i18n } = useTranslation();
  const { code } = props;

  const [{ data, loading, error }/*, refetch*/] = useAxios(
    `/pages/project/${code}.${i18n.language}.md`
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error!</p>

  return (
    <div className={styles.pagesProject}>
      <CloseButton />

      <Markdown>{data}</Markdown>

      <br />
      <hr />
      <FundersLogos className={styles.logos}/>

    </div>
  )
}

PagesProject.propTypes = {
  /**
   * code: the code name of the md page ('intro', ...)
   */
  code: PropTypes.string.isRequired,
}

export default PagesProject
