import React, { useState, useEffect } from "react";

// i18n
import { useTranslation } from "react-i18next";

import useStore, { useValidatedSearchStore } from "../../lib/store";
import { shallow } from "zustand/shallow";
import { getInLang } from "../../lib/translate";

import { basemaps } from "../../lib/map/basemaps";

const BoundingBox = ({ bbox, width, height }) => {
  if (!bbox) return;
  return (
    <div className="BoundingBox" style={{width, height}}>
      <div className="Area">
        <div className="Corner TopLeft"></div>
        <div className="Corner TopRight"></div>
        <div className="Corner BottomLeft"></div>
        <div className="Corner BottomRight"></div>
        <div className="NE">{`lng: ${Number.parseFloat(bbox._ne.lng).toFixed(
          2
        )}, lat: ${Number.parseFloat(bbox._ne.lat).toFixed(2)}`}</div>
        <div className="SW">{`lng: ${Number.parseFloat(bbox._sw.lng).toFixed(
          2
        )}, lat: ${Number.parseFloat(bbox._sw.lat).toFixed(2)}`}</div>
      </div>
    </div>
  );
};

const LegendWhere = () => {
  const { t, i18n } = useTranslation();

  const [map, mapUpdated, allMapDrawed, basemap, shapefiles, setRightPanel] =
    useStore(
      (state) => [
        state.mapObject,
        state.mapUpdated,
        state.allMapDrawed,
        state.basemap,
        state.shapefiles,
        state.setRightPanel,
      ],
      shallow
    );

  const [selectedShapefiles] = useValidatedSearchStore(
    (state) => [state.selectedShapefiles],
    shallow
  );

  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    if (map && allMapDrawed) {
      setBounds(map.getBounds());
    }
  }, [map, mapUpdated, allMapDrawed]);

  return (
    <div className="LegendWhere">
      <h2>{t`components.LegendWhere.coord.title`}</h2>

      <h3>{t`components.LegendWhere.boundaries.title`}</h3>
      {bounds && <BoundingBox bbox={bounds} width={300} height={200} />}

      <h3>{t`components.LegendWhere.basemap.title`}</h3>
      <li className="">{basemaps[basemap].name}</li>

      {selectedShapefiles.length > 0 && (
        <>
          <h2>{t`components.LegendWhere.include.title`}</h2>
          <div className="Coords">
            {selectedShapefiles.map((shapefile_id) => {
              const shapefile = shapefiles.find(
                (shapefile) => shapefile.id === shapefile_id
              );
              if (!shapefile)
                return <div key={shapefile.id}>unknwon shapefile id</div>;
              return (
                <ul key={shapefile.id}>
                  <li>
                    <a
                      href="/#"
                      rel="noreferer"
                      onClick={(e) => {
                        e.preventDefault();
                        setRightPanel({ type: "shp", id: shapefile.id });
                      }}
                    >
                      {getInLang(shapefile.shapefile_trs, i18n.language).name}
                    </a>
                  </li>
                </ul>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

LegendWhere.propTypes = {};

export default LegendWhere;
