import React from 'react'
import PropTypes from 'prop-types'

// i18n
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { GetOther } from '../../lib/queries/legend'

import useStore from '../../lib/store'
import Loader from '../Loader/Loader';

const Other = props => {
  const {varname, count, href, presentation} = props;
  const setRightPanel = useStore(state => state.setRightPanel);

  const link = presentation ?
    <a
      href='/#'
      rel="noreferrer"
      onClick={
        e => {
          e.preventDefault();
          setRightPanel(presentation)
        }
      }
    >
      {varname}
    </a> : href ? <a href={href} target='_blank' rel="noreferrer">{varname}</a> : <span>{varname}</span>;

  return (
    <div className="Other">
      <li>
        <span>{link}</span>
        {' '}
        ({count})
      </li>
    </div>
  )
}

const LegendOther = props => {
  const { features } = props;
  const { t/*, i18n*/ } = useTranslation();

  const site_ids = features.map(f => f.properties.site_id);

  const others = useQuery(GetOther, {
    variables: {
      site_ids: site_ids,
    }
  }) // others = { loading, error, data }

  if (others.loading) return <Loader />
  if (others.error) return <div className="Error">{others.error.message}</div>

  const editors = [];
  others.data.datasets.forEach(dataset => {
    const found = editors.find(editor => editor.editor === dataset.editor);
    if (found) {
      found.totalcount+=dataset.sites_aggregate.aggregate.count;
    } else {
      editors.push({
        ...dataset,
        totalcount: dataset.sites_aggregate.aggregate.count,
      });
    }
  })

  const authors = [];
  others.data.authors.forEach(node => {
    const found = authors.find(author => author.user.id === node.user.id);
    if (found) {
      found.totalcount+=node.database.sites_aggregate.aggregate.count;
    } else {
      authors.push({
        ...node,
        totalcount: node.database.sites_aggregate.aggregate.count,
      });
    }
  })

  return (
    <div className="LegendOther">

      <h2>{t`components.LegendOther.knowledge_type.title`}</h2>
      <div className="Others">
        <Other varname={t(`arkeo.knowledge_type.not_documented`)} count={others.data.knowledge_not_documented.aggregate.count}/>
        <Other varname={t(`arkeo.knowledge_type.literature`)} count={others.data.knowledge_literature.aggregate.count}/>
        <Other varname={t(`arkeo.knowledge_type.prospected_aerial`)} count={others.data.knowledge_prospected_aerial.aggregate.count}/>
        <Other varname={t(`arkeo.knowledge_type.prospected_pedestrian`)} count={others.data.knowledge_prospected_pedestrian.aggregate.count}/>
        <Other varname={t(`arkeo.knowledge_type.surveyed`)} count={others.data.knowledge_surveyed.aggregate.count}/>
        <Other varname={t(`arkeo.knowledge_type.dig`)} count={others.data.knowledge_dig.aggregate.count}/>
      </div>

      <h2>{t`components.LegendOther.precision.title`}</h2>
      <div className="Others">
        <Other varname={t(`arkeo.precision.precise`)} count={others.data.centroid_no.aggregate.count}/>
        <Other varname={t(`arkeo.precision.not-precise`)} count={others.data.centroid_yes.aggregate.count}/>
      </div>

      <h2>{t`components.LegendOther.exceptional.title`}</h2>
      <div className="Others">
        <Other varname={t(`arkeo.exceptional.exceptional`)} count={others.data.exceptional_yes.aggregate.count}/>
        <Other varname={t(`arkeo.exceptional.not-exceptional`)} count={others.data.exceptional_no.aggregate.count}/>
      </div>

      {
        editors.length > 0 && (
          <>
            <h2>{t`components.LegendOther.editors.title`}</h2>
            <div className="Others">
              {editors.sort((a,b) => b.totalcount - a.totalcount).map(node => (
                <Other key={node.editor} varname={node.editor} href={node.editor_url} count={node.totalcount}/>
              ))}
            </div>
          </>
        )
      }

      {
        authors.length > 0 && (
          <>
            <h2>{t`components.LegendOther.authors.title`}</h2>
            <div className="Others">
              {authors.sort((a,b) => b.totalcount - a.totalcount).map(author => (
                <Other key={author.user.id} varname={`${author.user.lastname} ${author.user.firstname}`} count={author.totalcount}/>
              ))}
            </div>
          </>
        )
      }

      {
        others.data.datasets > 0 && (
          <>
            <h2>{t`components.LegendOther.datasets.title`}</h2>
            <div className="Others">
              {[...others.data.datasets].sort((a,b) => b.sites_aggregate.aggregate.count - a.sites_aggregate.aggregate.count).map(dataset => (
                <Other key={dataset.id} varname={dataset.name} count={dataset.sites_aggregate.aggregate.count} presentation={{type: 'db', id: dataset.id}}/>
              ))}
            </div>
          </>
        )
      }

    </div>
  )
}

LegendOther.propTypes = {
  features: PropTypes.array.isRequired,
}

export default LegendOther
