import React, { useState} from 'react'
import { useTranslation } from 'react-i18next';
import SlidingLabel from '../SlidingLabel/SlidingLabel';

import styles from "./StaticLegend.module.scss";

const Droplet = () =><svg className={styles.droplet} xmlns="http://www.w3.org/2000/svg" width="10" height="15" fill="none"><path d="M5.034 0h-.025a.46.46 0 0 0-.034 0C2.952.014 1.155 1.292.373 3.187c-.658 1.594-.4 3.286.418 4.748.62 1.105 1.245 2.213 1.87 3.322.666 1.183 1.333 2.367 1.995 3.546.144.263.545.263.69 0 1.283-2.268 2.566-4.535 3.833-6.819.834-1.478 1.091-3.088.498-4.715-.72-1.98-2.6-3.21-4.643-3.268Z"/></svg>

const Circle = ({size}) => <div className={styles.circle} style={{width: size, height: size}}></div>

const Star = () =><svg className={styles.star} xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="none"><path d="M10.944 4.233a.375.375 0 0 0-.357-.261h-3.53L5.837.258a.376.376 0 0 0-.712 0l-1.22 3.714H.375a.375.375 0 0 0-.218.68l2.856 2.034-1.15 3.501a.376.376 0 0 0 .574.423L5.48 8.443l3.044 2.167a.374.374 0 0 0 .574-.423l-1.15-3.501 2.855-2.033a.374.374 0 0 0 .14-.42Z"/></svg>

const Datation = ({ color }) => <div className={styles.datation} style={{backgroundColor: color}}></div>

const Spacer = () => <div className={styles.spacer}></div>

const Close = ({setVisible}) => <div className={styles.close} onClick={(e) => { setVisible(false); e.stopPropagation() }}><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><path fill="#2B2B2B" d="M.692 2.076A.978.978 0 1 1 2.076.692l11.53 11.53a.978.978 0 0 1-1.385 1.383L.693 2.075v.001Z"/><path fill="#2B2B2B" d="M12.22.692a.978.978 0 1 1 1.385 1.384l-11.53 11.53A.978.978 0 0 1 .693 12.22L12.222.692h-.001Z"/></svg></div>

const StaticLegend = ({setVisible}) => {
    const { t } = useTranslation();
    return (
      <div className={styles.staticLegend}>
        <Close setVisible={setVisible}/>
        <div className={styles.label}>{t("components.StaticLegend.localisation.label")}</div>
        <Droplet />{t("components.StaticLegend.localisation.precise")}
        <Circle size={10} />{t("components.StaticLegend.localisation.imprecise")}
        <Spacer />
        <div className={styles.label}>{t("components.StaticLegend.knowledge_type.label")}</div>
        <Circle size={14}/>{t("components.StaticLegend.knowledge_type.dig")}
        <Circle size={12}/>{t("components.StaticLegend.knowledge_type.surveyed")}
        <Circle size={10}/>{t("components.StaticLegend.knowledge_type.prospected_pedestrian")}
        <Circle size={8}/>{t("components.StaticLegend.knowledge_type.prospected_aerial")}
        <Circle size={6}/>{t("components.StaticLegend.knowledge_type.literature")}
        <Circle size={4}/>{t("components.StaticLegend.knowledge_type.not_documented")}
        <Spacer />
        <Star /><div className={styles.exceptional}>{t("components.StaticLegend.exceptional.label")}</div>
        <Spacer />
        <div className={styles.label}>{t("components.StaticLegend.datation.label")}</div>
        <Datation color={"#2BE7E6"}/>{t("components.StaticLegend.datation.inside_chronology")}
        <Datation color={"#FFFFFF"}/>{t("components.StaticLegend.datation.outside_chronology")}
        <Datation color={"#999999"} />{t("components.StaticLegend.datation.undetermined")}
      </div>
    )
}

const MapButtonStaticLegend = props => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false)
  return (
    <SlidingLabel label={t("components.MainMap.MapButtonStaticLegend.label")} hide={visible}>
    <button
      className={`${styles.button} ${visible ? styles.active : ''}`}
      aria-label={t("components.MainMap.MapButtonStaticLegend.label")}
      onClick={() => {
        setVisible((visible) => !visible)
      }}
    >
      <img alt="legend" src='/icons-maps/legend.svg'/>
      {visible && <StaticLegend setVisible={setVisible}/>}
    </button>
    </SlidingLabel>
  )
}

export default MapButtonStaticLegend
