import { useEffect, React } from "react";
import styles from "./Header.module.scss";

// zustand
import useStore from "../../lib/store";
import { shallow } from "zustand/shallow";

// icons
import {
  HelpIcon,
  FullScreenIcon,
  SearchIcon,
  BurgerIcon,
} from "../Icons/Icons";

// i18n
import { useTranslation } from "react-i18next";

// fullscreen
import { useFullScreen } from "../../lib/hook";

const Header = () => {
  const { t } = useTranslation();
  const [
    setRightPanel,
    setSidePanelOpened,
    setShowBurgerModal,
    showBurgerModal,
    sidePanelOpened,
    rightPanel,
  ] = useStore(
    (state) => [
      state.setRightPanel,
      state.setSidePanelOpened,
      state.setShowBurgerModal,
      state.showBurgerModal,
      state.sidePanelOpened,
      state.rightPanel,
    ],
    shallow
  );

  const [fullscreenEnabled, toggleFullscreen, fullscreenAvailable] =
    useFullScreen();

  return (
    <div className={styles.componentHeader}>
      <header>
        {/* Logo */}
        <div className={styles.logo}>
          <img
            src="/logos/logo-arkeopen-white.svg"
            alt="ArkeoPen"
            title="Arkeopen"
          />
        </div>

        {/* Links and Buttons */}
        <div className={styles.linksAndIcons}>
          {/* Search */}
          <div className={`${styles.links} ${styles.visibleOnDesktopOnly}`}>
            <div>
              <a
                href="/#"
                rel="noreferrer"
                className={`${sidePanelOpened === "left" ? styles.active : ""}`}
                onClick={(e) => {
                  setSidePanelOpened("left");
                  e.preventDefault();
                }}
                dangerouslySetInnerHTML={{
                  __html: t("components.Header.Link.Search.label"),
                }}
              ></a>
            </div>
            {/* My map index */}
            <div>
              <a
                href="/#"
                rel="noreferrer"
                className={`${
                  sidePanelOpened === "right" && rightPanel.type === "legend"
                    ? styles.active
                    : ""
                }`}
                onClick={(e) => {
                  setRightPanel({ type: "legend" });
                  e.preventDefault();
                }}
                dangerouslySetInnerHTML={{
                  __html: t("components.Header.Link.MyMapIndex.label"),
                }}
              ></a>
            </div>

            {/* Project */}
            <div>
              <a
                href="/#"
                rel="noreferrer"
                className={`${
                  sidePanelOpened === "right" && rightPanel.type === "project"
                    ? styles.active
                    : ""
                }`}
                onClick={(e) => {
                  setRightPanel({ type: "project", id: "project" });
                  e.preventDefault();
                }}
                dangerouslySetInnerHTML={{
                  __html: t("components.Header.Link.Project.label"),
                }}
              ></a>
            </div>
          </div>

          {/* Help */}
          <div className={styles.icons}>
            <a
              href="/#"
              className={`${styles.visibleOnDesktopOnly} ${
                sidePanelOpened === "right" && rightPanel.type === "help"
                  ? styles.active
                  : ""
              }`}
              onClick={(e) => {
                setRightPanel({ type: "help" });
                e.preventDefault();
              }}
            >
              <HelpIcon />
            </a>

            {/* Fullscreen */}
            {fullscreenAvailable && (
              <a
                href="/#"
                className={`${fullscreenEnabled ? styles.active : ""}`}
                onClick={(e) => {
                  toggleFullscreen();
                  e.preventDefault();
                }}
              >
                <FullScreenIcon />
              </a>
            )}

            {/* Mobile search */}
            <a
              href="/#"
              className={`${styles.mobileSearch} ${styles.visibleOnPhoneOnly} ${
                sidePanelOpened === "left" ? styles.active : ""
              }`}
              onClick={(e) => {
                setSidePanelOpened("left");
                e.preventDefault();
              }}
            >
              <SearchIcon />
            </a>

            {/* Burger */}
            <a
              href="/#"
              className={`${
                sidePanelOpened === "right" && showBurgerModal
                  ? styles.active
                  : ""
              }`}
              onClick={(e) => {
                setShowBurgerModal(true);
                e.preventDefault();
              }}
            >
              <BurgerIcon />
            </a>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
