import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';

/**
 * A very simple component that display a roratry spinner, used when loading something...
 * @returns The Loader Component
 */
function Loader(props) {
  const { fullHeight } = props;
  const { t, i18n } = useTranslation();
  return (
    <div className="Loader">
      <div className="c-inline-container">
        <i className="c-inline-spinner"></i>
      </div>

      <div className="c-inline-container-text">
        {t("components.Loader.text")}
      </div>

    </div>
  )
}

Loader.propTypes = {
  fullHeight: PropTypes.bool,
}

export default Loader
