import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

// zustand
import useStore from '../../lib/store'
import {shallow} from 'zustand/shallow'

// i18n
import { useTranslation } from 'react-i18next';

import { useSearchParams } from "react-router-dom";

import FundersLogos from '../FundersLogos/FundersLogos';

import './WelcomeModal.scss';


const WelcomeModal = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  let paramsCount = 0;
  searchParams.forEach(() => {paramsCount++});

  const [
    firstMapDrawed,
    showWelcomeModal,
    setShowWelcomeModal,
    setSidePanelOpened,
    setRightPanel,
  ] = useStore(state => [
    state.firstMapDrawed,
    state.showWelcomeModal,
    state.setShowWelcomeModal,
    state.setSidePanelOpened,
    state.setRightPanel,
  ], shallow)

  const handleClose = () => setShowWelcomeModal(false);
  const handleSearch = () => {
    setShowWelcomeModal(false);
    setSidePanelOpened('left');
  }
  const handleProject = () => {
    setShowWelcomeModal(false);
    setRightPanel({type: 'project', id: 'project'});
  }

  return (
    <Modal size="xl" show={showWelcomeModal} onHide={handleClose} centered className="WelcomeModal">
      <Modal.Header>
        <Modal.Title>{t('components.WelcomeModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="presentation">{t('components.WelcomeModal.presentation')}</div>
        <FundersLogos className="logos" />
      </Modal.Body>
      {firstMapDrawed ? (
        <Modal.Footer>
          {paramsCount > 0 ? (
            <React.Fragment>
              {firstMapDrawed && (
                <Button className="big" variant="primary" onClick={handleClose}>
                  {t('components.WelcomeModal.button.ok.label')}
                </Button>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {firstMapDrawed && (
                <>
                  <Button className="big" variant="light" onClick={handleProject}>
                    {t('components.WelcomeModal.button.project.label')}
                  </Button>
                  <Button className="big" variant="primary" onClick={handleSearch}>
                    {t('components.WelcomeModal.button.search.label')}
                  </Button>
                </>
              )}
            </React.Fragment>
          )}
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <div className="loading">{t('components.WelcomeModal.loading.label')}<div className="dot-pulse"></div></div>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default WelcomeModal;
