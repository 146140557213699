import React, { useState } from 'react'
//import PropTypes from 'prop-types'

// zustand
import useStore, { useValidatedSearchStore } from '../../lib/store'
import {shallow} from 'zustand/shallow'
//

// i18n
import { useTranslation } from 'react-i18next';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { ChevronIcon } from '../Icons/Icons';

import { getInLang } from '../../lib/translate';
import { yearToHuman, UNDETERMINED_LEFT, UNDETERMINED_RIGHT } from '../../lib/year';

import Markdown from '../Markdown/Markdown';

import './Chronoview.scss'

const ChronoPeriod = props => {
  const { chronology } = props;
  const { i18n } = useTranslation();
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement='top'
      overlay={
        <Popover id={`popover-positioned-top`}>
          <Popover.Header as="h3">
            <div>{getInLang(chronology.chronology_trs, i18n.language).name}</div>
            <div>{yearToHuman(chronology.start_date, i18n.language)} =&gt; {yearToHuman(chronology.end_date, i18n.language)}</div>
          </Popover.Header>
          <Popover.Body>
            <div className="poptextwrap"><Markdown>{getInLang(chronology.chronology_trs, i18n.language).description}</Markdown></div>
          </Popover.Body>
        </Popover>
      }
    >
      <div className="ColoredPeriod" style={{backgroundColor: '#'+chronology.color}}/>
    </OverlayTrigger>
  )
}

const Chronobloc = props => {
  const { chronology, chronologyStartDate, chronologyEndDate } = props;
  const isSelected =
    chronologyStartDate === UNDETERMINED_LEFT && chronologyEndDate === UNDETERMINED_RIGHT ?
      false : chronologyStartDate <= chronology.start_date && chronologyEndDate >= chronology.end_date;
  return (
    <div className={`ChronoBloc ${isSelected ? 'selected' : 'not-selected'}`} style={{flexGrow: chronology.end_date - chronology.start_date}}>
      { chronology.parent_id !== 0 ? <ChronoPeriod chronology={chronology}/> : '' }
      <div className="ChronoLine">
        {chronology.chronologies.map(subchrono => <Chronobloc key={subchrono.id} chronology={subchrono} chronologyStartDate={chronologyStartDate} chronologyEndDate={chronologyEndDate}/>)}
      </div>
    </div>
  );
}

const Chronoview = () => {
  const { i18n, t } = useTranslation();
  const [ selectedChronologyId, chronologyStartDate, chronologyEndDate ]
    = useValidatedSearchStore(state => [
      state.selectedChronologyId, state.chronologyStartDate, state.chronologyEndDate ],
      shallow);
  const [ sidePanelOpened, chronologies ] = useStore(state => [ state.sidePanelOpened, state.chronologies ], shallow);
  //console.log("Chronoview / chronologySelection: ", chronologySelection);
  const [ show, setShow ] = useState(true);

  const selectedChronology = chronologies.find(c => c.id === selectedChronologyId);

  const toggleView = () => {
    setShow(!show);
  }

  return (
    <div className={`ChronoviewContainer sidepanel-${sidePanelOpened}`}>
      <div className={`Chronoview ${show ? 'showing' : 'hidding'}`}>
        <div className="ChronoviewTitle">
         <button onClick={toggleView} className={`${show ? 'showing' : 'hidding'}`}>{t(`components.Chronoview.button.label`)}<ChevronIcon /></button>
        </div>
        <Chronobloc chronology={selectedChronology} chronologyStartDate={chronologyStartDate} chronologyEndDate={chronologyEndDate} />
        <div className="StartYear">{yearToHuman(selectedChronology.start_date, i18n.language)}</div>
        <div className="EndYear">{yearToHuman(selectedChronology.end_date, i18n.language)}</div>
      </div>
    </div>
  )
}

Chronoview.propTypes = {}

export default Chronoview
