import React from "react";
import { useTranslation } from "react-i18next";
import SlidingLabel from "../SlidingLabel/SlidingLabel";
import useStore from "../../lib/store";

const UngroupedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><g filter="url(#a)"><rect width="30" height="30" x="3" y="3" rx="3" shapeRendering="crispEdges"/><path stroke="currentColor" strokeWidth="2" d="M11 11h5v5h-5zm9 0h5v5h-5zm-9 9h5v5h-5zm9 0h5v5h-5z"/></g><defs><filter id="a" width="36" height="36" x="0" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse"><feFlood floodOpacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset/><feGaussianBlur stdDeviation="1.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1529_223"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_1529_223" result="shape"/></filter></defs></svg>
);

const GroupedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><g filter="url(#a)"><rect width="30" height="30" x="3" y="3" rx="3" shapeRendering="crispEdges"/><path stroke="currentColor" strokeWidth="2" d="M13 13h5v5h-5v-5Zm5 0h5v5h-5zm-5 5h5v5h-5zm5 0h5v5h-5z"/></g><defs><filter id="a" width="36" height="36" x="0" y="0" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse"><feFlood floodOpacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset/><feGaussianBlur stdDeviation="1.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1529_230"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_1529_230" result="shape"/></filter></defs></svg>
);

export const MapButtonGroupUngroup = () => {
  const { t } = useTranslation();
  const [ungrouped, setUngrouped] = useStore((state) => [
    state.ungrouped,
    state.setUngrouped,
  ]);
  return (
    <SlidingLabel
      label={t("components.MainMap.MapButtonGroupUngroup.label")}
    >
      <button
        className={`button${ungrouped ? " active" : ""}`}
        aria-label={t("components.MainMap.MapButtonGroupUngroup.label")}
        title=""
        onClick={() => {
          setUngrouped(!ungrouped);
        }}
      >
        {ungrouped ? <UngroupedIcon /> : <GroupedIcon />}
      </button>
    </SlidingLabel>
  );
};

export default MapButtonGroupUngroup;
