import React from "react";
//import PropTypes from 'prop-types'
import ShareButton from "../ShareButton/ShareButton";

import { GetChronologyDetails } from "../../lib/queries/chronology";

import { useQuery } from "@apollo/client";

import Linkify from "react-linkify";

import useStore from "../../lib/store";

// i18n
import { useTranslation } from "react-i18next";
import { getInLang } from "../../lib/translate";

import styles from "./PanelChronology.module.scss";
import Loader from "../Loader/Loader";
import MiniMap from "../MiniMap/MiniMap";
import Markdown from "../Markdown/Markdown";
import { yearToHuman } from "../../lib/year";
import CloseButton from "../CloseButton/CloseButton";
import { Align, ContentWithPadding, ContentWithLabel, TitleWithIcons, DescriptionBlock, SpacedContent } from "../ArkeoUI/ArkeoUI";

const myLink = (decoratedHref, decoratedText, key) => {
  return (
    <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
      {decoratedText}
    </a>
  );
};

const PanelChronology = (props) => {
  const { t, i18n } = useTranslation();
  const { id } = props;

  const setRightPanel = useStore((state) => state.setRightPanel);

  const chronology = useQuery(GetChronologyDetails, {
    variables: {
      chronology_id: id,
    },
  }); // chronology = { loading, error, data }

  const loadingOrError = chronology.loading ? <Loader /> :
                  chronology.error ? chronology.error.message:
                  (!chronology.data.ako_chronology_root || chronology.ako_chronology_root === 0) ? "Dataset not found." : null;

  if (loadingOrError)
    return (
      <div className={styles.PanelChronology}>
        {loadingOrError}
      </div>
    );

  const cr = chronology.data.ako_chronology_root[0];

  const geojson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: cr.geom,
      },
    ],
  };

  const CSS_LABEL_WIDTH = 250;

  return (
    <div className={styles.PanelChronology}>
      <MiniMap geojson={geojson} minimaptype="polygon" />
      <CloseButton rp={{ type: "legend", id: "when" }} />

      <ContentWithPadding>
        <TitleWithIcons title={getInLang(cr.chronology.chronology_trs, i18n.language).name}>
          <ShareButton />
        </TitleWithIcons>

        <Align smallFont split className={styles.headingBlocks}>
        <div>
          <ContentWithLabel label={t`components.PanelChronology.temporal.title`} noColon/>
          <ContentWithLabel label={t`components.PanelChronology.temporal.begin.title`} lang={i18n.language}>
            {
              getInLang(
                cr.chronology.chronologies[0].chronology_trs,
                i18n.language
              ).name
            }
            <Align gap={12}>
              {<div className={styles.colorblock} style={{backgroundColor: "#" + cr.chronology.chronologies[0].color}}/>}
              {yearToHuman(cr.chronology.start_date)}
            </Align>
          </ContentWithLabel>
          <ContentWithLabel label={t`components.PanelChronology.temporal.end.title`} lang={i18n.language}>
            {
              getInLang(
                cr.chronology.chronologies.at(-1).chronology_trs,
                i18n.language
              ).name
            }
            <Align gap={12}>
              {<div className={styles.colorblock} style={{backgroundColor: "#" + cr.chronology.chronologies.at(-1).color}}/>}
              {yearToHuman(cr.chronology.end_date)}
            </Align>
          </ContentWithLabel>
        </div>
        <div>
          <ContentWithLabel label={t`components.PanelChronology.geographical.title`} lang={i18n.language} split noColon/>
            {
              getInLang(cr.chronology_root_trs, i18n.language)
                .geographical_covering
            }
        </div>
      </Align>

      <hr />

      <Align smallFont split className={styles.headingBlocks}>
        <div>
          <ContentWithLabel label={t`components.PanelChronology.authors.title`} lang={i18n.language}>
            {cr.group.user__groups
              .map((ug) => `${ug.user.lastname} ${ug.user.firstname}`)
              .join(", ")}
          </ContentWithLabel>
          <ContentWithLabel label={t`components.PanelChronology.editors.title`} lang={i18n.language}>
            {cr.editor_uri ? (
              <a href={cr.editor_uri} target="_blank" rel="noreferrer">
                {cr.editor}
              </a>
            ) : (
              cr.editor
            )}
          </ContentWithLabel>
        </div>
        <div>
          <ContentWithLabel label={t`components.PanelChronology.date-created.title`} lang={i18n.language}>
            {new Date(cr.chronology.created_at).toLocaleDateString(
              i18n.language
            )}
          </ContentWithLabel>
          <ContentWithLabel label={t`components.PanelChronology.date-updated.title`} lang={i18n.language}>
            {new Date(cr.chronology.updated_at).toLocaleDateString(
              i18n.language
            )}
          </ContentWithLabel>
        </div>
      </Align>

      <hr />

      <DescriptionBlock label={t`components.PanelChronology.description.title`}>
        <Markdown>
          {
            getInLang(cr.chronology.chronology_trs, i18n.language)
              .description
          }
        </Markdown>
      </DescriptionBlock>

      <hr />

      <SpacedContent>
        <ContentWithLabel label={t`components.PanelChronology.datasets.title`} lang={i18n.language} labelMinWidth={CSS_LABEL_WIDTH} split>
          {cr.databases.map((database) => (
            <div key={database.id}>
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setRightPanel({ type: "db", id: database.id });
                }}
              >
                {database.name}
              </a>{" "}
              :{" "}
              {database.database__authors
                .map(
                  (author) => `${author.user.lastname} ${author.user.firstname}`
                )
                .join(", ")}
            </div>
          ))}
        </ContentWithLabel>

        <hr />

        <ContentWithLabel width={CSS_LABEL_WIDTH} label={t`components.PanelChronology.Citation.title`} lang={i18n.language} labelMinWidth={CSS_LABEL_WIDTH} split>
          <Linkify componentDecorator={myLink}>{cr.credits}</Linkify>
        </ContentWithLabel>

        <ContentWithLabel label={t`components.PanelChronology.ExternalLink.title`} lang={i18n.language} labelMinWidth={CSS_LABEL_WIDTH} split>
          {t`components.PanelChronology.ExternalLink.leftpart`}
          {" "}
          <a
            href="https://client.perio.do/?page=authority-view&backendID=web-https%3A%2F%2Fdata.perio.do%2F&authorityID=p09hq4n"
            target="_blank"
            rel="noreferrer"
          >
            Periodo
          </a>
          {" "}
          {t`components.PanelChronology.ExternalLink.rightpart`}
        </ContentWithLabel>

        <ContentWithLabel label={t`components.PanelChronology.DepositURI.title`} lang={i18n.language} labelMinWidth={CSS_LABEL_WIDTH} split>
          <Linkify componentDecorator={myLink}>{cr.deposit_uri}</Linkify>
        </ContentWithLabel>

        <ContentWithLabel label={t`components.PanelChronology.license.title`} lang={i18n.language} labelMinWidth={CSS_LABEL_WIDTH} split>
          <a
            href="https://opendatacommons.org/norms/odc-by-sa/"
            target="_blank"
            rel="noreferrer"
          >
            Open Data Commons - ODC Attribution-Sharealike Community Norms
          </a>
        </ContentWithLabel>

        <ContentWithLabel label={t`components.PanelChronology.mediator.title`} lang={i18n.language} labelMinWidth={CSS_LABEL_WIDTH} split>
          {t`components.PanelChronology.mediator.content`}{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href={t`components.PanelChronology.mediator.url`}
          >{t`components.PanelChronology.mediator.urltext`}</a>
        </ContentWithLabel>
      </SpacedContent>
    </ContentWithPadding>
    </div>
  );
};

PanelChronology.propTypes = {};

export default PanelChronology;
