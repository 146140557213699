import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import styles from "./Markdown.module.scss";

const Markdown = props => {
  const { children } = props;

  return (
    <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]} className={styles.markdown}>{children}</ReactMarkdown>
  )
}

Markdown.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Markdown
