import React from 'react'
//import PropTypes from 'prop-types'

import FlatSelect from '../FlatSelect/FlatSelect'

// zustand
import useStore, { useSearchStore } from '../../lib/store'
import {shallow} from 'zustand/shallow'
//

// bootstrap
import Form from 'react-bootstrap/Form';
//import FormControl from 'react-bootstrap/FormControl';
//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
//

import Markdown from '../Markdown/Markdown';

import { yearToHuman } from '../../lib/year';

// i18n
import { useTranslation } from 'react-i18next';
import { getInLang } from '../../lib/translate';
//

import './FormSearchWhere.scss'

const FormSearchWhere = props => {
  const { t, i18n } = useTranslation();
  const [ selectedShapefiles, setSelectedShapefiles ] = useSearchStore(state => [state.selectedShapefiles, state.setSelectedShapefiles], shallow);

  const shapefiles = [...useStore(state => state.shapefiles)]
    .sort((a,b) => getInLang(a.shapefile_trs, i18n.language).name.localeCompare(getInLang(b.shapefile_trs, i18n.language).name, i18n.language))

  const toggleSelect = shapefile => {
    if (selectedShapefiles.includes(shapefile.id)) {
      setSelectedShapefiles(selectedShapefiles.filter(id => id !== shapefile.id));
    } else {
      setSelectedShapefiles([...selectedShapefiles, shapefile.id]);
    }
  }

  return (
    <div className="FormSearchWhere">
      <div>
        <Form.Label>{t("components.FormSearchWhere.label")}</Form.Label>
        {shapefiles.map(shapefile => (
          <FlatSelect
            key={shapefile.id}
            selected={selectedShapefiles.includes(shapefile.id)}
            label={getInLang(shapefile.shapefile_trs, i18n.language).name}
            onSelect={() => toggleSelect(shapefile)}
            leaf={true}
            pop={
              <Popover id={`popover-chronology`}>
                <Popover.Header as="h3">
                  <div><strong>{yearToHuman(shapefile.start_date, i18n.language)} =&gt; {yearToHuman(shapefile.end_date, i18n.language)}</strong></div>
                </Popover.Header>
                <Popover.Body>
                  <div className="poptextwrap"><Markdown>{getInLang(shapefile.shapefile_trs).description}</Markdown></div>
                </Popover.Body>
              </Popover>
            }
            >

            </FlatSelect>
        ))}
     </div>
    </div>
  )
}

FormSearchWhere.propTypes = {}

export default FormSearchWhere
