import React from "react";

// zustand
import useStore from "../../lib/store";
//import {shallow} from 'zustand/shallow'
//

// graphql
import { useQuery } from "@apollo/client";
import { GetAllCharacs } from "../../lib/queries/charac.js";
import { useEffect } from "react";
//

import _ from "underscore";

const convertCharacs = (characs, parent = null) => {
  return characs.map((charac) => {
    const _charac = _.pick(charac, "id", "ark_id", "aat_id", "pactols_id", "charac_trs");
    _charac.characs = charac.characs ? convertCharacs(charac.characs, _charac) : [];
    _charac.parent = parent;
    return _charac;
  });
};

export default function InitCharacsData(props) {
  const { /*loading, error, */ data } = useQuery(GetAllCharacs, { fetchPolicy: "no-cache" });
  const setCharacs = useStore((state) => state.setCharacs);

  useEffect(() => {
    setCharacs(data ? convertCharacs(data.ako_charac) : []);
  }, [data, setCharacs]);

  return <React.Fragment />;
}
