import React from 'react'
import PropTypes from 'prop-types'

import useAxios from 'axios-hooks'
import useStore from '../../lib/store'

import Markdown from '../Markdown/Markdown';
import SlidableTabs from '../SlidableTabs/SlidableTabs';


// i18n
import { useTranslation } from 'react-i18next';
//

import './TabbedMDComponent.scss'
import CloseButton from '../CloseButton/CloseButton';

const TabContent = props => {
  const { lang, section, code } = props;
  const [{ data, loading, error }/*, refetch*/] = useAxios(
    `/pages/${section}/${code}.${lang}.md`
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error!</p>

  return (
    <div className="TabbedContent"><Markdown>{data}</Markdown></div>
  );
}

const TabbedMDComponent = props => {
  const { t, i18n } = useTranslation();
  const { tabs, section } = props;
  const rightPanel = useStore(state => state.rightPanel);
  const setRightPanel = useStore(state => state.setRightPanel);

  const tabsWithContent = tabs.map(tab => {
    tab.content = <TabContent lang={i18n.language} section={section} code={tab.code} />;
    return tab;
  })

  return (
    <div className="TabbedMDComponent">
      <CloseButton />
      <h1>{t("components.TabbedMDComponent.title")}</h1>
      <SlidableTabs id="TabbedMDComponent" tabs={tabsWithContent} onSelect={k => setRightPanel({...rightPanel, id: k})} activeKey={rightPanel.id} umountOnExit></SlidableTabs>

    </div>
  )
}

TabbedMDComponent.propTypes = {
  /**
   * tabs: an object like this :
   *   const tabs = [
   *    { code: 'interface', text: t("components.Help.interface.title")},
   *    { code: 'when', text: t("components.Help.when.title")},
   *    { code: 'what', text: t("components.Help.what.title")},
   *    { code: 'where', text: t("components.Help.where.title")},
   *    { code: 'others', text: t("components.Help.others.title")},
   *    { code: 'map', text: t("components.Help.map.title")},
   *  ];
   */
  tabs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  /**
   * section: a string ('help')
   */
  section: PropTypes.string.isRequired,
}

export default TabbedMDComponent
